import { createSelector } from "reselect";
import querystring from "query-string";

import { getEntity } from "utils/entities";
import { ClusterSchema } from "utils/schemas";
import { CLUSTERS } from "utils/constants/routes";
import { generatePath } from "react-router";
import { DEFAULT_CLUSTERS_FILTERS } from "utils/constants/filters";
import { VIRTUAL_CLUSTERS_LIST_MODULE } from "state/cluster/actions/list/nestedclusters";
import { ADMIN_GUID } from "state/auth/selectors";
import dataFetcher from "modules/dataFetcher";
import api from "services/api";

export const getClustersListState = createSelector(
  (state) => state.auth.currentProjectId,
  (state) => state.list,
  (context, lists) => {
    return lists[`clusters/${context}`];
  }
);

export const getClusters = getEntity(
  createSelector(getClustersListState, (state) =>
    Object.keys(state?.items || {})
      .map((key) => state?.items[key])
      .flat()
  ),
  [ClusterSchema]
);

export const isInitialClusterListEmpty = createSelector(
  getClustersListState,
  (state) => !state?.isLoading && !state?.isInitializing && !state?.count
);

export const getClustersLink = createSelector(
  getClustersListState,
  (state) => state.auth.currentProjectId,
  (state, context) => {
    let rootPath = generatePath(CLUSTERS.ROOT, {
      tab: "overview",
    });
    if (context === ADMIN_GUID) {
      rootPath = `/admin${rootPath}`;
    }
    if (!state) {
      return rootPath;
    }
    const { deleted, sortField, sortOrder } = state?.query || {};
    const query = querystring.stringify({
      search: "",
      deleted,
      sortField,
      sortOrder,
    });

    return `${rootPath}?${query}`;
  }
);

export const isInitialVirtualClustersListEmpty = createSelector(
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.count,
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.isLoading,
  (state) => state.list[VIRTUAL_CLUSTERS_LIST_MODULE]?.isInitializing,
  (count, isLoading, isInitializing) => {
    return !isLoading && !isInitializing && !count;
  }
);

export const getAvailableDefaultFilters = createSelector(
  getClustersListState,
  (state) => {
    const filterGroups = state.query?.filterGroups || [];
    const selectedProperties = filterGroups.reduce((accumulator, group) => {
      const properties = (group?.conditions || []).map(
        (condition) => condition.property
      );
      accumulator.push(...properties);
      return accumulator;
    }, []);

    return DEFAULT_CLUSTERS_FILTERS.filter(
      (filter) => !selectedProperties.includes(filter.name)
    );
  }
);

export const edgeInstallerDownloadFetcher = dataFetcher({
  selectors: ["edgeInstallerDownload"],
  fetchData: async () => {
    const downloadLinks = await api.get(
      "v1/spectroclusters/config/edgeInstaller"
    );

    return downloadLinks?.installerDownloadLinks || {};
  },
});

export const getCurrentListView = createSelector(
  getClustersListState,
  (state) => state?.query.listView || "list"
);

export const getClustersListQuery = createSelector(
  getClustersListState,
  (state) => state?.query || {}
);
