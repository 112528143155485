import React, { useState, useMemo, useRef, useImperativeHandle } from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";

import Loader from "components/ui/Loader";
import ActionMenu from "components/common/ActionMenu";
import { useTranslation } from "react-i18next";
import { white, blue, winterMist } from "utils/constants/colors";
import Button, { TextButton } from "components/ui/Button";
import Icon from "components/ui/Icon";
import { faTimesCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import PresetsSider from "./Presets";
import Split from "assets/icons/split.svg?component";
import { userSettingsCache } from "services/localstorage/cache";

const EditorComponent = React.lazy(() => import("./Editor"));

const Wrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

export const Toolbar = styled.div`
  min-height: 45px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0 10px;
  z-index: 9;

  ${({ color, theme }) =>
    css`
      box-shadow: inset 0px -1px 0px ${theme === "dark" ? "#30365b" : color};
      background: ${theme === "dark" ? "#30365b" : color};
      color: ${theme === "dark" ? "#fff" : "#555"};
      opacity: ${theme === "dark" ? 1 : 0.9};
    `}

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    width: 100%;
  }
`;

const NotificationsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #a9a9a9;
  color: #fff;

  .anticon {
    vertical-align: middle;
    margin-right: 10px;
    color: inherit;
  }
  svg {
    max-width: 16px;
    path {
      fill: #fff;
    }
  }

  ${TextButton} {
    min-width: 40px;
    color: #fff;
  }
`;

const MessageWrap = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const IconWrap = styled.div`
  margin: 0 7px;
  font-size: 18px;

  ${(props) =>
    props.fill &&
    css`
      svg path {
        fill: ${props.fill};
      }
    `}
`;

const ToolbarMenuWrap = styled.div`
  display: flex;
  align-items: center;

  > ${Button} {
    margin: 0 10px;
  }
`;

const EditorWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const DiffButton = styled(TextButton)`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  svg path {
    fill: #78909c;
  }

  ${(props) =>
    props.diffDisplay === true &&
    css`
      &,
      &:hover,
      &:focus {
        background: ${winterMist};
        svg path {
          fill: ${blue};
        }
      }
    `}
`;

const CloseIconWrap = styled.div`
  margin-left: 10px;
  opacity: 0.6;
  width: 18px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Editor({
  showActions = true,
  title = "",
  headerColor = white,
  notifications = [],
  showNotifications,
  acknowledgeNotifications,
  onRevert,
  showRevertOption,
  showPresets,
  presets,
  presetsDisabledFields,
  onPresetsChange,
  showDiffToggle,
  showDiff,
  presetsPathName,
  theme,
  onClose,
  passedInRef,
  ...rest
}) {
  const editorRef = useRef();
  const [editorTheme, setTheme] = useState(
    userSettingsCache.get("editorTheme") || "light"
  );
  const [diffDisplay, setDiffDisplay] = useState(false);
  const actionableNotifications = useMemo(() => {
    return notifications.filter((notification) => notification.message);
  }, [notifications]);
  const { t } = useTranslation();

  useImperativeHandle(passedInRef, () => {
    return {
      refresh() {
        editorRef.current.refresh();
      },
      focus() {
        editorRef.current?.focus();
      },
    };
  });

  function toggleTheme() {
    const newTheme = editorTheme === "light" ? "dark" : "light";
    userSettingsCache.set("editorTheme", newTheme);
    setTheme(newTheme);
  }

  function renderNotification(notification) {
    if (!notification.message) {
      return null;
    }
    return (
      <MessageWrap>
        {notification.icon && <Icon {...notification.icon} />}{" "}
        <div>{notification.message}</div>
      </MessageWrap>
    );
  }

  function renderNotificationIcons(notification) {
    if (!notification.icon) {
      return null;
    }
    return (
      <IconWrap fill={notification?.icon?.fill}>
        <Tooltip title={notification.message}>
          <Icon {...notification.icon} />
        </Tooltip>
      </IconWrap>
    );
  }

  function renderNotifications() {
    if (!showNotifications || !actionableNotifications.length) {
      return null;
    }
    return (
      <NotificationsWrapper>
        <div>{actionableNotifications.map(renderNotification)}</div>
        <TextButton onClick={acknowledgeNotifications}>
          <Icon awesome={faTimesCircle} />
        </TextButton>
      </NotificationsWrapper>
    );
  }

  return (
    <Wrap>
      <Toolbar color={headerColor} theme={theme}>
        <span>{title}</span>
        {showActions && (
          <ToolbarMenuWrap>
            {showDiffToggle && (
              <DiffButton
                data-qa="diff"
                diffDisplay={diffDisplay}
                onClick={() => setDiffDisplay(!diffDisplay)}
              >
                <Split />
              </DiffButton>
            )}
            {showRevertOption && (
              <Button
                data-qa="revert-pack"
                secondary
                size="small"
                onClick={onRevert}
                disabled={rest.defaultValues === rest.value}
              >
                {t("Use defaults")}
              </Button>
            )}
            {notifications.map(renderNotificationIcons)}
            <ActionMenu
              options={[
                {
                  key: "theme",
                  label:
                    editorTheme === "light" ? t("Dark mode") : t("Light mode"),
                  onClick: toggleTheme,
                },
                {
                  key: "format",
                  label: t("Format Document"),
                  onClick: () =>
                    editorRef.current.runCommand(
                      "editor.action.formatDocument"
                    ),
                },
              ]}
            />
            {onClose && (
              <CloseIconWrap onClick={onClose}>
                <Icon awesome={faTimes} />
              </CloseIconWrap>
            )}
          </ToolbarMenuWrap>
        )}
      </Toolbar>
      {renderNotifications()}
      <React.Suspense fallback={<Loader />}>
        <EditorWrapper>
          {showPresets && !isEmpty(presets) && (
            <PresetsSider
              presets={presets}
              onChange={onPresetsChange}
              presetsPathName={presetsPathName}
              disabledFields={presetsDisabledFields}
            />
          )}
          <EditorComponent
            {...rest}
            showDiff={showDiffToggle ? diffDisplay : showDiff}
            theme={editorTheme}
            refresh={!showNotifications}
            passedInRef={editorRef}
          />
        </EditorWrapper>
      </React.Suspense>
    </Wrap>
  );
}
