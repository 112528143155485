import { createSelector } from "reselect";
import moment from "moment";

import {
  ClusterProfileTemplateSchema,
  ClusterSchema,
  ClusterProfileSchema,
} from "utils/schemas";
import { getEntity } from "utils/entities";
import {
  presentClusterProfileParams,
  groupPresetsOptions,
  generateEditorYamlSchema,
  getQuotaPercentages,
  excludeClusterCurrentQuota,
} from "utils/presenters";
import { formatTimestampToDate } from "utils/date";
import { PermissionService } from "services/permissions";
import YAML from "yaml";

import { getCurrentProjectUidFromUrl } from "state/auth/selectors";
import {
  clusterCertificatesFetcher,
  gaugeFetcher,
  utilizationFetcher,
  realTimeConsumptionFetcher,
  tencentSshKeyPairsFetcher,
  getContextCurrentLock,
  overlordsFetcher,
} from "state/cluster/services";
import * as colors from "utils/constants/colors";
import {
  clusterErrorFetcher,
  kubectlRedirectUriFetcher,
  hostClusterFetcher,
} from "../services";
import {
  round,
  formatToUSCurrency,
  formatToKilocoreHours,
  parseKiBToGB,
} from "utils/number";
import {
  BAREMETAL_ENVS,
  BEGINNING_OF_TIME,
  COXEDGE_ENVIRONMENT,
} from "utils/constants";
import {
  COST_CHART_COLORS,
  USAGE_LINE_CHART_COLORS,
} from "utils/constants/colors";
import i18next from "i18next";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";
import { NODE_DETAILS_FILTERS_MODULE } from "state/cluster/services/nodes";
import loginService from "services/loginService";
import { EDIT_CLUSTER_SIZE_FORM_MODULE } from "state/cluster/services";
import { sandboxClusterQuotaUsageFetcher } from "state/devnestedcluster/services";
import { getVmService } from "pages/clusters/vms";

const nodeMock = (isConfigured, isProvisioning) => {
  function getStatus() {
    if (isProvisioning) {
      return "provisioning";
    }

    if (isConfigured) {
      return "configured";
    }

    return "pending";
  }
  return {
    metadata: {},
    spec: {
      phase: getStatus(),
    },
    status: {},
  };
};

export const getRawCluster = getEntity(
  (state) => state.cluster.details.currentClusterId,
  ClusterSchema
);

export const getClusterImport = createSelector(getRawCluster, (cluster) => {
  return cluster?.status?.clusterImport;
});

export const hasClusterImportFinished = createSelector(
  getClusterImport,
  (clusterImport) => {
    return clusterImport?.state === "Imported";
  }
);

export const isClusterImportPending = createSelector(
  getClusterImport,
  (clusterImport) => {
    return clusterImport?.state === "ImportPending";
  }
);

export const isClusterImportMigrating = createSelector(
  getClusterImport,
  (clusterImport) => {
    return ["MigratePending", "Migrating"].includes(clusterImport?.state);
  }
);

export const getClusterOverlord = createSelector(
  overlordsFetcher.selector,
  getRawCluster,
  ({ result }, cluster) => {
    if (!result) {
      return null;
    }

    return result.find(
      (overlord) => overlord.spec.spectroClusterUid === cluster.metadata.uid
    );
  }
);

export const displayImportProcedure = createSelector(
  isClusterImportPending,
  isClusterImportMigrating,
  (isImportPending, isImportMigrating) => {
    return isImportPending || isImportMigrating;
  }
);

export const isBrownfield = createSelector(
  getClusterImport,
  getRawCluster,
  (clusterImport, cluster) => {
    const clusterLabels = cluster?.metadata?.labels;
    const ignoreImport = clusterLabels?.imported === "false";

    return ignoreImport
      ? false
      : clusterImport?.isBrownfield || !!clusterImport?.state;
  }
);

export const isClusterProvisioning = createSelector(
  getRawCluster,
  (cluster) => {
    return cluster?.status?.state === "Provisioning";
  }
);

export const isClusterImporting = createSelector(getRawCluster, (cluster) => {
  const status = cluster?.status || {};
  const { clusterImport, state } = status;
  const isBrownfield = clusterImport?.isBrownfield || !!clusterImport?.state;

  return (
    isBrownfield && ["pending", "importing"].includes(state?.toLowerCase())
  );
});

export const isVirtualCluster = createSelector(getRawCluster, (cluster) => {
  return cluster?.spec?.cloudType === "nested";
});

export const isHostCluster = createSelector(getRawCluster, (cluster) => {
  return (
    cluster?.spec?.clusterConfig?.hostClusterConfig?.isHostCluster ||
    cluster?.status?.virtual?.state === "True" ||
    cluster?.status?.virtual?.virtualClusters?.length
  );
});

export const getMemoryGaugeMetrics = createSelector(
  gaugeFetcher.selector,
  (state) => state.cluster.details.includeMasters,
  ({ result }, includeMasters) => {
    if (!result) {
      return { data: [], labelData: {} };
    }

    const { memoryTotal, memoryRequest, masters } = result;

    const denominator = memoryTotal?.aggregation.sum;
    const numerator = memoryRequest?.aggregation.sum;

    const percentage = (numerator * 100) / denominator;

    const formattedNumerator = parseKiBToGB(numerator, 2);
    const formattedDenominator = parseKiBToGB(denominator, 2);

    let data = [
      {
        id: "requests",
        label: "requests",
        value: percentage || 0,
        color: colors.lavender,
      },
      {
        id: "undefined",
        label: "undefined",
        value: 100 - percentage || 1,
        color: colors.lightGray,
      },
    ];

    let labelData = {
      denominator: formattedDenominator,
      numerator: formattedNumerator,
      label: "Gb",
      chartLabel: "memory",
    };

    if (includeMasters) {
      const allNodesDenominator = masters?.memoryTotal?.aggregation.sum;
      const allNodesNumerator = masters?.memoryRequest?.aggregation.sum;

      const greyArea = 100 - (allNodesNumerator * 100) / allNodesDenominator;
      const nodesPercentage = (numerator * 100) / allNodesDenominator;
      const mastersPercentage = 100 - nodesPercentage - greyArea;

      data = [
        {
          id: "requests",
          label: "requests",
          value: nodesPercentage || 0,
          color: colors.lavender,
        },
        {
          id: "masters",
          label: "masters",
          value: mastersPercentage || 0,
          color: colors.darkLavender,
        },
        {
          id: "undefined",
          label: "undefined",
          value: greyArea || 1,
          color: colors.lightGray,
        },
      ];

      labelData = {
        ...labelData,
        denominator: parseKiBToGB(allNodesDenominator, 2),
        numerator: parseKiBToGB(allNodesNumerator, 2),
      };
    }

    return { data, labelData };
  }
);

export const getCPUGaugeMetrics = createSelector(
  gaugeFetcher.selector,
  (state) => state.cluster.details.includeMasters,
  ({ result }, includeMasters) => {
    if (!result) {
      return { data: [], labelData: {} };
    }

    const { cpuTotal, cpuRequest, masters } = result;

    const denominator = cpuTotal?.aggregation.sum / 1000;
    const numerator = cpuRequest?.aggregation.sum / 1000;

    const percentage = (numerator * 100) / denominator;

    let data = [
      {
        id: "requests",
        label: "requests",
        value: percentage || 0,
        color: colors.lavender,
      },
      {
        id: "undefined",
        label: "undefined",
        value: 100 - percentage || 1,
        color: colors.lightGray,
      },
    ];

    let labelData = {
      denominator,
      numerator,
      label: "Cores",
      chartLabel: "cpu",
    };

    if (includeMasters) {
      const allNodesDenominator = masters?.cpuTotal?.aggregation.sum / 1000;
      const allNodesNumerator = masters?.cpuRequest?.aggregation.sum / 1000;

      const greyArea = 100 - (allNodesNumerator * 100) / allNodesDenominator;
      const nodesPercentage = (numerator * 100) / allNodesDenominator;
      const mastersPercentage = 100 - nodesPercentage - greyArea;

      data = [
        {
          id: "requests",
          label: "requests",
          value: nodesPercentage || 0,
          color: colors.lavender,
        },
        {
          id: "masters",
          label: "masters",
          value: mastersPercentage || 0,
          color: colors.darkLavender,
        },
        {
          id: "undefined",
          label: "undefined",
          value: greyArea || 1,
          color: colors.lightGray,
        },
      ];

      labelData = {
        ...labelData,
        denominator: allNodesDenominator,
        numerator: allNodesNumerator,
      };
    }

    return { data, labelData };
  }
);

export function mapChartPoints({ metric, total, key, dateUnit, customFormat }) {
  if (!metric?.points?.length) {
    return [];
  }

  return metric.points?.map((point) => {
    let value = point[key];
    if (!value) {
      value = point["value"];
    }

    const format = ["hour", "hours"].includes(dateUnit) ? "HH:mm" : "DD MMM";

    return {
      x: formatTimestampToDate(point.timestamp, customFormat || format),
      y: round((value / total?.aggregation.avg) * 100, 2),
    };
  });
}

export const getCpuUtilizationMetrics = createSelector(
  utilizationFetcher.selector,
  (state) => state.forms[NODE_DETAILS_FILTERS_MODULE]?.data?.dateTime,
  ({ result }, dateUnit = "") => {
    if (!result || !dateUnit) {
      return [];
    }

    const unit = dateUnit.split(" ")[1];
    const customFormat = dateUnit === "24 hours" ? "HH:mm DD MMM" : null;

    const { cpuUsage, cpuTotal, period } = result;

    const values = {
      avg: (cpuUsage?.aggregation?.avg / cpuTotal?.aggregation?.avg) * 100,
      max: (cpuUsage?.aggregation?.max / cpuTotal?.aggregation?.max) * 100,
    };

    const maxLabel = {
      label: "Maximum",
      value: Number.isNaN(values.max) ? "-" : round(values.max, 2),
      color: USAGE_LINE_CHART_COLORS.MAX,
    };
    const maxData = {
      id: "max",
      color: USAGE_LINE_CHART_COLORS.MAX,
      data: mapChartPoints({
        metric: cpuUsage,
        total: cpuTotal,
        key: "max",
        dateUnit: unit,
        customFormat,
      }),
    };
    let statistics = [
      {
        label: "Average",
        value: Number.isNaN(values.avg) ? "-" : round(values.avg, 2),
        color: period
          ? USAGE_LINE_CHART_COLORS.AVG
          : USAGE_LINE_CHART_COLORS.MAX,
      },
    ];
    let chartData = [
      {
        id: "avg",
        color: period
          ? USAGE_LINE_CHART_COLORS.AVG
          : USAGE_LINE_CHART_COLORS.MAX,
        data: mapChartPoints({
          metric: cpuUsage,
          total: cpuTotal,
          key: "avg",
          dateUnit: unit,
          customFormat,
        }),
      },
    ];

    if (period) {
      statistics = [...statistics, maxLabel];
      chartData = [...chartData, maxData];
    }

    const data = {
      label: "CPU Utilization %",
      statistics,
      chartData,
    };

    return data;
  }
);

export const getMemoryUtilizationMetrics = createSelector(
  utilizationFetcher.selector,
  (state) => state.forms[NODE_DETAILS_FILTERS_MODULE]?.data?.dateTime,
  ({ result }, dateUnit = "") => {
    if (!result || !dateUnit) {
      return [];
    }

    const { memoryUsage, memoryTotal, period } = result;

    const unit = dateUnit.split(" ")[1];
    const customFormat = dateUnit === "24 hours" ? "HH:mm DD MMM" : null;

    const values = {
      avg:
        (memoryUsage?.aggregation?.avg / memoryTotal?.aggregation?.avg) * 100,
      max:
        (memoryUsage?.aggregation?.max / memoryTotal?.aggregation?.max) * 100,
    };

    const maxLabel = {
      label: "Maximum",
      value: Number.isNaN(values.max) ? "-" : round(values.max, 2),
      color: USAGE_LINE_CHART_COLORS.MAX,
    };
    const maxData = {
      id: "max",
      color: USAGE_LINE_CHART_COLORS.MAX,
      data: mapChartPoints({
        metric: memoryUsage,
        total: memoryTotal,
        key: "max",
        dateUnit: unit,
        customFormat,
      }),
    };

    let statistics = [
      {
        label: "Average",
        value: Number.isNaN(values.avg) ? "-" : round(values.avg, 2),
        color: USAGE_LINE_CHART_COLORS.AVG,
      },
    ];

    let chartData = [
      {
        id: "avg",
        color: period
          ? USAGE_LINE_CHART_COLORS.AVG
          : USAGE_LINE_CHART_COLORS.MAX,
        data: mapChartPoints({
          metric: memoryUsage,
          total: memoryTotal,
          key: "avg",
          dateUnit: unit,
          customFormat,
        }),
      },
    ];

    if (period) {
      statistics = [...statistics, maxLabel];
      chartData = [...chartData, maxData];
    }

    const data = {
      label: "Memory Utilization %",
      statistics,
      chartData,
    };

    return data;
  }
);

export const getNodeChartMetrics = createSelector(
  getCpuUtilizationMetrics,
  getMemoryUtilizationMetrics,
  (state) => state.forms[NODE_DETAILS_FILTERS_MODULE]?.data?.utilizationType,
  (cpu, memory, utilizationType) => {
    if (utilizationType === "cpu") {
      return cpu;
    }
    return memory;
  }
);

export const hasKubeConfig = createSelector(getRawCluster, (cluster) => {
  const kubeMeta = cluster.status?.kubeMeta;

  return !!cluster.status?.kubeMeta?.hasKubeConfig;
});

export const hasKubeConfigClient = createSelector(getRawCluster, (cluster) => {
  const kubeMeta = cluster.status?.kubeMeta;

  return !!cluster.status?.kubeMeta?.hasKubeConfigClient;
});

export const getKubernetesConfigUrl = createSelector(
  getRawCluster,
  getCurrentProjectUidFromUrl,
  (cluster, currentProjectUid) => {
    const kubeMeta = cluster.status?.kubeMeta;
    const suffix = !!kubeMeta?.hasKubeConfigClient
      ? "kubeconfigclient"
      : "kubeconfig";
    let baseUrl = `/v1/spectroclusters/${cluster.metadata.uid}/assets/${suffix}`;
    if (currentProjectUid) {
      return `${baseUrl}?ProjectUid=${currentProjectUid}`;
    }

    return baseUrl;
  }
);

export const getAdminKubernetesConfigUrl = createSelector(
  getRawCluster,
  getCurrentProjectUidFromUrl,
  (cluster, currentProjectUid) => {
    let baseUrl = `/v1/spectroclusters/${cluster.metadata.uid}/assets/adminKubeconfig`;
    if (currentProjectUid) {
      return `${baseUrl}?ProjectUid=${currentProjectUid}`;
    }

    return baseUrl;
  }
);

export const getCluster = createSelector(
  getRawCluster,
  (state) => state.cluster.nodes.desiredNodePoolSizes,
  isClusterProvisioning,
  (cluster, desiredNodePoolSizes, isProvisioning) => {
    if (!cluster) {
      return cluster;
    }

    const type = cluster.spec.cloudType ? "cloud" : "edge";
    const { cloudConfig } = cluster.spec;
    if (!cloudConfig) {
      return cluster;
    }
    const nodePoolSizes = {
      masters: 0,
      workers: 0,
    };

    const nodePools = cloudConfig.spec.machinePoolConfig.map((nodePool) => {
      if (nodePool.isControlPlane === true) {
        nodePoolSizes.masters = nodePoolSizes.masters + nodePool.size;
      } else {
        nodePoolSizes.workers = nodePoolSizes.workers + nodePool.size;
      }
      if (nodePool?.nodes?.length === nodePool.size) {
        return nodePool;
      }

      const maxNodes = Math.max(
        nodePool.size,
        nodePool?.nodes?.length || 0,
        desiredNodePoolSizes[nodePool.name] || 0
      );

      let machines = nodePool?.nodes || [];
      const isNodePoolConfigured =
        nodePool.instanceType && nodePool.azs?.length;
      const nodes = Array.from({ length: maxNodes }).map((_, index) => {
        return (
          machines[index] || {
            ...nodeMock(isNodePoolConfigured, isProvisioning),
            isPlaceholder: true,
          }
        );
      });

      if (["Deleted", "Deleting"].includes(cluster.status.state)) {
        return { ...nodePool, nodes: machines };
      }

      return { ...nodePool, nodes };
    });

    return {
      ...cluster,
      type,
      spec: {
        ...cluster.spec,
        machinePoolConfig: nodePools,
        nodePoolSizes,
      },
    };
  }
);

export const isEnvVsphereEdge = createSelector(getCluster, (cluster) => {
  return cluster?.spec?.cloudConfig?.spec?.edgeHostRef?.uid;
});

const CONDITION_CATEGORIES = [
  {
    type: "Provisioning",
    conditions: [
      {
        category: "preparation",
        conditions: [
          "ImageResolutionDone",
          "IpAllocationDone",
          "BootstrappingDone",
        ],
      },
      {
        category: "deployment",
        conditions: [
          "CloudInfrastructureReady",
          "ControlPlaneNodeAdditionDone",
          "WorkerNodeAdditionDone",
        ],
      },
      {
        category: "configuration",
        conditions: ["AddOnDeploymentDone", "ProfileArtifactsDownload"],
      },
    ],
  },
  {
    type: "Deleting",
    conditions: [
      {
        category: "preparation",
        conditions: ["DeProvisionDone"],
      },
      {
        category: "cleanup",
        conditions: [
          "CloudInfrastructureCleanedUp",
          "ControlPlaneNodeDeletionDone",
          "WorkerNodeDeletionDone",
        ],
      },
    ],
  },
];

export const getClusterProfile = createSelector(getCluster, (cluster) => {
  return cluster.spec.clusterprofile;
});

function getComposedCondition({ type, conditions }, rawConditions) {
  const subConditions = rawConditions.filter((condition) =>
    [conditions].includes(condition.type)
  );
  let state = "pending";
  let status = subConditions.map((condition) => condition.reason).join("");

  if (subConditions.length) {
    state = "loading";

    const isDone = subConditions.every(
      (condition) => condition.status === "True"
    );
    if (isDone) {
      state = "success";
      status = [];
    }

    const failedCondition = subConditions.find(
      (condition) => condition.reason === "Failure"
    );
    if (failedCondition) {
      state = "error";
      status = failedCondition.message;
    }
  }

  return {
    type,
    state,
    status,
  };
}

function getNormalizedCondition(condition, type) {
  let state = "pending";
  let status = condition?.reason || "";

  if (condition) {
    state = "loading";

    if (["True", "true"].includes(condition.status)) {
      state = "success";
      status = "";
    }

    if (condition.status === "False" && condition.reason === "Failure") {
      state = "error";
      status = condition.message;
    }
  }

  return {
    type: condition?.type || type,
    state,
    status,
    message: condition?.message,
  };
}

const getClusterRawConditions = createSelector(getCluster, (cluster) => {
  return (cluster.status?.conditions || []).filter(
    (condition) => condition.type !== "Unknown"
  );
});

export const getClusterConditions = createSelector(
  getCluster,
  getClusterRawConditions,
  isVirtualCluster,
  (cluster, rawConditions, isVirtualCluster) => {
    const clusterState = cluster.status?.state;
    const cloudType = cluster.spec?.cloudConfig?.kind;
    const isStaticIP = cluster.spec?.cloudConfig?.spec?.clusterConfig?.staticIp;
    const IP_ALLOCATION_TYPE = "IpAllocationDone";

    if (clusterState === "Running") {
      const conditionsInProgress = rawConditions.filter((condition) =>
        ["false", "False"].includes(condition.status)
      );
      if (!conditionsInProgress.length) {
        return [];
      }

      return [
        {
          category: "",
          conditions: conditionsInProgress.map(getNormalizedCondition),
        },
      ];
    }

    const currentStage = CONDITION_CATEGORIES.find(
      (stage) => stage.type === clusterState
    );

    if (!currentStage) {
      return [];
    }

    return currentStage.conditions.map(({ category, conditions }) => {
      let categoryConditions = conditions.map((type) => {
        if (typeof type !== "string") {
          return getComposedCondition(type, rawConditions);
        }

        const condition = rawConditions.find(
          (condition) => condition.type === type
        );

        return getNormalizedCondition(condition, type);
      });

      // BET-1134 if deletion then ControlPlaneNodeDeletionDone, WorkerNodeDeletionDone are successful if CloudInfrastructureCleanedUp is
      const CloudInfrastructureCleanedUp = categoryConditions.find(
        ({ type }) => type === "CloudInfrastructureCleanedUp"
      );
      categoryConditions = categoryConditions.map((condition) => {
        if (
          !["ControlPlaneNodeDeletionDone", "WorkerNodeDeletionDone"].includes(
            condition.type
          ) ||
          !CloudInfrastructureCleanedUp
        ) {
          return condition;
        }

        return {
          ...condition,
          state:
            CloudInfrastructureCleanedUp.state !== "success"
              ? "pending"
              : condition.state,
        };
      });

      if (!(cloudType === "vsphere" && isStaticIP)) {
        categoryConditions = categoryConditions.filter(
          (condition) => condition.type !== IP_ALLOCATION_TYPE
        );
      }
      if (["eks", "aks", "gke", "tke"].includes(cloudType)) {
        categoryConditions = categoryConditions.filter(
          (condition) => condition.type !== "ImageCustomizationDone"
        );
      }

      if (cloudType !== "libvirt" && !isVirtualCluster) {
        categoryConditions = categoryConditions.filter(
          (condition) => condition.type !== "ProfileArtifactsDownload"
        );
      }

      if (isVirtualCluster) {
        categoryConditions = categoryConditions
          .filter((condition) =>
            [
              "BootstrappingDone",
              "CloudInfrastructureReady",
              "AddOnDeploymentDone",
              "CloudInfrastructureCleanedUp",
              "DeProvisionDone",
            ].includes(condition.type)
          )
          .map((condition) => {
            // for virtual clusters we will check the CloudInfrastructureReady and CloudInfrastructureCleanedUp conditions and change their type
            if (condition.type === "CloudInfrastructureReady") {
              return {
                ...condition,
                type: "NestedCloudInfrastructureReady",
              };
            }

            if (condition.type === "CloudInfrastructureCleanedUp") {
              return {
                ...condition,
                type: "NestedCloudInfrastructureCleanedUp",
              };
            }

            return condition;
          });
      }

      let state = "";
      const isDone = categoryConditions.every(
        (condition) => condition.state === "success"
      );
      const hasError = categoryConditions.find(
        (condition) => condition.state === "error"
      );

      const isLoading = categoryConditions.find(
        (condition) => condition.state === "loading"
      );

      if (isDone) {
        state = "success";
      }

      if (hasError) {
        state = "error";
      }

      return {
        category,
        conditions: categoryConditions,
        state,
        isLoading,
      };
    });
  }
);

// TODO: this should be replaced with clusterProfileTemplates.find(template => template.type !== "addon")
export const getClusterProfileParams = createSelector(getCluster, (cluster) => {
  const packs = (cluster?.spec?.clusterProfileTemplates || []).reduce(
    (acc, template) => [...acc, ...template.packs],
    []
  );
  return presentClusterProfileParams(packs);
});

export const hasAutoscalePack = createSelector(
  getClusterProfileParams,
  (packs) => packs.some((pack) => pack.name === "aws-cluster-autoscaler")
);

export const isClusterActive = createSelector(getCluster, (cluster) => {
  return ["Running", "Unknown"].includes(cluster?.status?.state);
});

export const isClusterPending = createSelector(getCluster, (cluster) => {
  return cluster?.status?.state === "Pending";
});

export const isClusterUpdating = createSelector(
  getCluster,
  getClusterRawConditions,
  (cluster, rawConditions) => {
    const inProgress = rawConditions.some(
      (condition) => condition.status === "False"
    );

    return (
      ["Running", "Unknown"].includes(cluster?.status?.state) && inProgress
    );
  }
);

function isUpdateNotificationActionable(notification) {
  const events = notification.action?.events;
  if (!events) {
    return;
  }

  if (notification?.isDone) {
    return;
  }

  return notification;
}

export const getUpdateNotification = createSelector(
  getRawCluster,
  (cluster) => {
    const notifications = cluster?.notifications || [];
    return notifications.find(isUpdateNotificationActionable);
  }
);

export const isEventConfirmed = createSelector(getRawCluster, (cluster) => {
  return cluster?.notifications?.some(
    (notification) => notification?.action?.isDone === false
  );
});

export const canUpdateCluster = createSelector(
  getUpdateNotification,
  isEventConfirmed,
  isClusterActive,
  isClusterPending,
  isClusterProvisioning,
  hasClusterImportFinished,
  (
    notification,
    isEventConfirmed,
    isActive,
    isPending,
    isProvisioning,
    isImported
  ) => {
    return (
      !!notification &&
      (isPending || isActive || isImported || isProvisioning) &&
      isEventConfirmed
    );
  }
);

export const isClusterDeleted = createSelector(getRawCluster, (cluster) => {
  return cluster.status?.state === "Deleted";
});

export const isClusterDeleting = createSelector(getRawCluster, (cluster) => {
  return cluster.status?.state === "Deleting";
});

export const getClusterErrors = createSelector(
  clusterErrorFetcher.selector,
  ({ isLoading, result }) => {
    if (!result) {
      return [];
    }
    return result.items?.slice(0, 3);
  }
);

export const showInfoNotification = createSelector(
  getRawCluster,
  (state) => state.cluster.details.scopePackValues,
  (cluster, scopePackValues) => {
    if (scopePackValues.length > 0) {
      return false;
    }

    return cluster?.notifications?.some(
      (notification) => notification?.action?.isInfo
    );
  }
);

const getSelectedPack = createSelector(
  getClusterProfileParams,
  (state) => state.cluster?.details?.currentPackGuid,
  (clusterProfileParams, packGuid) => {
    return clusterProfileParams.find((pack) => pack.guid === packGuid);
  }
);

export const getPresetsOptions = createSelector(
  getSelectedPack,
  (selectedPack) => {
    return groupPresetsOptions(selectedPack?.spec?.presets);
  }
);

export const getEditorSchema = createSelector(
  getSelectedPack,
  (selectedPack) => {
    return generateEditorYamlSchema(selectedPack?.spec?.schema);
  }
);

export const getDefaultPackValues = createSelector(
  getClusterProfileParams,
  (state) => state.cluster?.details?.currentPackGuid,
  (clusterProfileParams, packGuid) => {
    return clusterProfileParams.find((params) => params.guid === packGuid)
      ?.values;
  }
);

export const getResolvedValues = createSelector(
  getRawCluster,
  (cluster) => cluster?.status?.resolved || {}
);

export const getCertificatesCount = createSelector(
  clusterCertificatesFetcher.selector,
  ({ result }) => {
    return result?.items?.length;
  }
);

export const areCertificatesLoading = createSelector(
  (state) => state.cluster.details.isLoading,
  clusterCertificatesFetcher.selector,
  (isClusterLoading, { isLoading }) => {
    return isLoading || isClusterLoading;
  }
);

export const getOnDemandPatchAfter = createSelector(getCluster, (cluster) => {
  return cluster?.spec?.clusterConfig?.machineManagementConfig?.osPatchConfig
    ?.onDemandPatchAfter;
});

export const isOnDemandPatchActive = createSelector(
  getOnDemandPatchAfter,
  (onDemandPatchAfter) => {
    return (
      onDemandPatchAfter &&
      onDemandPatchAfter !== BEGINNING_OF_TIME &&
      moment().diff(moment(onDemandPatchAfter)) < 0
    );
  }
);

export const getClusterProfileTemplate = createSelector(
  getRawCluster,
  getEntity(
    (state) => state.cluster.details.profiles,
    [ClusterProfileTemplateSchema]
  ),
  (cluster, entityProfileTemplates) => {
    const clusterProfileTemplates = cluster.spec.clusterProfileTemplates || [];

    return entityProfileTemplates.map((profile) => {
      const profileTemplate = clusterProfileTemplates.find(
        (profileTemplate) => profileTemplate.uid === profile.profileRef.uid
      );

      return {
        ...profile,
        metadata: profile.profileRef,
        spec: {
          ...profile.spec,
          version: profileTemplate?.profileVersion,
          published: {
            packs: profile.spec.packs
              .map((pack) => {
                const clusterPack = (profileTemplate?.packs || []).find(
                  (templatePack) => {
                    return pack.metadata.name === templatePack.name;
                  }
                );

                if (!clusterPack) {
                  return undefined;
                }

                return {
                  ...pack,

                  isDisabled:
                    cluster.spec.cloudType === COXEDGE_ENVIRONMENT.apiKey &&
                    pack.spec.layer === "k8s",

                  guid: clusterPack.guid,
                  spec: {
                    ...pack.spec,
                    manifests: clusterPack.manifests,
                    tag: clusterPack?.tag,
                  },
                };
              })
              .filter(Boolean),
          },
        },
        type: profile.spec.type,
      };
    });
  }
);

function extractPackFromEvent(event, [clusterInfo, profileInfo] = []) {
  let packSpec = event.pack.spec;
  let eventMeta = { message: event.message, type: event.type };
  const newManifest = {
    ...event.manifest,
    event: eventMeta,
    isDisabled: event?.type === "PackManifestDelete",
  };

  let manifests = [];
  if (event.manifest) {
    manifests = [newManifest];
  }
  if (event.type === "PackCreate") {
    manifests = (clusterInfo?.spec?.manifests || []).map((manifest) => ({
      ...manifest,
      event: { type: "PackManifestCreate" },
    }));
    packSpec = clusterInfo?.spec || event.pack.spec;
  }

  const packMeta = {
    metadata: event.pack.metadata,
    guid: event.pack.guid,
    logo: packSpec?.logoUrl,
    name: packSpec?.displayName || packSpec?.name || event.pack.metadata.name,
    tag: packSpec?.tag || packSpec?.version,
    event: eventMeta,
    manifests,
    type: event.packType,
    isDisabled: event?.type === "PackDelete",
  };

  if (event.packType === "manifest") {
    if (event.type === "PackManifestUpdate") {
      eventMeta = {
        type: "PackValuesUpdate",
        message: i18next.t("{{packName}} values are updated", {
          packName: event.packName,
        }),
      };
    }

    return {
      ...packMeta,
      event: eventMeta,
      tag: null,
    };
  }

  return packMeta;
}

export const getUpdateNotificationProfiles = createSelector(
  getUpdateNotification,
  (state) => state.cluster.details.notification.divergences,
  (updateNotification, divergencies) => {
    const profiles = [];

    if (!updateNotification) {
      return [];
    }

    updateNotification.events.forEach((event) => {
      const existingProfile = profiles.find((profile) => {
        return profile.metadata.uid === event.profileUid;
      });

      const eventMeta = { message: event.message, type: event.type };
      const newPack = extractPackFromEvent(
        event,
        divergencies[event.profileUid]?.[event.packName]?.items
      );

      if (!existingProfile) {
        profiles.push({
          metadata: event.profile.metadata,
          guid: event.profile.guid,
          spec: {
            packs: [newPack],
          },
        });

        return;
      }

      const existingPack = existingProfile.spec.packs.find(
        (pack) => pack.metadata.name === event.packName
      );

      if (!existingPack) {
        existingProfile.spec.packs.push(newPack);
        return;
      }

      if (event.manifest) {
        existingPack.manifests.push(newPack?.manifests?.[0]);
        return;
      }

      existingPack.event = eventMeta;
    });

    return profiles;
  }
);

export const getClusterDivergencies = createSelector(
  getUpdateNotificationProfiles,
  (state) => state.cluster.details.notification.divergences,
  (profiles, divergencies) => {
    return Object.keys(divergencies).reduce((accumulator, profileKey) => {
      const profile = profiles.find(
        (profile) => profile.metadata.uid === profileKey
      );
      Object.keys(divergencies[profileKey]).forEach((packName) => {
        const packVersion = profile?.spec?.packs?.find(
          (pack) => pack.metadata.name === packName
        );
        const [clusterInfo, profileInfo] =
          divergencies[profileKey][packName]?.items;

        if (!packVersion) {
          return;
        }

        const isOverridden = clusterInfo?.spec?.isValuesOverridden;

        if (isOverridden) {
          if (profileInfo) {
            accumulator[packVersion.guid] = {
              values: getPackValuesWithoutPresetsComment(
                profileInfo?.spec?.values
              ),
              isInversed: isOverridden,
            };
          }
        } else {
          if (packVersion?.event?.type === "PackCreate") {
            accumulator[packVersion.guid] = {
              isInversed: true,
            };
          } else {
            accumulator[packVersion.guid] = {
              values: getPackValuesWithoutPresetsComment(
                clusterInfo?.spec?.values
              ),
              isInversed: isOverridden,
            };
          }
        }

        const manifests = packVersion.manifests;

        if (Array.isArray(manifests)) {
          manifests.forEach((manifest) => {
            const parentUid = manifest.parentUid;
            const associatedManifest = profileInfo?.spec?.manifests?.find(
              (profileManifest) => profileManifest.uid === parentUid
            );

            if (manifest?.event?.type === "PackManifestCreate") {
              accumulator[manifest.guid] = {
                isInversed: false,
              };

              return;
            }

            if (associatedManifest) {
              accumulator[manifest.guid] = {
                values: associatedManifest.content,
                isInversed: manifest.isOverridden,
              };
            } else {
              accumulator[manifest.guid] = {
                values: manifest.content,
                isInversed: manifest.isOverridden,
              };
            }
          });
        }
      });

      return accumulator;
    }, {});
  }
);

export const getClusterPermissions = createSelector(
  getRawCluster,
  (cluster) => {
    return new PermissionService(
      cluster?.metadata?.annotations?.permissions?.split(",")
    );
  }
);

export const canDownloadLogs = createSelector(
  isClusterImporting,
  isVirtualCluster,
  (state) => state.location?.params,
  getClusterPermissions,
  (isImporting, isVirtualCluster, params, clusterPermissions) => {
    const { clusterCategory, id } = params || {};

    if (isVirtualCluster) {
      return clusterPermissions.has("virtualCluster.get");
    }

    if (isImporting) {
      return false;
    }
    if (clusterCategory === "privatecloudgateways" && id === "system") {
      return false;
    }
    return true;
  }
);

function parseRealTimeNamespaceUsage({ result, getY, unit }) {
  return (result || []).map((item, index) => ({
    value: getY(item),
    id: item.entity.name,
    label: item.entity.name,
    color: COST_CHART_COLORS[index],
    unit,
  }));
}

export const getRealTimeCpuNamespaceUsageMetrics = createSelector(
  realTimeConsumptionFetcher.selector,
  ({ result }) => {
    const resources = result?.resources || [];
    const unit = "Millicores";
    const getY = (item) => formatToKilocoreHours(item.total?.usage?.cpu, 5);
    const data = parseRealTimeNamespaceUsage({ result: resources, getY, unit });
    const totalAllotted = result?.total?.allotted?.cpu;
    const totalUsage = result?.total?.usage?.cpu;
    const totalPercentage = totalAllotted
      ? round((totalUsage / totalAllotted) * 100, 2)
      : 0;

    let labelData = {
      value: totalPercentage > 100 ? "100%" : `${totalPercentage}%`,
      label: i18next.t("Used from total"),
      chartLabel: "CPU",
    };

    return {
      data,
      labelData,
    };
  }
);

export const getRealTimeMemoryNamespaceUsageMetrics = createSelector(
  realTimeConsumptionFetcher.selector,
  ({ result }) => {
    const resources = result?.resources || [];
    const unit = "GB";
    const getY = (item) => parseKiBToGB(item.total?.usage?.memory);
    const data = parseRealTimeNamespaceUsage({ result: resources, getY, unit });
    const totalAllotted = result?.total?.allotted?.memory;
    const totalUsage = result?.total?.usage?.memory;
    const totalPercentage = totalAllotted
      ? round((totalUsage / totalAllotted) * 100, 2)
      : 0;

    let labelData = {
      value: totalPercentage > 100 ? "100%" : `${totalPercentage}%`,
      label: i18next.t("Used from total"),
      chartLabel: "Memory",
    };

    return {
      data,
      labelData,
    };
  }
);

export const getClusterCost = createSelector(getRawCluster, (cluster) => {
  const totalCost = cluster?.status?.cost?.total;
  return formatToUSCurrency(totalCost, "usd");
});

export const shouldDisableNodesLogsDownload = createSelector(
  getRawCluster,
  isBrownfield,
  isVirtualCluster,
  (cluster, isBrownfield, isVirtualCluster) => {
    const cloudType = cluster.spec?.cloudConfig?.kind;
    return (
      isBrownfield || ["eks", "aks"].includes(cloudType) || isVirtualCluster
    );
  }
);

export const getClusterCloudType = createSelector(getRawCluster, (cluster) => {
  return cluster.spec.cloudType;
});

export const isBaremetal = createSelector(getRawCluster, (cluster) => {
  return BAREMETAL_ENVS.includes(cluster.spec.cloudType);
});

export const isEdgeCluster = createSelector(getRawCluster, (cluster) => {
  return cluster.spec.cloudType === "edge";
});

export const canUpdateSettings = createSelector(
  getClusterPermissions,
  isClusterDeleted,
  isClusterDeleting,
  isVirtualCluster,
  (permissions, isDeleted, isDeleting, isVirtual) => {
    const hasUpdatePermissions = isVirtual
      ? permissions.has("virtualCluster.update")
      : permissions.has("cluster.update");
    return hasUpdatePermissions && !isDeleted && !isDeleting;
  }
);

export const canDeleteCluster = createSelector(
  getCluster,
  getClusterPermissions,
  isClusterDeleted,
  isClusterDeleting,
  isVirtualCluster,
  (cluster, permissions, isDeleted, isDeleting, isVirtual) => {
    const hasDeletePermissions = isVirtual
      ? permissions.has("virtualCluster.delete")
      : permissions.has("cluster.delete");
    return (
      hasDeletePermissions &&
      !isDeleted &&
      !isDeleting &&
      !cluster?.metadata?.annotations?.overlordMode
    );
  }
);

export const getClusterType = createSelector(
  getCluster,
  getClusterCloudType,
  (cluster, cloudType) => {
    if (
      cluster.spec.cloudConfig.spec.edgeHostRef?.uid &&
      cloudType === "vsphere"
    ) {
      return "baremetal";
    }
  }
);

export const isImportModeReadOnly = createSelector(getRawCluster, (cluster) => {
  return cluster?.metadata?.annotations?.importMode === "read-only";
});

export const hasRestrictedAccess = createSelector(
  isImportModeReadOnly,
  isClusterImportMigrating,
  (isReadOnly, isMigrating) => {
    return isReadOnly || isMigrating;
  }
);

export const isReadOnlyCategory = createSelector(
  getRawCluster,
  isBrownfield,
  (state) => state?.location?.params?.clusterCategory,
  canUpdateSettings,
  isImportModeReadOnly,
  (cluster, isBrownfield, clusterCategory, canUpdateCluster, isReadOnly) => {
    const clusterLabels = cluster?.metadata?.labels;
    const ignoreImport = clusterLabels?.imported === "false";

    return (
      ignoreImport ||
      isBrownfield ||
      clusterCategory === "privatecloudgateways" ||
      !canUpdateCluster ||
      isReadOnly
    );
  }
);

export const useFailSafeMechanism = createSelector(
  isBrownfield,
  isClusterActive,
  (isBrownField, isClusterActive) => {
    return isBrownField || isClusterActive;
  }
);

export const getClusterUpgradeMessages = createSelector(
  getCluster,
  (cluster) => {
    const clusterUpgradesMessages = (cluster?.status?.upgrades || []).flatMap(
      (upgrade) =>
        (upgrade?.reason || []).map((reason) => ({
          timestamp: upgrade.timestamp,
          reason,
        }))
    );
    return clusterUpgradesMessages?.sort(
      (upgrade1, upgrade2) =>
        new Date(upgrade2?.timestamp).getTime() -
        new Date(upgrade1?.timestamp).getTime()
    );
  }
);

export const isClusterAssociatedToWorkspaces = createSelector(
  getCluster,
  (cluster) => {
    return cluster?.status?.workspaces?.length > 0;
  }
);

export const getNamespaceSelectOptions = createSelector(
  (state) => state.forms?.editRoleBindings?.data?.namespaces,
  (namespaces) => {
    return (namespaces || [])
      .filter((ns) => !ns.isChild)
      .map((ns) => ({
        label: ns.namespaceName,
        value: ns.namespaceName,
      }));
  }
);

export const getClusterInstanceTypes = createSelector(
  (state) => state.cluster?.nodes?.totalRates,
  (rates) => {
    return rates?.resourceMetadata?.instanceTypes || {};
  }
);

export const isApplianceAssociatedCluster = createSelector(
  getCluster,
  (cluster) => cluster?.metadata?.annotations?.primaryEdgeHostUid
);

export const getProfileArtifactsDownloadCondition = createSelector(
  getClusterRawConditions,
  (conditions) => {
    const profileArtifactsDownloadCondition = conditions
      .map((condition) => getNormalizedCondition(condition))
      .find((condition) => condition.type === "ProfileArtifactsDownload");
    return profileArtifactsDownloadCondition;
  }
);

export const getTencentSshKeys = createSelector(
  getRawCluster,
  tencentSshKeyPairsFetcher.selector,
  (cluster, { result }) => {
    const clusterConfig = cluster?.spec?.cloudConfig?.spec?.clusterConfig;

    return (result || []).reduce((acc, sshKey) => {
      if ((clusterConfig?.sshKeyIDs || []).includes(sshKey.id)) {
        return [...acc, sshKey.name];
      }
      return acc;
    }, []);
  }
);

export const getClusterCloudAccount = createSelector(
  getRawCluster,
  (cluster) => {
    return cluster.spec?.cloudConfig?.spec?.cloudAccount;
  }
);

export const getSystemClusterProfile = createSelector(
  getRawCluster,
  (cluster) => {
    const profileTemplates = cluster?.spec?.clusterProfileTemplates || [];
    return (profileTemplates || []).find((cp) => cp.type === "system");
  }
);

export const getRawAttachedProfile = getEntity(
  (state) => state.cluster.details.attachedProfileUid,
  ClusterProfileSchema
);

export const getClusterPacksStatuses = createSelector(
  getRawCluster,
  (cluster) => {
    return (cluster?.status?.packs || []).reduce(
      (accumulator, { condition, profileUid, name, version }) => {
        let state = "pending";
        let status = "";

        if (condition) {
          state = "loading";
          status = condition.message;

          if (
            ["True", "true"].includes(condition.status) &&
            ["Ready", "Installed"].includes(condition.type)
          ) {
            state = "success";
            status = "";
          }

          if (
            ["True", "true"].includes(condition.status) &&
            condition.type === "Error"
          ) {
            state = "error";
          }
        }

        accumulator[`${profileUid}~${name}~${version}`] = {
          type: condition?.type,
          state,
          status,
          message: condition?.message,
          profileUid,
          packName: name,
          packVersion: version,
        };

        return accumulator;
      },
      {}
    );
  }
);

export const getKubectlRedirectUri = createSelector(
  kubectlRedirectUriFetcher.selector,
  ({ result }) => {
    const authToken = loginService.getAuthorizationToken();
    return `${result?.redirectUri}${authToken}`;
  }
);

export const isClusterLocked = createSelector(getCluster, (cluster) => {
  return !!cluster?.metadata?.annotations?.spectroComponentsUpgradeForbidden;
});

export const getClusterKubernetesVersion = createSelector(
  getCluster,
  (cluster) => {
    return cluster?.status?.kubeMeta?.kubernetesVersion;
  }
);

export const areContextClusterLocked = createSelector(
  getContextCurrentLock.selector,
  ({ result }) => result === "lock"
);

export const getHostClusterCloudType = createSelector(
  hostClusterFetcher.selector,
  ({ result }) => {
    return result?.spec?.cloudType;
  }
);

export const getKubernetesDashboardEndpoint = createSelector(
  getCluster,
  (cluster) => {
    const addOnServices = cluster?.status?.addOnServices || [];
    return addOnServices.find(
      (service) => service?.name === "KubernetesDashboard"
    )?.endpoint;
  }
);

export const isPublicCloudCluster = createSelector(
  getClusterCloudType,
  (cloudType) => {
    return ["aws", "azure", "gcp", "eks", "aks", "tke"].includes(cloudType);
  }
);

export const getSandboxClusterLifecycleStatus = createSelector(
  getCluster,
  (cluster) =>
    (cluster?.status?.virtual?.lifecycleStatus?.status || "").toLowerCase()
);

export const getClusterAllocatedQuota = createSelector(
  getCluster,
  (cluster) => {
    const currentCloudConfig = cluster?.spec?.cloudConfig;
    const { machinePoolConfig } = currentCloudConfig?.spec || {};
    const instanceType = machinePoolConfig?.[0]?.instanceType;

    return {
      cpu: instanceType?.maxCPU || 0,
      memory: instanceType?.maxMemInMiB / 1024 || 0,
      storage: instanceType?.maxStorageGiB || 0,
    };
  }
);

export const getClusterAppDeployments = createSelector(
  getCluster,
  (cluster) => cluster?.status?.virtual?.appDeployments || []
);

export const getClusterQuotaRemainingPercentages = createSelector(
  (state) => state.forms[EDIT_CLUSTER_SIZE_FORM_MODULE]?.data,
  sandboxClusterQuotaUsageFetcher.selector,
  getClusterAllocatedQuota,
  (formData, { result }, currentQuota) => {
    const { usedCredit, allocatedCredit } = result || {};
    const excludedUsedCredit = excludeClusterCurrentQuota(
      usedCredit,
      currentQuota
    );

    return getQuotaPercentages(formData, excludedUsedCredit, allocatedCredit);
  }
);

export const canEditClusterTags = createSelector(
  getClusterPermissions,
  (permissions) => {
    return permissions.has("tag.update");
  }
);

export const isClusterGroupProjectScoped = createSelector(
  getRawCluster,
  (cluster) => {
    const hostCluster = cluster?.status?.virtual?.hostCluster || {};
    return !!hostCluster?.projectUid;
  }
);

export const getK8sOIDCSetting = createSelector(
  getClusterProfileTemplate,
  (profiles) => {
    const packs = profiles.flatMap((profile) => profile.spec.packs);
    const k8sPack = packs.find((pack) => pack.spec.layer === "k8s");

    if (!k8sPack) {
      return false;
    }

    const setting = (
      k8sPack.spec?.template?.parameters?.inputParameters || []
    ).find((param) => param.name === "identityProvider");

    if (!setting) {
      return false;
    }

    return (
      YAML.parseDocument(k8sPack.spec.values).getIn(
        setting.targetKey.split(".")
      ) || "noauth"
    );
  }
);

export const hasVMOTab = createSelector(getK8sOIDCSetting, (oidc) => {
  return ["palette", "noauth"].includes(oidc);
});

export const getVmoServiceURL = createSelector(
  getCluster,
  (cluster) => getVmService(cluster)?.endpoint
);

export const getClusterServices = createSelector(
  getCluster,
  getKubernetesDashboardEndpoint,
  (cluster, kubernetesDashboardEndpoint) => {
    const services = cluster?.status?.services || [];

    if (!kubernetesDashboardEndpoint) {
      return services;
    }

    return services.filter(
      (service) => service?.name !== "kubernetes-dashboard"
    );
  }
);
