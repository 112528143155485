export function LIST_FETCH_ITEMS_REQUEST(nextState, { module }, state) {
  if (!state[module]) {
    return;
  }
  nextState[module].isRequesting = true;
  nextState[module].isLoading = true;
}

export function LIST_FETCH_ITEMS_NORMALIZATION(
  nextState,
  { module, result, token, currentPageNumber, hasPagination },
  state
) {
  if (!state[module]) {
    return;
  }
  const itemKey = hasPagination ? currentPageNumber : token;
  nextState[module].items[itemKey] = result;
  nextState[module].pages.add(itemKey);
}

export function LIST_INITIALIZE_NORMALIZATION(...args) {
  LIST_FETCH_ITEMS_NORMALIZATION(...args);
}

export function LIST_FETCH_ITEMS_SUCCESS(nextState, { module }, state) {
  if (!state[module]) {
    return;
  }
  nextState[module].isRequesting = false;
  nextState[module].isLoading = false;
  nextState[module].isInitializing = false;
}

export function LIST_INITIALIZE_SUCCESS(...args) {
  LIST_FETCH_ITEMS_SUCCESS(...args);
}

export function LIST_RESET_ITEMS(nextState, { module, hasPagination }) {
  const token = "initial";
  const pageNumber = 1;
  const itemKey = hasPagination ? pageNumber : token;

  nextState[module].token = token;
  nextState[module].items = {
    [itemKey]: [],
  };
  nextState[module].pages = new Set([itemKey]);
  nextState[module].currentPageNumber = pageNumber;
}

export function LIST_CHANGE_QUERY(
  nextState,
  { module, name, value, hasPagination },
  state
) {
  nextState[module].isLoading = true;
  nextState[module].query[name] = value;
  LIST_RESET_ITEMS(nextState, { module, hasPagination }, state);
}

export function BATCH_CHANGE_QUERY(
  nextState,
  { module, query, hasPagination },
  state
) {
  nextState[module].isLoading = true;
  nextState[module].query = query;
  LIST_RESET_ITEMS(nextState, { module, hasPagination }, state);
}

export function LIST_REMOVE_ITEM(nextState, { module, items = [] }, state) {
  if (items.length === 0) {
    return;
  }
  const pages = state[module].items;
  Object.keys(pages).forEach((page) => {
    items.forEach((guid) => {
      const index = pages[page].indexOf(guid);
      nextState[module].items[page].splice(index, 1);
    });
  });
}

export function LIST_ADD_ITEMS(nextState, { module, items }, state) {
  nextState[module].items[state[module].token].push(...items);
}

export function LIST_SET_ITEMS_META(
  nextState,
  { module, nextToken, count },
  state
) {
  if (!state[module]) {
    return;
  }
  nextState[module].nextToken = nextToken;
  nextState[module].count = count;
}

export function LIST_NEXT_PAGE(nextState, { module }, state) {
  nextState[module].token = state[module].nextToken;
}

export function LIST_GO_TO_PAGE(
  nextState,
  { module, currentPageNumber },
  state
) {
  nextState[module].isLoading = true;
  nextState[module].currentPageNumber = currentPageNumber;
  nextState[module].token = state[module].nextToken;
}

export function LIST_INITIALIZE(nextState, { module, query, defaultQuery }) {
  nextState[module] = {
    query,
    pages: new Set(),
    items: {
      initial: [],
    },
    token: "initial",
    nextToken: null,
    count: 0,
    currentPageNumber: 1,
    isLoading: true,
    isInitializing: true,
    defaultQuery,
  };
}

export function LIST_REFRESH_ITEMS_NORMALIZATION(
  nextState,
  { module, result, token, currentPageNumber, hasPagination },
  state
) {
  if (!state[module]) {
    return;
  }
  const itemKey = hasPagination ? currentPageNumber : token;
  nextState[module].items[itemKey] = result;
}
