import React, { useMemo } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { Trans, useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import * as COLORS from "utils/constants/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle,
  faCircleMinus,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { BEGINNING_OF_TIME } from "utils/constants";
import moment from "moment";
import Loader from "components/ui/Loader";

const HEALTH_COLORS = {
  healthy: COLORS.green,
  unhealthy: COLORS.orange,
  unknown: COLORS.silver,
  maintenance: COLORS.mustard,
};

const HEALTH_ICONS = {
  healthy: faCheckCircle,
  unhealthy: faExclamationCircle,
  unknown: faCircleMinus,
  maintenance: faScrewdriverWrench,
};

const Wrap = styled.div`
  line-height: 16px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: ${(props) => props.color};

  > span {
    display: flex;
    align-items: center;
  }
`;

const HealthIcon = styled(FontAwesomeIcon)`
  margin-left: 7px;
  margin-bottom: 1px;
  font-size: 15px;
`;

const HealthStatusMessage = ({
  state,
  message = "",
  compact = false,
  health,
  maintenanceStatus = {},
}) => {
  const { t } = useTranslation();
  const lastHeartbeatMessage = useMemo(
    () =>
      health?.lastHeartBeatTimestamp &&
      health?.lastHeartBeatTimestamp !== BEGINNING_OF_TIME ? (
        <div>
          <strong>Last heartbeat:</strong>{" "}
          {moment(health.lastHeartBeatTimestamp).format("DD MMM, YYYY  HH:mmA")}
        </div>
      ) : (
        ""
      ),
    [health]
  );

  if (!state) {
    return (
      <Tooltip
        title={
          <div>
            <div>
              <strong>{t("Health state")}:</strong> {t("Unknown")}
            </div>
            {lastHeartbeatMessage}
          </div>
        }
        placement={"right"}
      >
        <HealthIcon
          icon={HEALTH_ICONS.unknown}
          style={{ color: COLORS.silver }}
        />
      </Tooltip>
    );
  }

  let content = (
    <span>
      {!compact && state}
      <HealthIcon
        icon={HEALTH_ICONS[state]}
        style={{ color: HEALTH_COLORS[state] }}
      />
    </span>
  );

  if (!isEmpty(message) || lastHeartbeatMessage) {
    content = (
      <Tooltip
        title={
          <div>
            {message}
            {lastHeartbeatMessage}
          </div>
        }
        placement={"right"}
      >
        {content}
      </Tooltip>
    );
  }

  if (maintenanceStatus?.state) {
    return maintenanceStatus?.state === "InProgress" ? (
      <Loader small />
    ) : (
      <Tooltip
        title={<Trans>{maintenanceStatus?.message}</Trans>}
        placement={"top"}
      >
        <span>
          <HealthIcon
            icon={HEALTH_ICONS.maintenance}
            style={{ color: HEALTH_COLORS.maintenance }}
          />
        </span>
      </Tooltip>
    );
  }

  return <Wrap color={HEALTH_COLORS[state]}>{content}</Wrap>;
};

export default HealthStatusMessage;
