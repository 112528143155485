import store from "services/store";
import history from ".";
import {
  getCurrentContext,
  getCurrentProjectUidFromUrl,
} from "state/auth/selectors";
import { generatePath } from "react-router";
import {
  ADMIN_PREFIX,
  ADMIN_PREFIX_WITH_PROJECT,
  DEV_PREFIX_WITH_PROJECT,
  PROJECT_PREFIX,
} from "components/common/History/Route";

export function appendContext(url = "", projectContextUid) {
  const shouldNotPrefix = [
    "/auth",
    "/my-profile",
    "/my-api-keys",
    "/projects/",
    "/admin/",
    "/dev/",
  ].some((path) => url.startsWith(path));

  if (shouldNotPrefix) {
    return url;
  }

  const currentContext = getCurrentContext(store.getState());

  if (!currentContext) {
    return url;
  }

  if (
    store.getState().auth.devMode &&
    (currentContext.projectUid || projectContextUid)
  ) {
    if (projectContextUid) {
      return `${generatePath(DEV_PREFIX_WITH_PROJECT, {
        projectUid: projectContextUid,
      })}${url}`;
    }

    return `${generatePath(DEV_PREFIX_WITH_PROJECT, {
      projectUid: currentContext.projectUid,
    })}${url}`;
  }

  if (currentContext?.isAdmin) {
    if (projectContextUid) {
      return `${generatePath(ADMIN_PREFIX_WITH_PROJECT, {
        projectUid: projectContextUid,
      })}${url}`;
    }

    return `${ADMIN_PREFIX}${url}`;
  }

  return `${generatePath(PROJECT_PREFIX, {
    projectUid: currentContext.projectUid,
  })}${url}`;
}

const historyPush = history.push;
export function hookHistoryWithContext() {
  history.push = function (...args) {
    const argAsObject = typeof args[0] !== "string";
    let nextLocation = args[0] || "";
    if (argAsObject) {
      nextLocation = args[0]?.pathname || "";
    }

    nextLocation = appendContext(nextLocation);
    historyPush(
      argAsObject ? { ...args[0], pathname: nextLocation } : nextLocation
    );
  };
}
