import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import { api, normalize } from "./middlewares";
import bugReporter, { bootstrapPlayback } from "./bugReporter";
import rootReducer from "state/reducers";
import history from "services/history";
import { denormalize } from "normalizr";

export function configureStore(initialState = {}) {
  const composedEnhancers = composeWithDevTools(
    bugReporter,
    applyMiddleware(routerMiddleware(history), thunkMiddleware, api, normalize)
  );
  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancers
  );
  if (import.meta.env.MODE !== "production" && import.meta.hot) {
    import.meta.hot.accept("state/reducers", () =>
      store.replaceReducer(rootReducer(history))
    );
  }
  return store;
}

const store = configureStore();
const externalReducers = {};

store.registerReducer = (name, reducer) => {
  if (externalReducers[name]) {
    return;
  }
  externalReducers[name] = reducer;
  store.replaceReducer(rootReducer(history, externalReducers));
};

export function getStoreEntity(item, Schema) {
  return denormalize(item, Schema, store.getState().entities);
}

bootstrapPlayback(store);

export default store;
