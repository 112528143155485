import React, { useMemo } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import { getCurrentContext } from "state/auth/selectors";
import {
  concrete,
  midnightBlue,
  greenGray,
  white,
  silver,
} from "utils/constants/colors";
import Link from "components/common/History/Link";
import Title from "components/common/Title";

import { PageHeaderSlot, PageToolbarSlot, BreadcrumbSlot } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";

const HeaderWrap = styled.div`
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 500;
    font-size: 24px;
    margin: 0;
    line-height: 32px;
  }
`;

const BreadcrumbWrap = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  a {
    color: #3e8edf;
  }
`;

const BreadcrumbDivider = styled.span`
  color: ${silver};
  margin: 0 8px;
`;

const BreadcrumbLabel = styled.span`
  color: ${greenGray};

  ${(props) =>
    props.isLast &&
    css`
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0015em;
    `}
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
`;

const ContentWrap = styled.div`
  min-height: 64px;
  display: flex;
  align-items: center;

  flex: 1;
  &:empty {
    min-height: 0;
  }

  ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}

  > div {
    margin-right: 32px;
  }
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: ${midnightBlue};
  }
`;

export const BackArrow = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-right: 20px;
  color: ${concrete};
  font-size: 20px;
`;

function renderBreadcrumb(breadcrumb, index, breadcrumbs) {
  const isLastElement = index === breadcrumbs.length - 1;
  if (!breadcrumb?.label) {
    return;
  }

  let labelElement = (
    <BreadcrumbLabel isLast={isLastElement}>{breadcrumb.label}</BreadcrumbLabel>
  );
  if (breadcrumb.href) {
    labelElement = <Link to={breadcrumb.href}>{breadcrumb.label}</Link>;
  }
  return (
    <BreadcrumbWrap key={index}>
      {!breadcrumb.isAdminBase && <BreadcrumbDivider>{"/"}</BreadcrumbDivider>}{" "}
      {labelElement}
    </BreadcrumbWrap>
  );
}

function PageHeader({
  title,
  breadcrumbs,
  context,
  tabTitleContent,
  withBack = false,
  extraContent,
  extraHeaderContent,
}) {
  const { t } = useTranslation();
  let content = null;
  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length;

  let tabTitle = tabTitleContent?.pageTitle ? tabTitleContent.pageTitle : title;

  if (tabTitleContent?.activeTab) {
    tabTitle = `${tabTitleContent.activeTab} | ${tabTitleContent.pageTitle}`;
  }

  const backLink = useMemo(() => {
    if (!breadcrumbs) {
      return null;
    }

    return [...breadcrumbs].reverse().find((breadcrumb) => breadcrumb.href);
  }, [breadcrumbs]);

  function renderBackArrow() {
    if (!backLink || !withBack) {
      return null;
    }

    return (
      <Link to={backLink.href}>
        <BackArrow icon={faArrowLeft} />
      </Link>
    );
  }

  if (hasBreadcrumbs) {
    content = (
      <BreadcrumbSlot.Source>
        <Breadcrumbs>
          {[
            {
              ...(context?.isAdmin && {
                label: t("Administration"),
                href: "/",
                isAdminBase: true,
              }),
            },
            ...breadcrumbs,
          ].map(renderBreadcrumb)}
        </Breadcrumbs>
      </BreadcrumbSlot.Source>
    );
  }

  return (
    <PageHeaderSlot.Source>
      {tabTitle && <Title>{tabTitle}</Title>}
      <HeaderWrap>
        <ContentWrap center={!hasBreadcrumbs}>
          {renderBackArrow()}
          {title && <h1>{title}</h1>}
          {content}
          {extraHeaderContent}
        </ContentWrap>
        <PageToolbarSlot.Target />
        {extraContent}
      </HeaderWrap>
    </PageHeaderSlot.Source>
  );
}

export default connect((state) => ({
  context: getCurrentContext(state),
}))(PageHeader);
