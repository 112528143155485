import { createSelector } from "reselect";

import {
  presentLayer,
  formatTags,
  presentLayersForAPI,
} from "utils/presenters";
import {
  systemProfileBuilderCreateModule,
  APP_PROFILE_FORM_MODULE,
  systemProfileBuilderEditModule,
} from "../services/create";

export const getFormattedPayload = createSelector(
  (state) => state.forms[APP_PROFILE_FORM_MODULE]?.data || {},
  () => systemProfileBuilderCreateModule.payload,
  () => systemProfileBuilderEditModule.payload,
  (formData, createLayers, editLayers) => {
    return {
      metadata: {
        name: formData.name,
        annotations: {
          description: formData.description,
        },
        labels: formatTags(formData.tags),
      },
      spec: {
        template: {
          cloudType: "all",
          type: "system",
          packs: formData.persisted
            ? (editLayers || []).map(presentLayersForAPI)
            : (createLayers || []).map(presentLayersForAPI),
        },
        version: formData.version,
      },
    };
  }
);

export const getProfileLayersPreview = createSelector(
  () => systemProfileBuilderCreateModule.state,
  (profileBuilder) => {
    return profileBuilder?.layers?.map((layer) => {
      return presentLayer({ ...layer, ...layer.config });
    });
  }
);
