import React from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import FIPSEnabled from "assets/icons/fips-enabled.svg?component";
import FIPSDisabled from "assets/icons/fips-disabled.svg?component";
import FIPSUnknown from "assets/icons/fips-unknown.svg?component";
import FIPSPartial from "assets/icons/fips-partial.svg?component";
import styled from "styled-components";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  span {
    margin-left: 7px;
  }
`;

export const FipsIcon = ({
  fipsStatus,
  showDescription,
  isImported,
  isClusterVariant = false,
}) => {
  const { t } = useTranslation();

  const description = isClusterVariant
    ? {
        full: () => t("All packs in the cluster are FIPS compliant."),
        unknown: (isImported) => {
          const messageStrings = [
            t("Unknown state of FIPS compliance"),
            isImported && t("this cluster was not deployed by Palette"),
          ]
            .filter(Boolean)
            .join(", ");

          return messageStrings;
        },
        partial: () =>
          t(
            "Some packs are FIPS compliant, but there is at least one that isn't."
          ),
        none: () => t("None of the packs in the cluster are FIPS compliant"),
      }
    : {
        full: () => t("FIPS compliant"),
        unknown: () => t("Unknown state of FIPS compliance"),
        partial: () => t("Partially FIPS compliant"),
        none: () => t("Non-FIPS compliant"),
      };

  if (fipsStatus === "true" || fipsStatus === "full") {
    return (
      <IconWrapper>
        <Tooltip title={description.full()}>
          <FIPSEnabled />
        </Tooltip>
        {showDescription && <span>{description.full()}</span>}
      </IconWrapper>
    );
  }

  if (fipsStatus === "unknown") {
    return (
      <IconWrapper>
        <Tooltip title={description.unknown()}>
          <FIPSUnknown />
        </Tooltip>
        {showDescription && <span>{description.unknown(isImported)}</span>}
      </IconWrapper>
    );
  }

  if (fipsStatus === "partial") {
    return (
      <IconWrapper>
        <Tooltip title={description.partial()}>
          <FIPSPartial />
        </Tooltip>
        {showDescription && <span>{description.partial()}</span>}
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <Tooltip title={description.none()}>
        <FIPSDisabled />
      </Tooltip>
      {showDescription && <span>{description.none()}</span>}
    </IconWrapper>
  );
};
