import React from "react";
import styled, { css } from "styled-components";
import Button, { TextButton } from "components/ui/Button";

import productsTourImages from "assets/images/productTour";
import TourModal from "./Modal";
import { Trans } from "react-i18next";
import Icon from "components/ui/Icon";
import Link from "components/common/History/Link";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { productTourModal } from "state/productTour/services";
import history from "services/history";
import { DEPLOYMENTS } from "utils/constants/routes";
import { media } from "utils/responsive";
import Permissions from "components/ui/Permissions";
import { generatePath } from "react-router";
import MobileAlert from "components/common/MobileAlert";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { darkGray } from "utils/constants/colors";

const DevContentWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // tablet
  @media (max-width: 768px) {
    height: initial;
    margin-bottom: 110px;

    #button-container {
      display: none;
    }
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  padding: 0 24px;
  border-bottom: 1px solid #dadee3;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 24px;
  overflow-y: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const WelcomeToDev = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 660px;
  color: #ffffff;
  padding: 32px;

  background: #7671c7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  // tablet
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 32px;
  }

  h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: 50px;
    line-height: 117.5%;
    margin-bottom: 5px;
  }
  h2 {
    color: #ffffff;
    font-weight: 400;
    font-size: 32px;
    line-height: 117.5%;
    margin-bottom: 0;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

const WelcomeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  // tablet
  @media (max-width: 768px) {
    flex-direction: column;
  }

  > div {
    width: 345px;
  }
`;

const Astronaut = styled.img`
  margin-bottom: 30px;
  height: 150px;
  width: 140px;
`;
const Rocket = styled.img`
  height: 22px;
  margin-left: 10px;
`;

const DevButton = styled(Button)`
  width: 252px;
  height: 54px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 800px;
`;

const Option = styled.div`
  display: flex;
  background: #f7f8f9;
  border: 1px solid #dadee3;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 6%);
  border-radius: 8px;
  padding: 20px;

  // tablet
  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;

    :last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.0025em;
    color: #2b323c;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    line-height: 24px;
    color: #3e4856;
    margin-bottom: 0;
  }

  span {
    color: #818ea1;
  }
`;

const OptionIcon = styled.img`
  height: 80px;
  margin-right: 15px;
`;
const DevLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 384px;
  background: #f7f8f9;
  border: 1px solid #dadee3;
  border-radius: 4px;
  padding: 16px 24px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;

    :last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 6px;
    color: #64748b;
  }

  h3 {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.0025em;
    color: #2b323c;
  }
  svg {
    font-size: 18px;
    color: #64748b;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 634px;

  ${(props) =>
    props.basis &&
    css`
      flex-basis: ${props.basis};
    `}

  // tablet
    @media (max-width: 768px) {
    flex-direction: column;
    max-height: none;
    align-items: center;
  }
`;

const Footer = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3e4856;
  margin-top: 30px;
`;

const LineBreak = styled.div`
  width: 100%;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #aaa;
    opacity: 0.3;
    margin: 24px 0;
  }
`;

const CloseButton = styled(TextButton)`
  color: ${darkGray};
  svg {
    font-size: 14px;
  }
`;

const DEV_TOUR_CONTENT = {
  options: [
    {
      key: "option1",
      title: <h3>Free Compute</h3>,
      content: (
        <p>
          We are offering <strong>12 CPU, 16 GiB</strong> of memory, and{" "}
          <strong>20 GiB</strong> of storage for free!
        </p>
      ),
      icon: productsTourImages.dev_option1,
    },
    {
      key: "option2",
      title: <h3>Multi-tier App Modeling</h3>,
      content: (
        <p>
          Model curated, pre-validated services, such as databases (MongoDB,
          Postgres, Redis) along with Helm Charts and Manifests as reusable App
          Profiles.
          <br />
          <span>Coming Soon: Kafka, NATS, S3, MySQL, etc.</span>
        </p>
      ),
      icon: productsTourImages.dev_option2,
    },
    {
      key: "option3",
      title: <h3>Virtual Clusters</h3>,
      content: (
        <p>
          Instant access to cluster resources in a
          “Virtual-Cluster-as-a-Service” deployment environment,
          <strong> enabling apps to be deployed and tested </strong>
          quickly, without the delay of waiting or the waste of a full
          Kubernetes cluster.
        </p>
      ),
      icon: productsTourImages.dev_option3,
    },
  ],
  links: [
    {
      key: "link1",
      title: <h3>How to deploy an app?</h3>,
      link: "https://docs.spectrocloud.com/devx/apps/create-app",
    },
    {
      key: "link2",
      title: <h3>How to create an App Profile?</h3>,
      link: "https://docs.spectrocloud.com/devx/app-profile/create-app-profile",
    },
    {
      key: "link3",
      title: <h3>How to deploy a virtual cluster?</h3>,
      link: "https://docs.spectrocloud.com/devx/palette-virtual-clusters",
    },
  ],
};

export default function DevTourModal() {
  function renderOption({ key, title, content, icon }) {
    return (
      <Option key={key}>
        <OptionIcon src={icon} alt={title} />
        <div>
          {title}
          {content}
        </div>
      </Option>
    );
  }

  function renderLinks({ key, title, link }) {
    return (
      <DevLink key={key} href={link} rel="noopener noreferrer" target="_blank">
        <div>
          <p>LEARN</p>
          {title}
        </div>
        <Icon awesome={faArrowUpRightFromSquare} />
      </DevLink>
    );
  }
  return (
    <TourModal
      footer={null}
      service={productTourModal}
      closeIcon={<div />}
      width="1600px"
    >
      <DevContentWrap>
        <Trans>
          <Header>
            <span>Get up and running in minutes</span>
            <CloseButton
              onClick={() => {
                productTourModal.close();
              }}
            >
              <Icon awesome={faTimes} />
            </CloseButton>
          </Header>
          <Content>
            <Wrapper basis="60%">
              <WelcomeToDev>
                <WelcomeHeader>
                  <Astronaut
                    src={productsTourImages.astronaut}
                    alt="astronaut"
                  />
                  <div>
                    <h2>Welcome to </h2>
                    <h1>Palette App Mode</h1>
                  </div>
                </WelcomeHeader>
                <p>
                  Palette App Mode includes a powerful set of features that
                  enable application developers to{" "}
                  <strong>
                    model and deploy applications without having to deal with
                    any of the complexities of Kubernetes.
                  </strong>
                </p>
                <p>
                  Designed to give developers the control they need, being able
                  to <strong>manage the complete app lifecycle</strong>; from
                  monitoring the health of applications or inspecting for
                  errors, backing up their workloads, adding new parameters,
                  etc.
                </p>
                <ButtonsWrap>
                  <Permissions has={"appDeployment.create"}>
                    <DevButton
                      data-qa="deploy-app"
                      color="purple"
                      onClick={() => {
                        history.push(
                          generatePath(DEPLOYMENTS.CREATE, {
                            appProfileId: null,
                          })
                        );
                        productTourModal.close();
                      }}
                    >
                      Deploy Your First App
                      <Rocket src={productsTourImages.rocket} alt="rocket" />
                    </DevButton>
                  </Permissions>
                  <DevButton
                    secondary
                    data-qa="explore-palette"
                    onClick={() => {
                      productTourModal.close();
                    }}
                  >
                    Explore Palette App Mode
                  </DevButton>
                </ButtonsWrap>
              </WelcomeToDev>

              <Options>{DEV_TOUR_CONTENT.options.map(renderOption)}</Options>
            </Wrapper>

            <LineBreak />

            <Wrapper>{DEV_TOUR_CONTENT.links.map(renderLinks)}</Wrapper>

            <Footer>
              Share your experience and post questions by joining our{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://join.slack.com/t/spectrocloudcommunity/shared_invite/zt-g8gfzrhf-cKavsGD_myOh30K24pImLA"
              >
                Slack community
              </a>
            </Footer>
          </Content>
        </Trans>

        <MobileAlert />
      </DevContentWrap>
    </TourModal>
  );
}
