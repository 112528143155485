import React from "react";
import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";

const Wrap = styled.div`
  height: ${(props) => props.height}px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 350px;
`;

const CanvasWrapper = styled.div`
  height: ${(props) => props.maxHeight}px;
  width: 100%;
`;

export default function LayersScene({ children, height, maxHeight }) {
  const cameraZ = (maxHeight / 600) * 170;

  return (
    <Wrap height={height}>
      <CanvasWrapper maxHeight={maxHeight}>
        <Canvas
          mode="concurrent"
          linear
          shadows
          flat={true}
          frameloop="demand"
          camera={{ position: [0, 0, cameraZ], fov: 10 }}
          onCreated={({ gl, scene }) => {
            if (!gl) {
              return;
            }

            gl.shadowMap.type = THREE.PCFSoftShadowMap;

            scene.rotation.set(0.2, 0, 0);
          }}
        >
          {children}
          <spotLight
            castShadow
            angle={0.3}
            intensity={0.2}
            position={[0, 300, 190]}
            shadow-radius={130}
            shadow-mapSize-height={2048}
            shadow-mapSize-width={2048}
          />
          <ambientLight intensity={0.94} />
        </Canvas>
      </CanvasWrapper>
    </Wrap>
  );
}
