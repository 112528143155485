import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Carousel } from "antd";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Color from "color";

import Button, { TextButton } from "components/ui/Button";
import {
  blueberry,
  lavender,
  lightLavender,
  platinum,
  silver,
  white,
} from "utils/constants/colors";

import {
  setTourNextStep,
  setTourPreviousStep,
} from "state/productTour/actions";
import Checkmark from "assets/icons/palette_colored_checkmark.svg?component";
import InactiveCheckmark from "assets/icons/inactive_checkmark.svg?component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/pro-regular-svg-icons";
import { media } from "utils/responsive";
import { useTheme } from "components/common/Theme";

const CONTENT_WIDTH = "35vw";
const AUTOPLAY_SPEED = 4;

const StageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  background: ${white};

  .ant-carousel {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
  }

  .slick-slider,
  .slick-slide,
  .slick-track {
    width: 100% !important;
  }
`;

const CarouselImageWrapper = styled.div`
  margin: 0 auto;
  width: ${CONTENT_WIDTH};
  height: ${CONTENT_WIDTH};
  max-height: 550px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MainImage = styled.img`
  position: absolute;
  width: ${CONTENT_WIDTH};
  max-width: 550px;
  z-index: 100;
`;

const BlurredImage = styled.img`
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const CarouselTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;

  > svg {
    width: 140px;
    margin-bottom: 10px;
  }

  span {
    color: #2f2c5f;
    font-size: 18px;

    :first-child {
      font-weight: 200;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 48px 24px;

  > a {
    align-self: flex-start;
  }

  ${media.laptop} {
    padding: 64px 64px 32px;
  }
`;

const Title = styled.div`
  width: 100%;

  > span {
    font-size: 32px;
    line-height: 48px;
    color: ${white};
  }

  > div {
    color: #8e60b2;
    font-size: 18px;
  }
`;

const TabsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Tab = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 24px;
  position: relative;
  cursor: pointer;
  color: #dbd6ea;
  padding: 18px;
  background: rgba(247, 248, 249, 0.05);
  border-radius: 2px;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.01em;

  :last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: rgba(240, 249, 255, 0.1);
      color: ${white};
    `}

  ${media.laptop} {
    padding: 24px;
  }
`;

const CheckWrapper = styled.div`
  margin-right: 12px;
  width: 30px;
  height: 30px;
`;

const ProgressBar = styled.div`
  width: 0px;
  height: 2px;
  background: #8e60b2;

  position: absolute;
  left: 0;
  bottom: 0;

  ${(props) =>
    props.isActive &&
    css`
      width: 100%;
      transition: ${props.shouldTransition ? AUTOPLAY_SPEED : 0.5}s ease-in-out;
    `}
`;

const NextStepButton = styled(Button)`
  background: ${lavender};
  margin-left: 22px;

  :hover {
    background: ${Color(lavender).lighten(0.2)};
  }

  svg {
    margin-left: 8px;
  }
`;

const Footer = styled.div`
  width: 100%;
  border-top: 1px solid ${platinum};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  & ${NextStepButton} {
    background-color: ${(props) => props.customColor};
  }

  & ${TextButton} {
    color: ${(props) => props.customColor};
  }
`;

const DocksButton = styled(Button)`
  background: transparent;
  border: 1px solid ${blueberry};
  color: ${silver};

  :hover,
  :focus,
  :active {
    background: transparent;
    color: ${white};
    border: 1px solid ${lightLavender};
  }
`;

const StageProgressWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
`;

const BarContainer = styled.div`
  width: 32px;
  height: 2px;
  margin-right: 4px;
  background: ${Color(lavender).lighten(1)};

  ${(props) =>
    props.isActive &&
    css`
      background: transparent;
    `}
`;

const StageProgressBar = styled.div`
  width: 0;
  height: 2px;
  background: #8e60b2;

  ${(props) =>
    props.isActive &&
    css`
      width: 100%;
    `}
`;

function StageProgress({ totalSteps, currentStep }) {
  return (
    <StageProgressWrapper>
      {Array.from({ length: totalSteps - 1 }).map((step, index) => (
        <BarContainer isActive={index + 2 === currentStep} key={step}>
          <StageProgressBar isActive={index + 2 === currentStep} />
        </BarContainer>
      ))}
    </StageProgressWrapper>
  );
}

function StageSlider({
  options,
  stageName,
  description,
  docsHref,
  setTourNextStep,
  stepNumber,
  totalSteps,
  setTourPreviousStep,
}) {
  const { t } = useTranslation();
  const { ColoredLogo, textColor } = useTheme();
  const [activeSlide, setActiveSlide] = useState(undefined);
  const [shouldAutoplay, setShouldAutoplay] = useState(true);
  const sliderRef = useRef(null);

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const beforeSlideChange = () => {
    shouldAutoplay &&
      setActiveSlide((prevSlide) =>
        options.length - 1 === prevSlide ? 0 : prevSlide + 1
      );
  };

  const onTabSelect = (index) => () => {
    setShouldAutoplay(false);
    sliderRef.current.goTo(index);
    setActiveSlide(index);
  };

  return (
    <StageWrapper>
      <CarouselWrapper>
        <CarouselTitle>
          <ColoredLogo />
          <div>
            <span>{t("Time to unleash the")}</span>{" "}
            <span>{t("true potential of K8s")}</span>
          </div>
        </CarouselTitle>
        <Carousel
          autoplay={shouldAutoplay}
          autoplaySpeed={AUTOPLAY_SPEED * 1000}
          beforeChange={beforeSlideChange}
          dotPosition="right"
          dots={false}
          ref={(ref) => (sliderRef.current = ref)}
        >
          {options.map((option) => (
            <CarouselImageWrapper key={option.key}>
              <MainImage src={option.image} alt={`${option.key}`} />
              {option.blurredImage && (
                <BlurredImage
                  src={option.blurredImage}
                  alt={`blurred ${option.key}`}
                />
              )}
            </CarouselImageWrapper>
          ))}
        </Carousel>
        <Footer customColor={textColor}>
          <TextButton
            secondary
            key="setTourPreviousStep"
            data-qa="setTourPreviousStep"
            onClick={setTourPreviousStep}
          >
            {t("Previous")}
          </TextButton>
          <NextStepButton
            key="setTourNextStep "
            data-qa="setTourNextStep"
            onClick={setTourNextStep}
          >
            {stepNumber < totalSteps ? (
              <>
                {t("Next")}
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </>
            ) : (
              t("Finish")
            )}
          </NextStepButton>
        </Footer>
      </CarouselWrapper>
      <ContentWrapper>
        <StageProgress totalSteps={totalSteps} currentStep={stepNumber} />
        <Title>
          <span>{stageName}</span>
          <div>{description}</div>
        </Title>
        <TabsWrapper>
          {options.map((option, index) => {
            const isActive = index === activeSlide;
            return (
              <Tab
                key={option.key}
                isActive={isActive}
                onClick={onTabSelect(index)}
              >
                <CheckWrapper>
                  {isActive ? <Checkmark /> : <InactiveCheckmark />}
                </CheckWrapper>
                <div>{option.tab}</div>
                <ProgressBar
                  isActive={isActive}
                  shouldTransition={shouldAutoplay}
                />
              </Tab>
            );
          })}
        </TabsWrapper>
        <a target="_blank" rel="noopener noreferrer" href={docsHref}>
          <DocksButton secondary data-qa="goToDocs">
            {t("Learn more about {{ stageName }}", { stageName })}
          </DocksButton>
        </a>
      </ContentWrapper>
    </StageWrapper>
  );
}

export default connect(
  (state) => ({
    stepNumber: state.productTour.stepNumber,
    totalSteps: state.productTour.totalSteps,
  }),
  { setTourNextStep, setTourPreviousStep }
)(StageSlider);
