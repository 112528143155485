import React, { useState } from "react";
import HelmChartIcon from "assets/icons/helm_chart_layer.svg?component";
import { LAYER_TYPES } from "utils/constants";
import styled from "styled-components";

export function PackLogoIcon({
  logoUrl,
  displayName,
  layer: type,
  addonType = type,
}) {
  const [logoSrc, setLogoSrc] = useState(logoUrl);

  const onImageError = () => {
    const layerIcon = LAYER_TYPES.find(
      (layer) => layer.type === addonType
    )?.icon;
    setLogoSrc(layerIcon);
  };

  if (logoUrl) {
    return (
      <img src={logoSrc} alt={`${displayName} logo`} onError={onImageError} />
    );
  }
  if (addonType === "helmChart") {
    return <HelmChartIcon />;
  }

  return null;
}

export const PackLogo = styled.div`
  width: 30px;
  height: 24px;
  margin-right: 8px;
  opacity: ${(props) => props.disabled && "0.5"};
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    color: transparent;
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }
`;

export const PackContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const PackLabel = styled.div`
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;
