export const initialState = {
  isLoading: true,
  currentClusterProfileId: null,
  scopePackValues: [],
  arePackValuesLoading: false,
  notification: {
    divergences: {},
    selectedLayer: null,
  },
  selectedVersion: null,
};

export function REFRESH_CLUSTER_PROFILE_DETAILS_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function REFRESH_CLUSTER_PROFILE_DETAILS_SUCCESS(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTER_PROFILE_DETAILS_REQUEST(nextState) {
  nextState.isLoading = true;
  nextState.error = null;
}

export function FETCH_CLUSTER_PROFILE_DETAILS_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.isLoading = false;
  nextState.currentClusterProfileId = result;
}

export function FETCH_CLUSTER_PROFILE_DETAILS_SUCCESS(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTER_PROFILE_DETAILS_FAILURE(nextState, { error }) {
  nextState.isLoading = false;
  nextState.error = error;
}

export function FETCH_PACK_DIVERGENCE_INFO_SUCCESS(
  nextState,
  { packName, result }
) {
  nextState.notification.divergences[packName] = result;
}

export function NOTIFICATION_SELECT_LAYER(nextState, { layer }) {
  nextState.notification.selectedLayer = layer.guid;
}

export function UPDATE_SELECTED_VERSION(nextState, { version }) {
  nextState.selectedVersion = version;
}
