import React, { useContext, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ConfigProvider, Pagination as AntPagination } from "antd";
import Loader from "components/ui/Loader";
import Search from "components/ui/Search";
import { contentRef } from "components/common/layouts/App";
import EmptyPageLayout from "components/common/EmptyPage";
import StyledTable from "components/ui/Table";
import { ListContext } from "modules/list/index";
import { paginationStyle } from "components/styled/mixins";
import EmptyResults from "components/ui/EmptyResults";

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingDummy = styled.div`
  height: 120px;
`;

const StyledPagination = styled.span`
  position: sticky;
  bottom: ${(props) => (props.bottom ? props.bottom : "-20px")};
  z-index: 99;
  ${paginationStyle}
`;

const OverlayWrap = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
`;

export function List({
  items,
  Item,
  isLoading,
  smallLoader,
  isInitializing,
  hasOverlayLoader,
}) {
  function renderItem(item, index) {
    return <Item data={item} key={index} />;
  }

  function renderLoader() {
    if (!isLoading) {
      return null;
    }

    if (hasOverlayLoader && items.length > 0) {
      return (
        <OverlayWrap>
          <Loader small />
        </OverlayWrap>
      );
    }

    return (
      <LoaderWrap>
        <Loader small={items.length || smallLoader} />
      </LoaderWrap>
    );
  }

  if (!Item) {
    return null;
  }

  if (isInitializing) {
    return <Loader small={smallLoader} />;
  }

  return (
    <>
      {items.map(renderItem)}
      {renderLoader()}
    </>
  );
}

export function LoadAsYouScroll({ children, watchElement = contentRef }) {
  const context = useContext(ListContext);
  const nextPage = context.nextPage;
  const onScroll = useCallback(() => {
    const element = watchElement.current;
    if (
      element.scrollHeight - parseInt(element.scrollTop) ===
      element.clientHeight
    ) {
      nextPage();
    }
  }, [nextPage, watchElement]);
  const throttledScroll = useMemo(() => {
    return _.throttle(onScroll, 200);
  }, [onScroll]);
  useEffect(() => {
    const element = watchElement.current;
    element.addEventListener("scroll", throttledScroll);
    return () => {
      element.removeEventListener("scroll", throttledScroll);
    };
  }, [throttledScroll, watchElement]);

  return children || null;
}

export function SearchBar({ changeQuery, filters }) {
  return (
    <Search
      value={filters.search}
      onChange={(ev) => changeQuery("search", ev.target.value)}
    />
  );
}

export function Table({
  items = [],
  hasLoader = true,
  isLoading,
  columns,
  slim,
  rowKey = (record) => record.guid,
  onRowClick = () => {},
  hasEmptyPage = false,
  noDataComponent,
  onColumnSort,
  uiPagination = false,
  uiPaginationProps,
  ...rest
}) {
  const { t } = useTranslation();
  const paginationProps = useMemo(() => {
    if (!uiPagination) {
      return false;
    }

    return {
      defaultPageSize: 20,
      showSizeChanger: false,
      position: ["none", "bottomLeft"],
      showTotal: (total) => t("Total {{total}} items", { total }),
      ...uiPaginationProps,
    };
  }, [uiPaginationProps, uiPagination, t]);

  if (items.length === 0 && hasEmptyPage) {
    return null;
  }

  return (
    <ConfigProvider renderEmpty={noDataComponent && (() => noDataComponent)}>
      <StyledTable
        dataSource={items}
        locale={{
          emptyText: !isLoading ? <EmptyResults /> : <LoadingDummy />,
        }}
        // TODO: Maybe find a better solution for when table is loading data from polling
        loading={hasLoader && isLoading}
        rowKey={rowKey}
        pagination={paginationProps}
        columns={columns}
        slim={slim}
        selectableRow={onRowClick}
        onRow={(record, index) => {
          const props = {
            "data-qa-type": "row",
            "data-qa-index": index,
            onClick: () => onRowClick(record),
          };

          if (record?.metadata?.uid) {
            props["data-qa-uid"] = record.metadata.uid;
          }

          return props;
        }}
        onChange={uiPagination ? () => {} : onColumnSort} // empty function needed because on first pagination change the API is called again. no idea why
        showSorterTooltip={false}
        {...rest}
      />
    </ConfigProvider>
  );
}

export function EmptyPage({
  isLoading,
  items,
  hasActiveFilters,
  withFilters,
  ...rest
}) {
  if (!isLoading && !items.length) {
    if (hasActiveFilters) {
      return <EmptyPageLayout {...withFilters} />;
    }
    return <EmptyPageLayout {...rest} />;
  }

  return null;
}

export function EmptyResult(props) {
  if (!props.isLoading && !props.items.length) {
    if (props.hasActiveFilters) {
      return <EmptyResults {...props.withFilters} />;
    }

    return <EmptyResults {...props} />;
  }
  return null;
}

export function Pagination({
  count,
  query,
  currentPageNumber,
  goToPage,
  bottom,
  isSticky,
  ...rest
}) {
  const { t } = useTranslation();

  if (!count) {
    return null;
  }

  return (
    <StyledPagination bottom={bottom} isSticky={isSticky}>
      <AntPagination
        showSizeChanger={false}
        pageSize={query?.limit}
        total={count}
        onChange={goToPage}
        current={currentPageNumber}
        showTotal={(total) => t(`Total {{total}} items`, { total })}
        {...rest}
      />
    </StyledPagination>
  );
}
