import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import i18next from "i18next";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import Fields from "components/ui/Fields";
import { FieldLabel, FieldWrap } from "components/styled/Field";
import { lightWhite } from "utils/constants/colors";
import { createSelectComponent } from "modules/dataFetcher";
import { ociRepositoriesFetcher } from "modules/profileIDE/state/packs";
import { createConnector } from "modules/binder";
import { createSelector } from "reselect";
import store from "services/store";
import { appProfileMacrosFetcher } from "modules/profileIDE/state/editor";
import { appProfileDetailFetcher } from "state/appprofiles/services/details";

const Wrap = styled.div`
  background: ${lightWhite};
  padding: 8px 16px;
  margin-bottom: 20px;

  > ${FieldWrap} {
    :last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.layout === "horizontal" &&
    css`
      display: flex;
      > ${FieldWrap} {
        margin-right: 8px;

        :last-child {
          margin-right: 0;
        }

        > * {
          width: 100%;
        }
      }
    `}
`;

const StyledLabel = styled(FieldLabel)`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    font-size: 14px;
    vertical-align: middle;
  }
`;

const StyledNumberedInput = styled(Fields.InputNumber)`
  width: 100%;
  max-width: 428px;
`;

function ValueAutocomplete({ macros, ...props }) {
  return (
    <Fields.Autocomplete
      options={macros}
      filterOption={(inputValue, option) =>
        option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      backfill={true}
      defaultActiveFirstOption={true}
      dropdownMatchSelectWidth={false}
      {...props}
    />
  );
}

const ConnectedValueAutocomplete = createConnector({
  selectors: {
    // copied and pasted from editor connector
    macros: createSelector(
      () => appProfileMacrosFetcher.selector(store.getState())?.result,
      (state) => state.drafts,
      (state) => state.fileList.files,
      (state) => state.fileList.childrenMap,
      (state) => state.fileList.activeFile,
      (macros, drafts, files, childrenMap, activeFile) => {
        const filteredMacros = (macros || []).filter(
          (macro) => !macro.includes("#")
        );

        const outputParameterDependentMacros = (macros || []).filter((macro) =>
          macro.includes("#tierOutputParam")
        );
        const inputParameterDependentMacros = (macros || []).filter((macro) =>
          macro.includes("#tierInputParam")
        );

        const operatorTiersDraftsGuids = (Object?.keys(drafts) || []).filter(
          (draftGuid) =>
            ["operator-instance", "container"].includes(
              drafts?.[draftGuid]?.type
            ) && draftGuid !== activeFile
        );

        const operatorTiersMacros = operatorTiersDraftsGuids.flatMap(
          (operatorDraftGuid) => {
            let activeFileIndex = files.indexOf(activeFile);

            // child file parent index
            if (activeFileIndex === -1) {
              const parentGuid = Object.keys(childrenMap).find((parentGuid) =>
                childrenMap[parentGuid].includes(activeFile)
              );

              activeFileIndex = files.indexOf(parentGuid);
            }

            if (files.indexOf(operatorDraftGuid) < activeFileIndex) {
              return [];
            }

            const operatorDraftData = drafts[operatorDraftGuid];
            const outputParametersMacros = (
              operatorDraftData?.parameters?.outputParameters || []
            ).flatMap((outputParameter) => {
              return outputParameterDependentMacros.map((outputMacro) =>
                outputMacro
                  .replace("#tierName", operatorDraftData.name)
                  .replace("#tierOutputParam", outputParameter.name)
              );
            });

            return outputParametersMacros;
          }
        );

        const currentDraftData = drafts[activeFile];
        const currentTierInputMacros = (
          currentDraftData?.parameters?.inputParameters || []
        ).flatMap((inputParameter) => {
          return inputParameterDependentMacros.map((inputMacro) =>
            inputMacro
              .replace("#tierName", currentDraftData.name)
              .replace("#tierInputParam", inputParameter.name)
          );
        });

        return [
          ...filteredMacros,
          ...currentTierInputMacros,
          ...operatorTiersMacros,
        ].map((macro) => ({
          label: macro,
          value: `{{.${macro}}}`,
        }));
      }
    ),
  },
}).connect(ValueAutocomplete);

const RepositorySelector = createSelectComponent({
  dataFetcher: ociRepositoriesFetcher,
  parseOptions: (items) => {
    const repositories = [
      {
        label: i18next.t("Public"),
        value: "",
      },
      ...items,
    ];

    return repositories;
  },
});

function ContainerForm({ isEdit, isPersisted, registryUrl, hasInUseApps }) {
  const { t } = useTranslation();
  const isEditAndPersisted = useMemo(
    () => isEdit && isPersisted,
    [isEdit, isPersisted]
  );

  return (
    <>
      <Fields.Input
        data-qa="container-name"
        name="name"
        label={t("Container Name")}
        disabled={isEditAndPersisted}
      />
      <RepositorySelector name="containerRegistryUid" label={t("Registry")} />
      <Fields.Input
        description={
          !registryUrl &&
          t(
            "Specify an image from a public registry; the registry hostname may be skipped for images from Docker's public registry"
          )
        }
        data-qa="image"
        name="image"
        label={t("Image")}
        addonBefore={registryUrl ? `${registryUrl}/` : null}
      />
      <StyledNumberedInput
        description={t(
          "Replicas allow you to create and handle multiple identical copies of application, ensuring reliability, scalability, and resilience"
        )}
        data-qa="container-replicas"
        name="replicas"
        label={t("Replicas")}
      />
      <Fields.RadioGroup
        data-qa="access"
        name="access"
        label={t("Network Access")}
        options={[
          { label: t("Private"), value: "private" },
          { label: t("Public"), value: "public" },
        ]}
      />
      <Fields.MultipleInputs
        data-qa="ports"
        name="ports"
        label={t("Ports")}
        placeholder="8080"
        showError={false}
      />
      <FieldLabel>{t("Environment Variables")}</FieldLabel>
      <Wrap>
        <Fields.MultipleKeyValuePairs
          data-qa="env-variables"
          name="env"
          addNewButtonTitle={t("Add New Environment Variable")}
          ValueComponent={ConnectedValueAutocomplete}
        />
      </Wrap>
      <StyledLabel>
        {t("Volume")}{" "}
        {hasInUseApps && (
          <Tooltip
            title={t(
              "Editing the volume is not allowed if there are running apps using this app profile"
            )}
            placement="right"
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </StyledLabel>
      <Wrap layout="horizontal">
        <Fields.Input
          data-qa="volume-name"
          name="volumeName"
          label={t("Name")}
          disabled={isEditAndPersisted && hasInUseApps}
        />
        <Fields.InputNumber
          addonAfter="GiB"
          data-qa="volume-size"
          name="volumeSize"
          label={t("Size")}
          disabled={isEditAndPersisted && hasInUseApps}
        />
        <Fields.Input
          data-qa="volume-mount-path"
          name="pathToMount"
          label={t("Mount path")}
          disabled={isEditAndPersisted && hasInUseApps}
        />
      </Wrap>
      <FieldLabel>{t("Runtime settings")}</FieldLabel>
      <Wrap>
        <Fields.Input data-qa="command" name="command" label={t("Command")} />
        <Fields.MultipleInputs
          data-qa="arguments"
          name="args"
          label={t("Arguments")}
        />
      </Wrap>
    </>
  );
}

const ConnectedContainerForm = createConnector({
  selectors: {
    isEdit: (state) => state.editor?.isEdit,
    isPersisted: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.fileList.entities,
      (activeFile, entities) => entities[activeFile]?.persisted
    ),
    registryUrl: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.drafts,
      () => ociRepositoriesFetcher.selector(store.getState())?.result,
      (activeFile, drafts, ociRepos) => {
        const draftData = drafts?.[activeFile] || {};

        return (
          (ociRepos || []).find((repo) => {
            return repo.metadata.uid === draftData.containerRegistryUid;
          })?.spec?.endpoint || ""
        );
      }
    ),
  },
}).connect(ContainerForm);

export default connect((state) => ({
  hasInUseApps:
    (appProfileDetailFetcher.selector(state)?.result?.status?.inUseApps || [])
      .length > 0,
}))(ConnectedContainerForm);
