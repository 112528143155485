import React from "react";
import * as YAML from "yaml";

import { BinderProvider, createModule } from "modules/binder";
import store from "services/store";
import Save from "./exceptions/Save";
import Layout from "./layout";
import ServicesSelectionScreen from "modules/profileIDE/components/Editor/ServicesSelectionScreen";
import OverviewScreen from "modules/profileIDE/components/Editor/OverviewScreen";
import _ from "lodash";

import toolbarReducer from "modules/profileIDE/state/toolbar";
import fileListReducer from "modules/profileIDE/state/fileList";
import editorReducer from "modules/profileIDE/state/editor";
import { bindFormTo } from "modules/profileIDE/state/forms";
import {
  helmPacksFetcher,
  helmPackVersionFetcher,
  helmRepositoriesFetcher,
  ociRepositoriesFetcher,
} from "modules/profileIDE/state/packs";
import draftsReducer from "modules/profileIDE/state/drafts";
import api from "services/api";
import { appProfileDetailFetcher } from "state/appprofiles/services/details";
import {
  isProtectedRegistry,
  protectedHelmEffects,
  getFormUpdatesOnPackVersionChange,
} from "../application-create";
import { validationsReducer } from "modules/profileIDE/state/validations";
import Validator from "services/validator";
import {
  isValidPort,
  isValidReplicas,
  Missing,
} from "services/validator/rules";

function updateFileName({ value, module }) {
  module.dispatch({
    type: module.actions.fileList.updateEntity,
    fileGuid: module.state.fileList.activeFile,
    overwrites: {
      name: value,
    },
  });
}

function helmEffects({ name, value }, module) {
  const registryUid =
    name === "registryUid" ? value : module.form.state.data.registryUid;
  const protectedRegistry = isProtectedRegistry(registryUid);

  if (protectedRegistry) {
    return protectedHelmEffects({ name, value }, module);
  }

  if (name === "registryUid") {
    store.dispatch(helmPacksFetcher.key(value).fetch());
    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          packName: "",
          packVersion: "",
        },
      })
    );
  }

  if (name === "packName") {
    store.dispatch(helmPackVersionFetcher.key(value).fetch(registryUid));
    store.dispatch(
      module.form.actions.batchChange({
        updates: {
          packVersion: "",
          name: value,
        },
      })
    );

    updateFileName({ value, module });
  }

  if (name === "packVersion") {
    const updates = getFormUpdatesOnPackVersionChange(module, value);
    store.dispatch(
      module.form.actions.batchChange({
        updates,
      })
    );
  }
}

function manifestEffects({ name, value }, module) {
  if (name === "name") {
    updateFileName({ value, module });
  }

  if (name === "manifests") {
    const formManifestGuids = value.map((manifest) => manifest.guid);
    const activeFile = module.state.fileList.activeFile;
    const entitiesGuid = module.state.fileList.childrenMap[activeFile] || [];

    const filesToAdd = _.difference(formManifestGuids, entitiesGuid);
    const filesToDelete = _.difference(entitiesGuid, formManifestGuids);
    const entities = value.reduce((acc, file) => {
      acc[file.guid] = file;
      return acc;
    }, {});

    filesToAdd.forEach((fileGuid) => {
      module.dispatch({
        type: module.actions.fileList.addChild,
        parentGuid: activeFile,
        file: entities[fileGuid],
      });
      module.dispatch({
        type: module.actions.drafts.stashForm,
        formData: entities[fileGuid],
      });
    });

    filesToDelete.forEach((fileGuid) => {
      module.dispatch({
        type: module.actions.fileList.removeFile,
        fileGuid,
      });
    });
  }
}

function operatorInstanceEffects({ name, value }, module) {
  if (name === "name") {
    updateFileName({ value, module });
  }
}

export const applicationProfileEditor = createModule({
  name: "applicationProfileEditor",
  submodules: [
    toolbarReducer,
    fileListReducer({}),
    editorReducer(),
    draftsReducer(),
    validationsReducer(),
  ],
  effects: {
    form: {
      async load(entity, draft, module) {
        if (draft.type === "helm") {
          if (!helmRepositoriesFetcher.selector(store.getState()).result) {
            await store.dispatch(helmRepositoriesFetcher.fetch());
          }

          const protectedRegistry = isProtectedRegistry(draft.registryUid);
          if (draft?.persisted && !protectedRegistry) {
            store.dispatch(
              helmPackVersionFetcher
                .key(draft.packName)
                .fetch(draft.registryUid)
            );
            store.dispatch(helmPacksFetcher.key(draft.registryUid).fetch());
            store.dispatch(
              helmPackVersionFetcher
                .key(entity.packName)
                .fetch(draft.registryUid)
            );
          }
        }

        if (draft.type === "operator-instance") {
          // new operator
          if (!draft?.persisted) {
            const formFields = (
              entity?.template?.parameters?.inputParameters || []
            ).reduce((acc, inputParam) => {
              acc[inputParam.name] = inputParam.value || "";

              return acc;
            }, {});

            return {
              ...entity.template,
              packUid: entity.packUid,
              version: entity.version,
              subType: entity.subType || "",
              ...formFields,
            };
          }

          // persisted operator
          const formFields = (entity?.properties || []).reduce(
            (acc, inputParam) => {
              acc[inputParam.name] = inputParam.value || "";

              return acc;
            },
            {}
          );

          return {
            ...entity.template,
            packUid: entity.sourceAppTierUid,
            version: entity.version,
            ...formFields,
          };
        }

        if (draft.type === "container") {
          if (!ociRepositoriesFetcher.selector(store.getState()).result) {
            store.dispatch(ociRepositoriesFetcher.fetch());
          }

          if (draft?.persisted) {
            const containerValuesObject = YAML.parse(
              draft.values
            ).containerService;

            return {
              type: "container",
              ...entity.template,
              registryUid: entity.registryUid,
              containerRegistryUid:
                draft?.containerRegistryUid ||
                entity?.spec?.containerRegistryUid ||
                "",
              packUid: entity.packUid,
              version: entity.version,
              ports: draft?.ports || containerValuesObject?.ports || [""],
              access:
                draft?.access || containerValuesObject?.access || "private",
              args: draft?.args || containerValuesObject?.args || [""],
              command:
                draft?.command ||
                (containerValuesObject?.command || []).join(" "),
              env:
                draft?.env ||
                (containerValuesObject?.env || [{}]).map((env) => ({
                  key: env?.name || "",
                  value: env?.value || "",
                })),
              image: draft?.image || containerValuesObject?.image || "",
              replicas: draft?.replicas || containerValuesObject?.replicas || 1,
              pathToMount:
                draft?.pathToMount || containerValuesObject?.pathToMount,
              volumeName:
                draft?.volumeName || containerValuesObject?.volumeName,
              volumeSize:
                draft?.volumeSize || containerValuesObject?.volumeSize,
              values: entity.values,
            };
          }

          return {
            registryUid: entity.registryUid,
            packUid: entity.packUid,
            version: entity.version,
            containerRegistryUid: draft.containerRegistryUid || "",
            ports: draft.ports || [""],
            access: draft.access || "private",
            values: entity.values,
            image: draft.image || "",
          };
        }

        if (draft.type === "manifest") {
          if (!draft.persisted) {
            return {
              manifests: draft.manifests || [],
            };
          }
        }

        if (draft.type === "child-manifest") {
          if (!draft.persisted) {
            return {
              values: draft.values || "",
            };
          }

          //TODO see if you can use fileListConnector.selectors
          const profile = appProfileDetailFetcher.selector(
            store.getState()
          ).result;
          const activeFile = module.state.fileList.activeFile;
          const childrenMap = module.state.fileList.childrenMap;
          const entities = module.state.fileList.entities;
          const parentGuid = Object.keys(childrenMap).find((guid) =>
            childrenMap[guid].includes(activeFile)
          );
          const tierUid = entities[parentGuid].uid;

          const manifestUid = draft.uid;
          let manifestContent = draft?.values || "";

          // this means the manifest is persisted, has no loaded content and needs api call to fetch it's data
          if (manifestUid && !manifestContent) {
            const manifestData = await api.get(
              `v1/appProfiles/${profile.metadata.uid}/tiers/${tierUid}/manifests/${manifestUid}`
            );

            manifestContent = manifestData.spec?.published?.content || "";

            module.dispatch({
              type: module.actions.fileList.updateEntity,
              fileGuid: draft.guid,
              overwrites: {
                values: manifestContent,
              },
            });

            module.dispatch({
              type: module.actions.drafts.stashForm,
              formData: {
                ...module.state.drafts[draft.guid],
                values: manifestContent,
              },
            });
          }

          return {
            ...draft,
            values: manifestContent,
          };
        }
      },
      change(payload, module) {
        const type = module.form.state.data.type;
        if (type === "helm") {
          helmEffects(payload, module);
          manifestEffects(payload, module);
        }
        if (type === "manifest") {
          manifestEffects(payload, module);
        }
        if (type === "operator-instance") {
          operatorInstanceEffects(payload, module);
        }
      },
    },
  },
});

const validator = new Validator();

// general rules
validator.addRule(["name"], Missing());

// Missing field rule
validator.addRule(function* (data) {
  const fields = {
    helm: ["registryUid", "packName", "packVersion"],
    container: ["image"],
    "operator-instance": (data?.parameters?.inputParameters || []).map(
      (param) => param.name
    ),
    manifest: ["manifests"],
    "child-manifest": ["values"],
  };

  for (const field of fields[data.type]) {
    yield {
      result: Missing()(data[field], field, data),
      field,
    };
  }
});

// container rules
validator.addRule(function* (data) {
  if (data.type === "container") {
    for (const portIndex of (data?.ports || []).keys()) {
      const port = data.ports[portIndex];
      if (!port) {
        yield {
          result: Missing()(port),
          field: `ports.${portIndex}`,
        };
        return;
      }
      yield {
        result: isValidPort()(port),
        field: `ports.${portIndex}`,
      };
    }
  }
});

validator.addRule(function* (data) {
  if (data.type === "container") {
    const replicas = data.replicas;
    if (!replicas && replicas !== 0) {
      yield {
        result: Missing()(replicas),
        field: `replicas`,
      };
      return;
    }
    yield {
      result: isValidReplicas()(replicas),
      field: `replicas`,
    };
  }
});

bindFormTo(applicationProfileEditor, validator);

// Workflow
// 1. create reusable reducers
// 2. create components & connectors
// 3. create module & register it
// 4. compose modules with connected components
// 5. overwrite layout with exceptions

export default function ApplicationEdit() {
  return (
    <BinderProvider module={applicationProfileEditor}>
      <Layout />
      <ServicesSelectionScreen />
      <OverviewScreen />
      <Save />
    </BinderProvider>
  );
}
