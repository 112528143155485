import moment from "moment";
import api from "services/api";
import dataFetcher from "modules/dataFetcher";
import { GENERAL_DATE_FORMAT } from "utils/constants";
import { getCurrentProject } from "state/auth/selectors";

export const PROJECT_OVERVIEW_FILTERS_MODULE = "projectOverviewFilters";

export const clustersCostsFetcher = dataFetcher({
  selectors: [
    "clustersCosts",
    (state) => getCurrentProject(state)?.projectUid,
    (state) => state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
  ],
  async fetchData([_, projectUid, groupBy], query, selectedClusters) {
    const startTime = moment(query.startTime)
      .utc()
      .startOf("day")
      .format(GENERAL_DATE_FORMAT);
    const endTime = moment(query.endTime)
      .utc()
      .endOf("day")
      .format(GENERAL_DATE_FORMAT);
    const periodPerDay = 60 * 24;
    const payload = {
      filter: {
        startTime,
        endTime,
        clusters: selectedClusters || [],
        projects: projectUid ? [projectUid] : [],
      },
      options: {
        groupBy,
        period: periodPerDay,
      },
    };

    const response = await api.post(
      "v1/dashboard/spectroclusters/cost",
      payload
    );

    return response?.resources || [];
  },
});

export const clustersConsumptionFetcher = dataFetcher({
  selectors: [
    "clustersConsumption",
    (state) => state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
  ],
  async fetchData([_, groupBy], query, selectedClusters) {
    const startTime = moment(query.startTime)
      .utc()
      .startOf("day")
      .format(GENERAL_DATE_FORMAT);
    const endTime = moment(query.endTime)
      .utc()
      .endOf("day")
      .format(GENERAL_DATE_FORMAT);
    const periodPerDay = 60 * 24;
    const payload = {
      filter: {
        startTime,
        endTime,
        clusters: selectedClusters || [],
      },
      options: {
        groupBy,
        period: periodPerDay,
      },
    };
    const response = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      payload
    );

    return response?.resources || [];
  },
});
