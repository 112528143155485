import store from "services/store";
import i18n from "i18next";
import _ from "lodash";

import Validator from "services/validator";
import { Missing, validateDomainOrIP } from "services/validator/rules";
import api from "services/api";
import notifications from "services/notifications";
import {
  overlordConfigureModal,
  datacentersFetcher,
  setOverlordNodesModal,
} from "state/overlord/services/list";

import createActions from "modules/form/actions";
import {
  propertiesFetcher,
  OVERLORD_CLOUD_MODULE,
} from "state/overlord/services/list";
import { openInlineModal } from "state/dns/actions/create";
import { dnsMappingsFetcher } from "state/dns/services";
import { getVsphereFolderPayload, parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import {
  getVMwarePlacementOptions,
  getOverlordData,
  getDatacenters,
  getOverlordOsPatchingSchedule,
} from "state/overlord/selectors/configure";
import { fetchOverlordConfiguration } from "state/overlord/actions/configure";

const overlordAccountValidator = new Validator();
overlordAccountValidator.addRule(
  ["vcenterServer", "username", "password"],
  Missing()
);
overlordAccountValidator.addRule(["vcenterServer"], validateDomainOrIP());

const overlordCloudValidator = new Validator();
overlordCloudValidator.addRule(["datacenter", "folder"], Missing());
const domainValidator = new Validator();
domainValidator.addRule(
  ["cluster", "datastore", "network"],
  Missing({ message: () => " " })
);

domainValidator.addRule("cluster", function* (value, key, data) {
  const domains = store.getState().forms[OVERLORD_CLOUD_MODULE].data.domains;
  const clusters = domains.map(({ cluster }) => cluster);
  for (const clusterIndex in clusters) {
    const cluster = clusters[clusterIndex];
    const duplicates = clusters.filter(
      (currentItem) => currentItem === cluster
    );
    yield {
      result: duplicates.length > 1 ? i18n.t("Clusters must be unique") : false,
      field: `domains.${clusterIndex}.cluster`,
    };
  }
});

overlordCloudValidator.addRule("domains", domainValidator);
overlordCloudValidator.addRule("domains", function* (value, key, data) {
  const networks = data.domains.map((domain) => domain.network);
  const staticPlacementEnabled = data.networkType === "staticIP";

  for (const domainIndex in data.domains) {
    const domain = data.domains[domainIndex];
    yield {
      result: networks.some(
        (network) => network !== "" && network !== domain.network
      )
        ? i18n.t("Master nodes must share the same network")
        : false,
      field: `domains.${domainIndex}.network`,
    };

    yield {
      result:
        domainIndex === "0" && !domain.dns && !staticPlacementEnabled
          ? i18n.t("Please define a DNS mapping")
          : false,
      field: `domains.${domainIndex}.dns.spec.dnsName`,
    };
  }
});

export const overlordAccountFormActions = createActions({
  init: async () => {
    const overlordUid = store.getState().location?.params?.uid;
    if (overlordUid) {
      await store.dispatch(fetchOverlordConfiguration(overlordUid));
    }

    const overlordData = getOverlordData(store.getState());
    const cloudAccountUid = overlordData?.spec?.cloudAccountUid;
    if (!cloudAccountUid) {
      return Promise.resolve({
        name: overlordData?.metadata?.name || "new-cloud-gateway",
        vcenterServer: "",
        username: "",
        password: "",
        insecure: true,
        shareWithProjects: true,
        tags: [],
      });
    }

    const data = await api.get(`v1/cloudaccounts/vsphere/${cloudAccountUid}`);

    return {
      name: data.metadata.name,
      tags: parseTagsForInput(overlordData.metadata.labels),
      ...data.spec,
      shareWithProjects: data.metadata.annotations.scopeVisibility === "20",
    };
  },
  submit: async function thunk(data) {
    const overlordData = getOverlordData(store.getState());
    const payload = {
      account: {
        vcenterServer: data.vcenterServer,
        username: data.username,
        password: data.password,
        insecure: data.insecure,
      },
      shareWithProjects: data.shareWithProjects,
      metadata: {
        name: data.name,
        labels: formatTags(data.tags),
      },
      name: data.name,
    };

    try {
      await api.put(
        `v1/overlords/${overlordConfigureModal.data.uid}/metadata`,
        {
          metadata: payload.metadata,
        }
      );
    } catch (err) {
      notifications.warn({
        message: i18n.t(
          "Something went wrong when updating private cloud gateway metadata"
        ),
        description: err.message,
      });
      return;
    }

    let promiseMethod = api.post;
    if (overlordData?.spec?.cloudAccountUid) {
      promiseMethod = api.put;
    }
    const promise = promiseMethod(
      `v1/overlords/vsphere/${overlordConfigureModal.data.uid}/account`,
      payload
    );
    store.dispatch({
      type: "CONFIGURE_OVERLORD_ACCOUNT",
      promise: promise,
    });
    try {
      await promise;
      store.dispatch(datacentersFetcher.fetch());
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to create your account"
        ),
        description: err.message,
      });
    }
  },
  validator: overlordAccountValidator,
});

export const overlordCloudFormActions = createActions({
  validator: overlordCloudValidator,
  init: async () => {
    let overlordData = getOverlordData(store.getState());
    const spectroClusterUid = overlordData?.spec?.spectroClusterUid;

    await store.dispatch(fetchOverlordConfiguration(overlordData.metadata.uid));
    overlordData = getOverlordData(store.getState());
    if (!spectroClusterUid) {
      return Promise.resolve({
        datacenter: "",
        folder: "",
        imageTemplateFolder: "",
        useFolderSufix: true,
        size: 1,
        name: overlordData.metadata.name,
        networkType: "dhcp",
        osPatchingScheduleOption: "never",
        patchOnBoot: true,
        domains: [
          {
            cluster: "",
            datastore: "",
            network: "",
            resourcePool: "",
            parentPoolUid: "",
          },
        ],
      });
    }

    const cluster = await api.get(`v1/spectroclusters/${spectroClusterUid}`);

    const cloudConfig = await api.get(
      `v1/cloudconfigs/${cluster.spec.cloudConfigRef.kind}/${cluster.spec.cloudConfigRef.uid}`
    );

    const clusterConfig = cloudConfig?.spec?.clusterConfig;

    if (cloudConfig?.placement?.cluster) {
      store.dispatch(
        propertiesFetcher.key(cloudConfig?.placement?.cluster).fetch()
      );
    }

    await store.dispatch(dnsMappingsFetcher.fetch());

    const dnsMapping =
      dnsMappingsFetcher.selector(store.getState())?.result || [];

    const masterPlacements =
      cloudConfig?.spec?.machinePoolConfig[0]?.placements || [];

    let domains = masterPlacements.map((placement) => {
      return {
        ...placement,
        dns: dnsMapping.find((mapping) => {
          return _.isEqual(
            _.pick(mapping.spec, [
              "network",
              "privateGatewayUid",
              "datacenter",
            ]),
            {
              network: placement.network.networkName,
              datacenter: placement.datacenter,
              privateGatewayUid: overlordData.metadata.uid,
            }
          );
        }),
        disabled: true,
        network: placement.network.networkName,
        resourcePool: !!placement.resourcePool ? placement.resourcePool : "",
        parentPoolUid: placement.network.parentPoolRef?.uid,
        staticIp: clusterConfig.staticIp,
      };
    });

    return Promise.resolve({
      name: overlordData.metadata.name,
      ...clusterConfig,
      computecluster: clusterConfig?.placement.cluster,
      resourcePool: clusterConfig?.placement?.resourcePool || "",
      network: clusterConfig?.network?.networkName,
      sshKeys:
        clusterConfig?.sshKeys.length > 0 ? clusterConfig?.sshKeys : [""],
      ntpServers:
        clusterConfig?.ntpServers.length > 0 ? clusterConfig?.ntpServers : [],
      size: cloudConfig?.spec?.machinePoolConfig[0].size,
      datacenter: masterPlacements?.[0]?.datacenter || "",
      mode: spectroClusterUid ? "configure-edit" : "configure",
      domains,
      networkType: clusterConfig.staticIp ? "staticIP" : "dhcp",
    });
  },
  submit: async (data) => {
    const state = store.getState();
    const ipamUid = state.overlord.configure.configureIPPoolUid;
    const staticIp = data.networkType === "staticIP";
    const dnsSetting = data.domains?.[0]?.dns?.spec?.dnsName;
    const osPatchingSchedule = getOverlordOsPatchingSchedule(state);

    const payload = {
      clusterConfig: {
        ...(!!dnsSetting
          ? {
              controlPlaneEndpoint: {
                type: "DDNS",
                ddnsSearchDomain: dnsSetting,
              },
            }
          : {}),
        staticIp,
        placements: (data.domains || []).map(({ network, dns, ...rest }) => ({
          ...rest,
          datacenter: data.datacenter,
          folder: getVsphereFolderPayload(data),
          imageTemplateFolder: data.imageTemplateFolder || undefined,
          network: {
            networkName: network,
            staticIp,
            parentPoolUid: rest.parentPoolUid || ipamUid,
          },
        })),
        sshKeys: !data.sshKeys ? [] : data.sshKeys.filter((item) => !!item),
        ntpServers: !data.ntpServers
          ? []
          : data.ntpServers.filter((item) => !!item),
      },
      clusterSettings: {
        machineManagementConfig: {
          osPatchConfig: {
            schedule: osPatchingSchedule,
            patchOnBoot: data.patchOnBoot,
          },
        },
      },
      size: data.size || 1,
    };

    const overlordData = getOverlordData(store.getState());
    let promiseMethod = api.post;
    if (overlordData?.spec?.spectroClusterUid) {
      promiseMethod = api.put;
    }

    const promise = promiseMethod(
      `v1/overlords/vsphere/${
        overlordConfigureModal.data?.uid || setOverlordNodesModal.data?.uid
      }/cloudconfig`,
      payload
    );
    store.dispatch({
      type: "CREATE_OVERLORD_VSPHERE_CLOUD_CONFIG",
      promise,
    });
    try {
      await promise;
      notifications.success({
        message: i18n.t(
          "Private cloud gateway configuration saved successfully"
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18n.t("We could not save the cloud config for the overlord"),
        description: err.message,
      });
    }

    return promise;
  },
});

export function openDnsSettings() {
  return async function thunk(dispatch, getState) {
    const formData = getState().forms?.[OVERLORD_CLOUD_MODULE]?.data;
    const dns = formData.domains[0].dns;
    const overlordData = getOverlordData(store.getState());

    const updates = {
      network: formData.domains[0].network,
      datacenter: formData.datacenter,
      privateGatewayUid: overlordData.metadata.uid,
    };

    await dispatch(
      openInlineModal((dns) => {
        dispatch(
          overlordCloudFormActions.onChange({
            module: OVERLORD_CLOUD_MODULE,
            name: "domains.0.dns",
            value: dns,
          })
        );

        dispatch(
          overlordCloudFormActions.validateField({
            name: `domains.0.dns.spec.dnsName`,
            module: OVERLORD_CLOUD_MODULE,
          })
        );
      }, dns?.metadata?.uid)
    );

    if (!dns) {
      dispatch(
        overlordCloudFormActions.batchChange({
          module: "dnsMapping",
          updates,
        })
      );
    }
  };
}

export function onDatacenterChange(datacenter) {
  return (dispatch, getState) => {
    const datacenters = getDatacenters(getState());
    let imageTemplateFolder = "";

    const selectedDatacenter = datacenters.find(
      (dc) => dc.datacenter === datacenter
    );

    if ((selectedDatacenter?.folders || []).includes("spectro-templates")) {
      imageTemplateFolder = "spectro-templates";
    }

    dispatch(
      overlordCloudFormActions.batchChange({
        module: OVERLORD_CLOUD_MODULE,
        updates: {
          datacenter,
          computecluster: "",
          datastore: "",
          network: "",
          resourcePool: "",
          folder: "",
          imageTemplateFolder,
        },
      })
    );
  };
}

export function onDataclusterChange(name, cluster) {
  return (dispatch, getState) => {
    const updates = {
      cluster,
      computecluster: cluster,
      datastore: "",
      network: "",
      resourcePool: "",
    };
    if (name.startsWith("domains")) {
      const pathParts = name.split(".");
      const domainIndex = pathParts[1];
      const domains = [...getState().forms[OVERLORD_CLOUD_MODULE].data.domains];

      const prevValue = domains[domainIndex].cluster;

      domains.splice(domainIndex, 1, {
        cluster,
        datastore: "",
        network: "",
        resourcePool: "",
        staticIp: domains[domainIndex].staticIp,
      });
      dispatch(
        overlordCloudFormActions.batchChange({
          module: OVERLORD_CLOUD_MODULE,
          updates: {
            domains,
          },
        })
      );

      if (prevValue !== "") {
        const relatedErrors = domains.reduce((accumulator, domain, index) => {
          if (domain.cluster === prevValue && index !== domainIndex) {
            accumulator.push(index);
          }

          return accumulator;
        }, []);

        dispatch(
          overlordCloudFormActions.validateField({
            name: relatedErrors.map((index) => `domains.${index}.cluster`),
            module: OVERLORD_CLOUD_MODULE,
          })
        );
      }
    } else {
      dispatch(
        overlordCloudFormActions.batchChange({
          module: OVERLORD_CLOUD_MODULE,
          updates,
        })
      );
    }

    dispatch(propertiesFetcher.key(cluster).fetch());
  };
}

export function onNetworkChange(name, network) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms[OVERLORD_CLOUD_MODULE].data.domains];
    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      network,
    });
    const staticPlacementEnabled =
      getState().forms[OVERLORD_CLOUD_MODULE].data.networkType === "staticIP";

    if (!staticPlacementEnabled && domainIndex === "0") {
      const domain = domains[domainIndex];
      const properties = getVMwarePlacementOptions(getState());
      const selectedNetwork = properties[domain.cluster].networks.find(
        (option) => domain.network === option.value
      );

      if (selectedNetwork) {
        domains.splice(domainIndex, 1, {
          ...domains[domainIndex],
          dns: selectedNetwork.dnsMapping,
        });
      }
    }

    dispatch(
      overlordCloudFormActions.batchChange({
        module: OVERLORD_CLOUD_MODULE,
        updates: {
          domains,
        },
      })
    );

    const relatedErrors = domains.reduce((accumulator, domain, index) => {
      if (domain.network === network && index !== domainIndex) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    dispatch(
      overlordCloudFormActions.validateField({
        name: relatedErrors.map((index) => `domains.${index}.network`),
        module: OVERLORD_CLOUD_MODULE,
      })
    );

    dispatch(
      overlordCloudFormActions.validateField({
        name: `domains.0.dns.spec.dnsName`,
        module: OVERLORD_CLOUD_MODULE,
      })
    );
  };
}

export function onAddDomain(name) {
  return (dispatch, getState) => {
    const domains = [...getState().forms[OVERLORD_CLOUD_MODULE].data.domains];
    domains.push({
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
    });

    dispatch(
      overlordCloudFormActions.batchChange({
        module: OVERLORD_CLOUD_MODULE,
        updates: {
          domains,
        },
      })
    );
  };
}

export function onDeleteDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const formState = getState().forms[OVERLORD_CLOUD_MODULE];
    const domains = [...formState.data.domains];
    domains.splice(domainIndex, 1);

    if (domains.length === 0) {
      domains.push({
        cluster: "",
        datastore: "",
        network: "",
        resourcePool: "",
        parentPoolUid: "",
      });
    }

    const errorFields = ["cluster", "datastore", "network"].map(
      (field) => `domains.${domainIndex}.${field}`
    );
    const formErrors = formState.errors;
    const updatedErrors = formErrors.map((error) => {
      const shouldRemove = errorFields.includes(error.field);
      if (shouldRemove) {
        return { ...error, result: false };
      }

      return error;
    });

    dispatch(
      overlordCloudFormActions.updateErrors({
        module: OVERLORD_CLOUD_MODULE,
        errors: updatedErrors,
      })
    );

    dispatch(
      overlordCloudFormActions.batchChange({
        module: OVERLORD_CLOUD_MODULE,
        updates: {
          domains,
        },
      })
    );
  };
}
