import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { APIKeysSchema } from "utils/schemas";
import { red } from "utils/constants/colors";
import { MY_API_KEYS, SETTINGS } from "utils/constants/routes";

import Loader from "components/ui/Loader";
import { TextButton } from "components/ui/Button";
import ActionMenu from "components/common/ActionMenu";
import AddNewItem from "components/styled/AddNewItem";

import createList, { Blocks } from "modules/list";
import history from "services/history";
import { API_KEYS_LIST_MODULE } from "state/apikeys/services";
import {
  apikeysListActions,
  onAPIKeyChangeStatus,
  onAPIKeyDelete,
} from "state/apikeys/actions";
import ApiKeyModal from "./create";
import Link from "components/common/History/Link";
import { generatePath } from "react-router";
import moment from "moment";
import Clipboard from "components/common/Clipboard";
import GreyContainer from "components/styled/GreyContainer";
import Filters, { SelectFieldWrap } from "components/styled/Filters";
import { createSelectComponent } from "modules/dataFetcher";
import { usersMetaFetcher } from "state/users/services";
import StatusCard from "components/styled/StatusCard";
import { getFullName } from "utils/presenters";

const StyledTextButton = styled(TextButton)`
  padding: 0;
  min-height: 50px;
`;

const UsersSelect = createSelectComponent({
  Component: Blocks.FilterFields.Select,
  dataFetcher: usersMetaFetcher,
  parseOptions(items) {
    if (!items?.length) {
      return [];
    }

    const usersDropdown = items.map((user) => ({
      label: getFullName(user),
      value: user.metadata.uid,
    }));

    return usersDropdown;
  },
});

const ListModule = createList({
  actions: apikeysListActions,
  schema: [APIKeysSchema],
});

function isExpiredKey(expirationDate) {
  return moment().diff(expirationDate) > 0;
}

const ApiKeys = ({
  loading,
  onAPIKeyChangeStatus,
  onAPIKeyDelete,
  listLength,
  location,
  query,
}) => {
  const { t } = useTranslation();

  const haveAnyActiveFilter = !!query?.user;
  const showAllApiKeys = !location.startsWith(MY_API_KEYS.ROOT);

  const apiKeyCreateUrl = showAllApiKeys
    ? SETTINGS.CREATE_API_KEY
    : MY_API_KEYS.CREATE;

  const columns = useMemo(() => {
    function renderActionsColumn(data) {
      const expiredKey = isExpiredKey(data.spec.expiry);
      const activationButton = data.status.isActive
        ? {
            key: "revoke",
            label: t("Revoke"),
            onClick: () => onAPIKeyChangeStatus(data, false),
          }
        : {
            key: "reactivate",
            label: t("Re-activate"),
            onClick: () => onAPIKeyChangeStatus(data, true),
          };
      const actions = [
        {
          key: "edit",
          label: t("Edit"),
          href:
            data?.metadata?.uid &&
            generatePath(
              location === MY_API_KEYS.ROOT
                ? MY_API_KEYS.EDIT
                : SETTINGS.EDIT_API_KEY,
              {
                uid: data.metadata.uid,
              }
            ),
        },
        !expiredKey && activationButton,
        {
          key: "delete",
          color: red,
          label: t("Delete"),
          onClick: () => onAPIKeyDelete(data),
        },
      ].filter(Boolean);

      return <ActionMenu options={actions} />;
    }

    const arr = [
      {
        title: t("Name"),
        key: "name",
        dataIndex: ["metadata", "name"],
      },
      {
        title: t("Description"),
        key: "description",
        dataIndex: ["metadata", "annotations", "description"],
      },
      {
        title: t("Expiration Date"),
        key: "expiry",
        dataIndex: ["spec", "expiry"],
        render(expirationDate) {
          const expiredKey = isExpiredKey(expirationDate);
          const date = moment(expirationDate).format("D MMM YYYY");

          if (expiredKey) {
            return `${date} (${t("expired")})`;
          }

          return `${date}`;
        },
      },
      {
        title: t("Key"),
        key: "key",
        width: "20%",
        dataIndex: ["spec", "key"],
        render(data) {
          return (
            <Clipboard text={data}>
              <GreyContainer>
                <span>{data}</span>
              </GreyContainer>
            </Clipboard>
          );
        },
      },
      {
        title: t("Active"),
        key: "active",
        width: "5%",
        dataIndex: ["status", "isActive"],
        render(data) {
          return data ? (
            <StatusCard status={"active"} />
          ) : (
            <StatusCard status={"inactive"} />
          );
        },
      },
      {
        title: null,
        key: "actions",
        width: "2%",
        render: renderActionsColumn,
      },
    ];

    if (showAllApiKeys) {
      arr.splice(4, 0, {
        title: t("User"),
        key: "user",
        dataIndex: ["spec", "user"],
        render(data) {
          return `${data.firstName} ${data.lastName}`;
        },
      });
    }
    return arr;
  }, [location, t, onAPIKeyChangeStatus, onAPIKeyDelete]);

  if (loading) {
    return <Loader small />;
  }

  return (
    <>
      <ListModule module={API_KEYS_LIST_MODULE} preventInitOnMount>
        {showAllApiKeys && (haveAnyActiveFilter || listLength > 0) && (
          <Filters.FormWrap top="0" withTopBorder={false} withPadding={false}>
            <SelectFieldWrap>
              <UsersSelect
                name="user"
                data-qa="api-key-users"
                placeholder={t("All Users")}
                label={t("User Name")}
                showSearch={false}
                maxTagCount={1}
                filterOption={false}
                showArrow
                allowClear
              />
            </SelectFieldWrap>
          </Filters.FormWrap>
        )}
        <Blocks.EmptyResult
          size="medium"
          description={
            showAllApiKeys && haveAnyActiveFilter ? (
              <p>{t("Nothing was found.")}</p>
            ) : (
              <>
                <p>{t("No API keys were set up yet.")}</p>
                <p>{t("Start with creating a new API key.")}</p>
                <StyledTextButton onClick={() => history.push(apiKeyCreateUrl)}>
                  <AddNewItem>{t("Add new API key")}</AddNewItem>
                </StyledTextButton>
              </>
            )
          }
        />
        <Blocks.Table hasEmptyPage={true} hasLoader={false} columns={columns} />
      </ListModule>
      {listLength ? (
        <Link to={apiKeyCreateUrl}>
          <AddNewItem>{t("Add New API key")}</AddNewItem>
        </Link>
      ) : null}
      <ApiKeyModal />
    </>
  );
};

export default connect(
  (state) => ({
    loading: state.list[API_KEYS_LIST_MODULE]?.isLoading,
    listLength: state.list[API_KEYS_LIST_MODULE]?.items.initial.length,
    query: state.list[API_KEYS_LIST_MODULE]?.query,
    location: state.router.location.pathname,
  }),
  { onAPIKeyChangeStatus, onAPIKeyDelete }
)(ApiKeys);
