import notifications from "services/notifications";
import i18next from "i18next";
import history from "services/history";
import { CLUSTER_PROFILES } from "utils/constants/routes";
import { generatePath } from "react-router";
import { getStoreEntity } from "services/store";
import { ClusterProfileSchema } from "utils/schemas";
import { fetchClusterProfilePacks } from "modules/profileStack/actions";
import {
  clusterProfileListActions,
  environmentSelectFormActions,
  ENVIRONMENT_SELECTOR_MODULE,
  newClusterModal,
} from "./services";
import { cloneProfile } from "state/clusterprofile/actions/create";
import { clusterProfileListFetcher } from "state/clusterprofile/services";
import { clusterDependency } from "state/cluster/actions/list/clusters";

export function setSelectedCluster(guid) {
  return async (dispatch) => {
    dispatch({
      type: "SET_SELECTED_CLUSTER_PROFILE",
      selectedProfileGuid: guid,
    });

    const clusterProfile = getStoreEntity(guid, ClusterProfileSchema);
    dispatch(fetchClusterProfilePacks(clusterProfile));
  };
}

export function changeOOTBStep(step) {
  return (dispatch) => {
    dispatch({
      type: "SET_OOTB_STEP",
      step,
    });
  };
}

export function cloneAndEditProfile(guid) {
  return async () => {
    let response;
    const clusterProfile = getStoreEntity(guid, ClusterProfileSchema);
    const name = `${clusterProfile.metadata.name}-copy`;

    try {
      response = await cloneProfile({ guid, name });
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while trying to clone the cluster profile"
        ),
        description: error?.message,
      });
      return;
    }

    if (response?.uid) {
      history.push(
        generatePath(CLUSTER_PROFILES.DETAILS, {
          tab: "cluster",
          id: response.uid,
        })
      );
      newClusterModal.close();
    }
  };
}

export function openNewClusterModal() {
  return (dispatch) => {
    dispatch({
      type: "SET_NEW_CLUSTER_ACTION",
      key: "start",
    });
    dispatch({
      type: "SET_FINISHED_TOUR",
      finishedTour: false,
    });
    newClusterModal.open();
  };
}

export function loadAllClusterProfiles(module, initialQuery) {
  return async (dispatch, getState) => {
    if (initialQuery) {
      await dispatch(
        clusterProfileListActions.initialize(module, initialQuery)
      );
    }

    await dispatch(clusterProfileListActions.nextPage(module));

    if (getState().list[module].nextToken) {
      await dispatch(loadAllClusterProfiles(module));
    }
  };
}

export function setNewClusterPath(key) {
  return (dispatch, getState) => {
    const clusterprofiles = clusterProfileListFetcher.selector(
      getState()
    )?.result;
    if (clusterprofiles?.length === 0 && key === "create") {
      clusterDependency.open().then(() => {
        history.push("/profiles/cluster");
        newClusterModal.close();
      });

      return;
    }
    if (key === "ootb") {
      dispatch(
        loadAllClusterProfiles("ootb-use-case", {
          filter: {
            profileType: ["add-on"],
          },
        })
      );
      dispatch(
        loadAllClusterProfiles("ootb-infra", {
          filter: {
            profileType: ["cluster"],
          },
        })
      );
    }

    dispatch({
      type: "SET_NEW_CLUSTER_ACTION",
      key,
    });
  };
}

export function onCardSelect(value, clusterType) {
  return (dispatch) => {
    dispatch(
      environmentSelectFormActions.batchChange({
        module: ENVIRONMENT_SELECTOR_MODULE,
        updates: {
          cloudType: value,
          clusterType,
        },
      })
    );
  };
}

export function redirectToRoute(path) {
  return () => {
    history.push(path);
    newClusterModal.close();
  };
}
