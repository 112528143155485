import vertexEnvironmentPath from "assets/images/login/vertex/environment.png";
import VertexColoredLogoWhiteText from "assets/icons/vertex/palette_logo_white_text.svg?component";
import SmallVertexLogo from "assets/icons/vertex/small_logo.svg?component";
import VertexColoredLogoColoredText from "assets/icons/vertex/palette_logo_colored_text.svg?component";
import VertexFullWhiteLogo from "assets/icons/vertex/palette_logo_full_white.svg?component";

import paletteEnvironmentPath from "assets/images/login/environment.png";
import PaletteColoredLogoWhiteText from "assets/icons/logo_landscape_colored.svg?component";
import PaletteColoredLogoColoredText from "assets/icons/logo_landscape_colored_purple_text.svg?component";
import FullWhiteLogo from "assets/icons/logo_landscape_white.svg?component";
import SmallPaletteLogo from "assets/icons/brands/palette.svg?component";

import appEnv from "services/app";
import { lavender, purple } from "utils/constants/colors";

export function useTheme({ isMenuExpanded = false, isAdminMode = false } = {}) {
  if (appEnv.env.theme?.variant === "VERTEX") {
    return {
      envBackgroundPath: vertexEnvironmentPath,
      buttonBackground: "linear-gradient(to right, #3575CF, #3DA894)",
      LightLogo: VertexFullWhiteLogo,
      ColoredLogo: VertexColoredLogoColoredText,
      ColoredLogoWhiteText: VertexColoredLogoWhiteText,
      SmallColoredLogo: SmallVertexLogo,
      SideMenuLogo: isMenuExpanded
        ? VertexColoredLogoWhiteText
        : SmallVertexLogo,
      customMenuLogoStyle: false,
      inputBorder: "#BCC3CD",
      secondaryButtonColor: "#206CD1",
      linkColor: "#206CD1",
      linkColorHover: "#206CD1",
      textColor: "#206cd1",
    };
  }

  return {
    envBackgroundPath: paletteEnvironmentPath,
    buttonBackground:
      "linear-gradient(92.91deg, #5a53a4 5.15%, #8460b2 74.36%)",
    LightLogo: FullWhiteLogo,
    ColoredLogo: PaletteColoredLogoColoredText,
    ColoredLogoWhiteText: PaletteColoredLogoWhiteText,
    SmallColoredLogo: SmallPaletteLogo,
    SideMenuLogo: isAdminMode ? PaletteColoredLogoWhiteText : FullWhiteLogo,
    customMenuLogoStyle: true,
    inputBorder: purple,
    secondaryButtonColor: "#332e76",
    linkColor: "#4a4398",
    linkColorHover: "#332e76",
    textColor: lavender,
  };
}
