import createFormActions from "modules/form/actions";
import ListActions from "modules/list/actions";
import {
  SYFT_CONFIGURATION_FORM_MODULE,
  scanWarning,
  syftScanConfigurationModal,
} from "../services/scan";
import { ClusterScanSchema } from "utils/schemas";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import { getRawCluster } from "../selectors/details";
import notifications from "services/notifications";
import i18next from "i18next";
import { DEFAULT_SCHEDULE_OPTIONS } from "utils/constants";
import withPolling from "utils/withPolling";
import { getCronScheduleValue } from "utils/parsers";
import Validator from "services/validator";
import { ApplyIf, Missing } from "services/validator/rules";
import parseCron from "utils/cron";
import { fetchBackupLocationsFetcher } from "state/backuplocations/actions";

//
function isScanScheduleShort() {
  return (value) => {
    var { schedules } = parseCron(value);
    const hours = schedules[0].h;

    const isMinuteIntervalShort = !schedules[0].m || schedules[0].m.length > 1;

    const isHourIntervalShort =
      !hours ||
      (hours.length > 1 &&
        hours.some((hour, index) => hours[index + 1] - hour < 3));

    if (isHourIntervalShort || isMinuteIntervalShort) {
      return i18next.t(
        "Scan schedule operation requires a minimum interval of at least 3 hours"
      );
    }
    return false;
  };
}

const validator = new Validator();
const sonobuoyValidator = new Validator();
const kubebenchValidator = new Validator();
const kubehunterValidator = new Validator();

validator.addRule("kubebench", function* () {
  yield kubebenchValidator;
});

validator.addRule("kubehunter", function* () {
  yield kubehunterValidator;
});

validator.addRule("sonobuoy", function* () {
  yield sonobuoyValidator;
});

[sonobuoyValidator, kubebenchValidator, kubehunterValidator].forEach((val) => {
  val.addRule(
    "recurrency",
    ApplyIf((value, key, data) => data.type === "custom", isScanScheduleShort())
  );
});

const API_KEY_MAPPING = {
  kubebench: "kubeBench",
  kubehunter: "kubeHunter",
  sonobuoy: "sonobuoy",
};

export const LOGS_API_KEY_MAPPING = {
  "kube-bench": "kubeBench",
  "kube-hunter": "kubeHunter",
  sonobuoy: "sonobuoy",
  kubebench: "kubeBench",
  kubehunter: "kubeHunter",
  syft: "syft",
};

export const scansCountFetcher = keyedDataFetcher({
  selectors: ["scans", "count"],
  fetchData([_1, _2, clusterId]) {
    return api
      .get(
        `v1/spectroclusters/${clusterId}/features/complianceScan/logs/drivers`
      )
      .then((data) => {
        return {
          kubebench: data?.kubeBenchLogs?.length || 0,
          kubehunter: data?.kubeHunterLogs?.length || 0,
          sonobuoy: data?.sonobuoyLogs?.length || 0,
          syft: data?.syftLogs?.length || 0,
        };
      });
  },
});

export const kubebenchListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.kubeBenchLogs }));
  },
});

export const kubehunterListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.kubeHunterLogs }));
  },
});

export const sonobuoyListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.sonobuoyLogs }));
  },
});

export const syftListActions = new ListActions({
  schema: [ClusterScanSchema],
  fetchData() {
    const cluster = getRawCluster(store.getState());
    return api
      .get(
        `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
      )
      .then((data) => ({ items: data.syftLogs }));
  },
});

export const scanFetcher = keyedDataFetcher({
  selectors: ["cluster-scans"],
  fetchData([_, scanUid], scanType) {
    const cluster = getRawCluster(store.getState());
    return api.get(
      `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/${scanUid}/drivers/${LOGS_API_KEY_MAPPING[scanType]}`
    );
  },
});

const SCAN_LISTS = {
  kubebench: kubebenchListActions,
  kubehunter: kubehunterListActions,
  sonobuoy: sonobuoyListActions,
  syft: syftListActions,
};

export function initList(type) {
  return function thunk(dispatch) {
    if (!SCAN_LISTS[type]) {
      return;
    }
    dispatch(SCAN_LISTS[type].initialize(type));
  };
}

export const watchScansTask = withPolling(async () => {
  const cluster = getRawCluster(store.getState());
  const data = await api.get(
    `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/logs/drivers`
  );
  store.dispatch({
    type: "SCANS_STATUS_UPDATE",
    schema: {
      kubeBenchLogs: [ClusterScanSchema],
      kubeHunterLogs: [ClusterScanSchema],
      sonobuoyLogs: [ClusterScanSchema],
      syft: [ClusterScanSchema],
    },
    promise: Promise.resolve(data),
  });

  const finished = Object.keys(data).every((key) => {
    const logs = data[key];

    return logs.every((log) => log.status.state === "Completed");
  });

  if (finished) {
    return Promise.resolve();
  }

  return Promise.reject();
}, 10000);

export function openSyftScanConfigurationModal() {
  syftScanConfigurationModal.open().then(() => {
    return store.dispatch(
      syftScanConfigurationFormActions.submit({
        module: SYFT_CONFIGURATION_FORM_MODULE,
      })
    );
  });
}

export function runOnDemand({ type }) {
  return function thunk(dispatch, getState) {
    const cluster = getRawCluster(getState());
    async function runScan() {
      try {
        await api.post(
          `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/onDemand`,
          {
            [API_KEY_MAPPING[type]]: {
              runScan: true,
            },
          }
        );
        notifications.success({
          message: i18next.t("Scan scheduled"),
        });
        dispatch(scansCountFetcher.key(cluster.metadata.uid).fetch());
        await dispatch(SCAN_LISTS[type].fetchItems(type));
        watchScansTask.start();
      } catch (err) {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: err.message,
        });
      }
    }

    if (type === "sonobuoy") {
      scanWarning.open({ type }).then(runScan);
      return;
    }

    if (type === "syft") {
      openSyftScanConfigurationModal();
      return;
    }

    runScan();
  };
}

export function loadClusterScan(scanType, scanUid) {
  return function tunk(dispatch) {
    dispatch(scanFetcher.key(scanUid).fetch(scanType));
  };
}

const syftScanConfigurationValidator = new Validator();
syftScanConfigurationValidator.addRule(["format", "scope"], Missing());
syftScanConfigurationValidator.addRule(
  ["labelSelector"],
  ApplyIf((_1, _2, data) => data.scope === "label-selector", Missing())
);
syftScanConfigurationValidator.addRule(
  ["podName"],
  ApplyIf((_1, _2, data) => data.scope === "pod", Missing())
);
syftScanConfigurationValidator.addRule(
  ["namespace"],
  ApplyIf((_1, _2, data) => data.scope !== "cluster", Missing())
);

export const syftScanConfigurationFormActions = createFormActions({
  validator: syftScanConfigurationValidator,
  async init() {
    await store.dispatch(fetchBackupLocationsFetcher.fetch());

    return Promise.resolve({
      format: "cyclonedx-json",
      labelSelector: [],
      namespace: "",
      podName: "",
      scope: "cluster",
      location: "",
    });
  },
  async submit(data) {
    const cluster = getRawCluster(store.getState());

    const promise = api.post(
      `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan/onDemand`,
      {
        syft: {
          runScan: true,
          config: {
            ...data,
            labelSelector: data.labelSelector.join(","),
            location: {
              uid: data.location,
            },
            schedule: "now",
          },
        },
      }
    );

    try {
      await promise;
      notifications.success({
        message: i18next.t("Scan scheduled"),
      });
      store.dispatch(scansCountFetcher.key(cluster.metadata.uid).fetch());
      await store.dispatch(SCAN_LISTS["syft"].fetchItems("syft"));
      watchScansTask.start();
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: error.message,
      });
      return Promise.reject();
    }

    return promise;
  },
});

export const scanFormActions = createFormActions({
  async init() {
    const cluster = getRawCluster(store.getState());
    let data = {
      spec: {
        driverSpec: {},
      },
    };
    try {
      data = await api.get(
        `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan`
      );
    } catch (err) {}
    function isEnabled(type) {
      return !!Object.keys(data.spec.driverSpec[type]?.config.schedule || {})
        .length;
    }

    function getRecurrency(type) {
      const schedule = data.spec.driverSpec[type]?.config.schedule;
      if (!schedule?.scheduledRunTime) {
        return {
          type: "never",
          recurrency: "",
        };
      }

      const selectedOption = DEFAULT_SCHEDULE_OPTIONS.find(
        (option) => option.value === schedule?.scheduledRunTime
      );
      return {
        type: selectedOption ? selectedOption.value : "custom",
        recurrency: schedule?.scheduledRunTime,
      };
    }

    return Promise.resolve({
      kubebenchEnabled: isEnabled("kube-bench"),
      kubebench: getRecurrency("kube-bench"),
      kubehunterEnabled: isEnabled("kube-hunter"),
      kubehunter: getRecurrency("kube-hunter"),
      sonobuoyEnabled: isEnabled("sonobuoy"),
      sonobuoy: getRecurrency("sonobuoy"),
    });
  },

  async submit(data) {
    const cluster = getRawCluster(store.getState());

    const promise = api.put(
      `v1/spectroclusters/${cluster.metadata.uid}/features/complianceScan`,
      {
        kubeBench: {
          schedule: {
            scheduledRunTime: data.kubebenchEnabled
              ? getCronScheduleValue(
                  data.kubebench.type,
                  data.kubebench.recurrency
                )
              : "",
          },
        },
        kubeHunter: {
          schedule: {
            scheduledRunTime: data.kubehunterEnabled
              ? getCronScheduleValue(
                  data.kubehunter.type,
                  data.kubehunter.recurrency
                )
              : "",
          },
        },
        sonobuoy: {
          schedule: {
            scheduledRunTime: data.sonobuoyEnabled
              ? getCronScheduleValue(
                  data.sonobuoy.type,
                  data.sonobuoy.recurrency
                )
              : "",
          },
        },
      }
    );

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: error.message,
      });
    }
    return promise;
  },
  validator,
});

export const scanNamespacesFetcher = dataFetcher({
  selectors: ["scan", "namespaces"],
  async fetchData() {
    const cluster = getRawCluster(store.getState());
    const promise = api.get(
      `v1/spectroclusters/${cluster.metadata.uid}/namespaces?skipEmptyNamespace=true`
    );

    let response;
    try {
      response = await promise;
    } catch (error) {}

    return {
      items: response?.namespaces?.reduce(
        (acc, item) => [...acc, item.namespace],
        []
      ),
    };
  },
});

export function onScanScopeChange(scope) {
  return async (dispatch) => {
    dispatch(
      syftScanConfigurationFormActions.onChange({
        module: SYFT_CONFIGURATION_FORM_MODULE,
        name: "scope",
        value: scope,
      })
    );

    if (scope !== "cluster") {
      await store.dispatch(scanNamespacesFetcher.fetch());
    }
  };
}
