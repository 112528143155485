import { connect } from "react-redux";
import flags, { getDemoFlags } from "services/flags";

const Flags = (props) => {
  let shouldRender = false;
  if (props.has) {
    shouldRender = flags.has(props.has);
  }

  if (props.is) {
    shouldRender = flags.is(props.is);
  }

  if (!shouldRender) {
    return null;
  }

  return props.children || null;
};

export const DemoFlags = connect(() => ({
  flags: getDemoFlags(),
}))((props) => {
  const flags = props.flags;
  let shouldRender = false;
  if (props.has) {
    shouldRender = flags.has(props.has);
  }

  if (props.is) {
    shouldRender = flags.is(props.is);
  }

  if (!shouldRender) {
    return null;
  }

  return props.children || null;
});

export default Flags;
