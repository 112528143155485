import moment from "moment";

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = 60 * SECOND_IN_MILLISECONDS;

export function formatTimestampToDate(timestamp, format) {
  const msTimestamp = Math.floor(timestamp / 1000000);
  return moment(msTimestamp).format(format);
}

export function formatTimestampToGeneralDateFormat(timestamp) {
  return moment(timestamp).format("DD MMM, YYYY, hh:mm A");
}
