export const initialState = {
  isLoading: true,
  currentClusterGroupGuid: null,
};

export function FETCH_CLUSTER_GROUP_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function FETCH_CLUSTER_GROUP_FAILURE(nextState) {
  nextState.isLoading = false;
  nextState.currentClusterGroupGuid = null;
}

export function FETCH_CLUSTER_GROUP_NORMALIZATION(nextState, { result }) {
  nextState.currentClusterGroupGuid = result;
  nextState.isLoading = false;
}

export function REFRESH_CLUSTER_GROUP_NORMALIZATION(nextState, { result }) {
  nextState.currentClusterGroupGuid = result;
}
