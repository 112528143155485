import ListActions from "modules/list/actions";
import { MY_API_KEYS, SETTINGS } from "utils/constants/routes";
import { APIKeysSchema } from "utils/schemas";
import historyService from "services/history";
import {
  apiKeyModal,
  apiKeysFetcher,
  API_KEYS_FORM_MODULE,
  API_KEYS_LIST_MODULE,
} from "./services";
import { nonProjectApi } from "services/api";
import createFormActions from "modules/form/actions";
import notifications from "services/notifications";
import i18next from "i18next";
import moment from "moment";
import { ApplyIf, Missing } from "services/validator/rules";
import Validator from "services/validator";
import { getCurrentUser } from "state/auth/selectors";
import store from "services/store";

function filterData(data, id) {
  if (!id) {
    return data;
  }
  return { items: data.items.filter((item) => item.spec.user.uid === id) };
}

export const apikeysListActions = new ListActions({
  schema: [APIKeysSchema],
  initialQuery() {
    let user = null;
    const location = store.getState().router.location.pathname;
    if (location === MY_API_KEYS.ROOT) {
      user = getCurrentUser(store.getState())?.metadata?.uid;
    }
    return {
      user,
    };
  },
  async fetchData({ user }) {
    let data = apiKeysFetcher.selector(store.getState()).result;
    if (data) {
      return filterData(data, user);
    }

    await store.dispatch(apiKeysFetcher.fetch());
    data = apiKeysFetcher.selector(store.getState()).result;

    return filterData(data, user);
  },
});

function parseDate(date) {
  if (typeof date === "number") {
    return moment().add(date, "days").toISOString();
  }
  return moment(date).toISOString();
}

const validator = new Validator();
validator.addRule(["name", "expiryDate"], Missing());
validator.addRule(
  ["customDate"],
  ApplyIf((value, key, data) => data.expiryDate === "custom", Missing())
);

export const apikeysFormActions = createFormActions({
  validator,
  init: async () => {
    const uid = apiKeyModal?.data?.uid;
    const user = getCurrentUser(store.getState());

    let data;

    if (uid) {
      const promise = nonProjectApi.get(`v1/apiKeys/${uid}`);
      try {
        data = await promise;
      } catch (err) {
        notifications.error({
          message: i18next.t(
            "Something went wrong while fetching the requested API key"
          ),
          description: err.message,
        });
        return Promise.reject(err);
      }
    }

    return Promise.resolve({
      name: data?.metadata?.name || "",
      description: data?.metadata?.annotations?.description || "",
      expiryDate: data?.spec?.expiry
        ? moment(data?.spec?.expiry).format("D MMM YYYY")
        : "",
      customDate: moment().add(1, "days"),
      status: data?.status?.isActive,
      userUid: user?.metadata?.uid,
    });
  },
  submit: async (data) => {
    const uid = apiKeyModal?.data?.uid;

    const payload = {
      metadata: {
        name: data.name,
        annotations: {
          description: data.description,
        },
        uid: uid,
      },
      spec: {
        expiry:
          data.expiryDate === "custom"
            ? moment(data.customDate).toISOString()
            : parseDate(data.expiryDate),
        userUid: data.userUid,
      },
    };
    let promise;

    if (uid) {
      promise = nonProjectApi.put(`v1/apiKeys/${uid}`, payload);
    } else {
      promise = nonProjectApi.post(`v1/apiKeys`, payload);
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: uid
          ? i18next.t("Something went wrong while updating the API key")
          : i18next.t("Something went wrong while creating the API key"),
        description: error.message,
      });
      return Promise.reject(error);
    }

    notifications.success({
      message: uid
        ? i18next.t("API key '{{name}}' was updated successfully", {
            name: data?.name,
          })
        : i18next.t("API key '{{name}}' was created successfully", {
            name: data?.name,
          }),
    });
  },
});

export function openApiKeyModal(uid) {
  return (dispatch, getState) => {
    const location = getState().router.location.pathname;
    const apiKeysUrl = location.startsWith(MY_API_KEYS.ROOT)
      ? MY_API_KEYS.ROOT
      : SETTINGS.API_KEYS;

    function fallback() {
      historyService.push(apiKeysUrl);
      dispatch(refreshList());
    }
    apiKeyModal.open({ uid }).then(async () => {
      await dispatch(
        apikeysFormActions.submit({
          module: API_KEYS_FORM_MODULE,
        })
      );
      fallback();
    }, fallback);
    dispatch(apikeysFormActions.init({ module: API_KEYS_FORM_MODULE }));
  };
}

function refreshList() {
  return (dispatch) => {
    dispatch(apiKeysFetcher.flushCache());
    dispatch(apikeysListActions.refreshItems(API_KEYS_LIST_MODULE));
  };
}

export function onAPIKeyChangeStatus(data, isActive) {
  return async (dispatch) => {
    try {
      await nonProjectApi.put(`v1/apiKeys/${data.metadata.uid}/state`, {
        isActive,
      });
      dispatch(refreshList());
    } catch (err) {
      notifications.error({
        message: isActive
          ? i18next.t(
              "Something when wrong when trying to re-activate '{{name}}'",
              {
                name: data?.metadata?.name,
              }
            )
          : i18next.t("Something when wrong when trying to revoke '{{name}}'", {
              name: data?.metadata?.name,
            }),
      });
    }
  };
}

export function onAPIKeyDelete(data) {
  return async (dispatch) => {
    try {
      await nonProjectApi.delete(`v1/apiKeys/${data.metadata.uid}`);
      dispatch(refreshList());
    } catch (err) {
      notifications.error(
        i18next.t("Something when wrong when trying to delete '{{name}}'", {
          name: data?.metadata?.name,
        })
      );
    }
  };
}
