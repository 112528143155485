import React, { useState, useContext, useMemo } from "react";

import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { createConnector } from "modules/binder";
import { useTranslation } from "react-i18next";

import Fields from "components/ui/Fields";
import { TextButton } from "components/ui/Button";
import AddNewItem from "components/styled/AddNewItem";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { red, white } from "utils/constants/colors";
import store from "services/store";
import ActionMenu from "components/common/ActionMenu";
import { Error, FieldLabel } from "components/styled/Field";
import { useFormContext, ReadOnlyContext } from "modules/form";
import { createSelector } from "reselect";

const ApplyManifestButton = styled.div`
  font-size: 17px;
  svg {
    margin-left: 24px;
    cursor: pointer;
  }
`;
const ManifestField = styled.div`
  display: flex;
  align-items: center;
  border: 1px dashed #dddddd;
  background: ${white};
  padding: 13px 20px;

  div:first-child {
    margin: 0px;
  }
`;

const ManifestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  ${FieldLabel} {
    margin-top: 10px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #aaa;
    opacity: 0.3;
    margin-bottom: 10px;
  }
`;
const OpenEditorTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  font-size: 12px;
  transition: none;
  background: ${white};
  border-radius: 2px;
  color: inherit;

  :hover,
  :focus {
    background: ${white};
    color: inherit;
  }
`;

function ManifestList({
  addNewManifest,
  clearNewManifestInput,
  deleteNewManifest,
  accessNewManifest,
  manifestsError,
}) {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const childManifests = useFormContext().data.manifests || [];
  const readOnly = useContext(ReadOnlyContext);
  const error = useMemo(
    () =>
      manifestsError?.find((err) => err.field === "manifests" && err.result),
    [manifestsError]
  );

  function renderManifest({ name, guid }) {
    const options = [
      !readOnly && {
        key: "deleteManifest",
        color: red,
        label: t("Delete"),
        onClick: () => deleteNewManifest(guid),
      },
    ].filter(Boolean);

    return (
      <OpenEditorTextButton key={name} onClick={() => accessNewManifest(guid)}>
        <div>{name}</div>

        <ActionMenu options={options} />
      </OpenEditorTextButton>
    );
  }
  return (
    <ManifestWrapper>
      <FieldLabel>{t("Manifests")}</FieldLabel>
      <List>{childManifests.map(renderManifest)}</List>
      {showInput ? (
        <ManifestField>
          <Fields.Input
            name="childManifest"
            data-qa="childManifest"
            placeholder={t("Name of manifest")}
          />

          <ApplyManifestButton
            data-qa="detach-manifest"
            onClick={() => {
              setShowInput(!showInput);
              clearNewManifestInput();
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} color="#de7f92" />
          </ApplyManifestButton>

          <ApplyManifestButton
            data-qa="apply-manifest"
            onClick={() => {
              addNewManifest();
              setShowInput(!showInput);
              clearNewManifestInput();
            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#357BD6" />
          </ApplyManifestButton>
        </ManifestField>
      ) : (
        <>
          <AddNewItem
            hasError={!!error}
            data-qa="add-manifest"
            onClick={() => {
              if (readOnly) {
                return;
              }
              clearNewManifestInput();
              setShowInput(!showInput);
            }}
            disabled={readOnly}
          >
            {t("Add Manifests")}
          </AddNewItem>
          <Error>{error?.result}</Error>
        </>
      )}
    </ManifestWrapper>
  );
}

export const ConnectedManifestList = createConnector({
  selectors: {
    manifestsError: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.validations,
      (activeFile, validations) => {
        return validations?.[activeFile];
      }
    ),
  },
  actions: {
    addNewManifest: () => (dispatch, module) => {
      const fileGUID = uuid();
      const formManifests = module.form.state?.data?.manifests || [];
      const childManifest = {
        name:
          module.form.state?.data?.childManifest ||
          `manifest-${formManifests.length + 1}`,
        type: "child-manifest",
        guid: fileGUID,
      };

      store.dispatch(
        module.form.actions.onChange({
          name: "manifests",
          value: [...formManifests, childManifest],
        })
      );
    },
    deleteNewManifest: (guid) => (dispatch, module) => {
      const formManifests = module.form.state?.data?.manifests;
      const newFormMenifests = formManifests.filter(
        (manifest) => manifest.guid !== guid
      );

      store.dispatch(
        module.form.actions.onChange({
          name: "manifests",
          value: newFormMenifests,
        })
      );
    },
    accessNewManifest: (guid) => (dispatch, module) => {
      dispatch({
        type: module.actions.fileList.onFileSelect,
        fileGuid: guid,
      });

      store.dispatch(module.form.actions.init({ guid }));

      dispatch({
        type: module.actions.editor.switchMode,
        mode: "editor",
      });
    },
    clearNewManifestInput: () => (dispatch, module) => {
      store.dispatch({
        type: "FORM_ON_CHANGE",
        module: `${module.name}-layer-form`,
        name: "childManifest",
        value: "",
      });
    },
  },
}).connect(ManifestList);

const Manifest = createConnector({
  selectors: {
    isEdit: (state) => state.editor?.isEdit,
    isPersisted: createSelector(
      (state) => state.fileList.activeFile,
      (state) => state.fileList.entities,
      (activeFile, entities) => entities[activeFile]?.persisted
    ),
  },
}).connect(({ isEdit, isPersisted }) => {
  const { t } = useTranslation();

  return (
    <>
      <Fields.Input
        data-qa="name"
        name="name"
        label={t("Manifest name")}
        disabled={isEdit && isPersisted}
      />
      <ConnectedManifestList />
    </>
  );
});

export default Manifest;
