import i18next from "i18next";

import { createSelector } from "reselect";
import { VIRTUAL_CLUSTER_FORM_MODULE } from "state/nestedclusters/actions/create";
import {
  profileModule,
  hostClusterMetadataFetcher,
  hostClusterFetcher,
} from "state/nestedclusters/services/create";
import { getClusterGroupEntity } from "state/clustergroups/selectors/details";

import { ClusterProfileSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { formatTags, getQuotaPercentages } from "utils/presenters";
import _ from "lodash";
import { sandboxClusterQuotaUsageFetcher } from "state/devnestedcluster/services";
import { clusterGroupsFetcherWithProjectId } from "state/clustergroups/services/listing";

export const getSelectedProfiles = createSelector(
  (state) => state.forms?.[VIRTUAL_CLUSTER_FORM_MODULE]?.data?.clusterProfiles,
  (profiles) => {
    return profiles;
  }
);

export const getSelectedProfilesMetadata = getEntity(
  (state) => getSelectedProfiles(state),
  [ClusterProfileSchema]
);

export const getProfiles = createSelector(
  (state) => getSelectedProfilesMetadata(state),
  (profiles) => {
    return profiles;
  }
);

export const getVirtualClusterFormError = createSelector(
  (state) => state.forms?.[VIRTUAL_CLUSTER_FORM_MODULE],
  (formState) => {
    if (!formState) {
      return null;
    }

    const { errors } = formState;

    if (!errors.length) {
      return null;
    }

    let message = i18next.t(
      "Please resolve the errors on the page in order to continue"
    );

    return message;
  }
);

export const getClusterEndpointType = createSelector(
  (state) => state.location?.route,
  hostClusterFetcher.selector,
  getClusterGroupEntity,
  (route, { result }, clusterGroup) => {
    if (route === "CLUSTER_GROUPS.CREATE_VIRTUAL_CLUSTER") {
      return clusterGroup?.spec?.clustersConfig?.endpointType;
    }
    return result?.spec?.clusterConfig?.hostClusterConfig?.clusterEndpoint
      ?.type;
  }
);

export const getVirtualClusterPayload = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  () => profileModule.payload,
  getClusterEndpointType,
  (data = {}, profiles, clusterEndpointType) => {
    const loadBalancerConfig = _.pick(data, [
      "externalTrafficPolicy",
      "externalIPs",
      "loadBalancerSourceRanges",
    ]);

    let clusterConfig = {
      hostClusterConfig: {
        clusterGroup: {
          uid: data.clusterGroup,
        },
      },
    };

    if (clusterEndpointType === "LoadBalancer") {
      clusterConfig.hostClusterConfig.config = loadBalancerConfig;
    }

    if (data.clusterGroupUid) {
      clusterConfig.hostClusterConfig.clusterGroup = {
        uid: data.clusterGroupUid,
      };
    }

    return {
      metadata: {
        name: data.name,
        labels: formatTags(data.tags),
        annotations: {
          description: data.description,
        },
      },
      spec: {
        profiles: profileModule.payload,
        cloudConfig: {
          helmRelease: {
            chart: {
              name: null,
              repo: null,
              version: null,
            },
            values: null,
          },
        },
        clusterConfig,
        machinepoolconfig: [
          {
            cloudConfig: {
              instanceType: {
                maxCPU: data.cpuLimits ? parseFloat(data.cpuLimits) : 6,
                maxMemInMiB: data.memoryLimits
                  ? parseFloat(data.memoryLimits) * 1024
                  : 8192,
                maxStorageGiB: data.storageLimits
                  ? parseFloat(data.storageLimits)
                  : 10,
                minCPU: 0,
                minMemInMiB: 0,
              },
            },
          },
        ],
      },
    };
  }
);

export const getSelectedClusterGroup = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data?.clusterGroup,
  clusterGroupsFetcherWithProjectId.selector,
  (clusterGroupUid, { result }) => {
    return (result?.items || []).find((item) => item?.uid === clusterGroupUid);
  }
);

export const getClusterGroup = createSelector(
  (state) => state.location?.route,
  getClusterGroupEntity,
  (route, clusterGroup) => {
    if (route === "CLUSTER_GROUPS.CREATE_VIRTUAL_CLUSTER") {
      return clusterGroup;
    }
  }
);

export const getResourcesRemainingPercentages = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  sandboxClusterQuotaUsageFetcher.selector,
  (formData, { result }) => {
    const { usedCredit, allocatedCredit } = result || {};

    return getQuotaPercentages(formData, usedCredit, allocatedCredit);
  }
);

export const hasSelectedClusterGroup = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  (formData) => {
    return !!formData?.clusterGroupUid;
  }
);

export const isClusterGroupProjectScoped = createSelector(
  (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data,
  clusterGroupsFetcherWithProjectId.selector,
  (formData, { result }) => {
    const clusterGroupUid = formData?.clusterGroupUid;
    const clusterGroups = result?.items || [];
    const clusterGroup = clusterGroups.find(
      (clusterGroup) => clusterGroup?.uid === clusterGroupUid
    );

    return clusterGroup?.scope === "project";
  }
);
