import store from "services/store";
import i18next from "i18next";

import createFormActions from "modules/form/actions";
import Validator from "services/validator";
import { ApplyIf, Missing } from "services/validator/rules";
import api from "services/api";
import notifications from "services/notifications";

import parseCron from "utils/cron";
import {
  DEFAULT_SCHEDULE_OPTIONS,
  EXPIRY_PERIODS,
  FOUR_MONTHS_IN_HOURS,
} from "utils/constants";
import {
  fetchBackupLocationsFetcher,
  fetchBackupNamespacesFetcher,
} from "state/backuplocations/actions";

import { backupStatusesFetcher, fetchBackupStatuses } from "../services";
import { getScheduledBackupPayload } from "../selectors";
import { getRawCluster } from "state/cluster/selectors/details";

const scheduleBackupsValidator = new Validator();
scheduleBackupsValidator.addRule(
  ["location", "backupPrefix", "expiryPeriod"],
  Missing()
);
scheduleBackupsValidator.addRule(
  "occurrence",
  ApplyIf(
    (value, key, data) => data?.schedule === "custom",
    (value) => {
      var { schedules } = parseCron(value);
      const minutes = schedules[0].m;

      const isIntervalTooShort =
        minutes?.length > 1 &&
        minutes.find((min, index) => minutes[index + 1] - min < 30);

      if (!minutes || isIntervalTooShort) {
        return i18next.t(
          "Backup schedule operation requires a minimum interval of 30 minutes"
        );
      }
      return false;
    }
  )
);

export const scheduleBackupsFormAction = createFormActions({
  init: async () => {
    let data;
    await store.dispatch(fetchBackupLocationsFetcher.fetch());
    await store.dispatch(fetchBackupNamespacesFetcher.fetch());
    if (store.getState().location?.params?.id) {
      data = await store.dispatch(fetchBackupStatuses());
    }
    const namespaces =
      fetchBackupNamespacesFetcher.selector(store.getState())?.result?.items ||
      [];
    const config = data?.spec?.config || {};
    const scheduledRunTime = config.schedule?.scheduledRunTime;
    const isCustomSchedule = !DEFAULT_SCHEDULE_OPTIONS.find(
      (option) => option.value === scheduledRunTime
    );
    const expiryPeriod =
      EXPIRY_PERIODS.find((period) => period.value === config.durationInHours)
        ?.value || "custom";

    const getPeriodOption = () => {
      if (config.durationInHours) {
        return expiryPeriod;
      }
      return EXPIRY_PERIODS[0].value;
    };

    const getCustomHours = () => {
      if (config.durationInHours && expiryPeriod === "custom") {
        return config.durationInHours;
      }
      return FOUR_MONTHS_IN_HOURS;
    };

    const getScheduleOption = () => {
      if (!scheduledRunTime) {
        return "never";
      }
      if (isCustomSchedule) {
        return "custom";
      }
      return scheduledRunTime;
    };

    return Promise.resolve({
      location: config.backupLocationUid || null,
      schedule: getScheduleOption(),
      occurrence: isCustomSchedule ? scheduledRunTime : undefined,
      backupPrefix: config.backupPrefix || "",
      includeAllDisks: config.includeAllDisks,
      includeClusterResources: config.includeClusterResources,
      backupedNamespaces: config.namespaces?.length
        ? config.namespaces
        : namespaces,
      expiryPeriod: getPeriodOption(),
      expiryHours: getCustomHours(),
    });
  },
  validator: scheduleBackupsValidator,
  submit: async (data) => {
    const payload = getScheduledBackupPayload(data);
    const clusterUid = store.getState().location.params.id;
    const backupUid = backupStatusesFetcher.selector(store.getState())?.result
      ?.metadata?.uid;

    let promise;
    if (backupUid) {
      promise = api.put(
        `v1/spectroclusters/${clusterUid}/features/backup`,
        payload
      );
    } else {
      promise = api.post(
        `v1/spectroclusters/${clusterUid}/features/backup`,
        payload
      );
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: backupUid
          ? i18next.t("Something went wrong while editing the backup schedule")
          : i18next.t(
              "Something went wrong while creating the backup schedule"
            ),
        description: error.message,
      });
      return Promise.reject();
    }
    if (!backupUid) {
      notifications.success({
        message: i18next.t("Backup schedule was created successfully"),
      });
    }
  },
});
