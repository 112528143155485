import i18next from "i18next";
import createFormActions from "modules/form/actions";
import api from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import { getCurrentUser } from "state/auth/selectors";

export const cloudRatesFormActions = createFormActions({
  async init() {
    try {
      const user = getCurrentUser(store.getState());
      return await api.get(
        `v1/tenants/${user.status.tenant.tenantUid}/rateConfig`
      );
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the cloud rates configuration"
        ),
        description: err.message,
      });
    }
  },
  async submit(data) {
    const user = getCurrentUser(store.getState());
    const publicClouds = ["aws", "azure", "gcp"].reduce((accumulator, type) => {
      return {
        ...accumulator,
        [type]: {
          ...data[type],
          computeOptimized: {
            ...data[type].computeOptimized,
            memoryRateProportion:
              100 - data[type].computeOptimized.computeRateProportion,
          },
          memoryOptimized: {
            ...data[type].computeOptimized,
            memoryRateProportion:
              100 - data[type].memoryOptimized.computeRateProportion,
          },
        },
      };
    }, {});

    const privateClouds = ["vsphere", "maas", "openstack", "generic"].reduce(
      (accumulator, type) => {
        const payload = {
          cpuUnitPricePerHour: parseFloat(data[type].cpuUnitPricePerHour),
          gpuUnitPricePerHour: parseFloat(data[type].gpuUnitPricePerHour),
          memoryUnitPriceGiBPerHour: parseFloat(
            data[type].memoryUnitPriceGiBPerHour
          ),
          storageUnitPriceGiBPerHour: parseFloat(
            data[type].storageUnitPriceGiBPerHour
          ),
        };

        return {
          ...accumulator,
          [type]: payload,
        };
      },
      {}
    );

    try {
      await api.put(`v1/tenants/${user.status.tenant.tenantUid}/rateConfig`, {
        ...data,
        ...publicClouds,
        ...privateClouds,
      });
      notifications.success({
        message: i18next.t("Successfully saved the cloud rates configuration"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while saving the cloud rates configuration"
        ),
        description: err.message,
      });
    }
  },
});
