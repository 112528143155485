import api from "services/api";

import ModalService from "services/modal";
import ProfileStackModule from "modules/profileStack";
import dataFetcher from "modules/dataFetcher";
import { ClusterSchema } from "utils/schemas";
import store from "services/store";
import { VIRTUAL_CLUSTER_FORM_MODULE } from "../actions/create";

export const cancelCreateVirtualClusterModal = new ModalService();
export const revertPackValuesConfirm = new ModalService();

export const profileModule = new ProfileStackModule({
  name: "virtualCluster",
});

export const hostClusterMetadataFetcher = dataFetcher({
  selectors: ["hostCluster"],
  schema: [ClusterSchema],
  async fetchData() {
    const response = await api.get(
      "v1/dashboard/spectroclusters/metadata?quickFilter=strictHostclusters"
    );
    return response?.items || [];
  },
});

export const hostClusterFetcher = dataFetcher({
  selectors: [
    "nesteclusters",
    "hostCluster",
    (state) => state.forms[VIRTUAL_CLUSTER_FORM_MODULE]?.data?.hostCluster,
  ],
  fetchData([_1, _2, clusterUid]) {
    return api.get(`v1/spectroclusters/${clusterUid}`);
  },
});

export const virtualYamlConfigValuesFetcher = dataFetcher({
  selectors: ["virtual-clusters", "yaml-config"],
  fetchData() {
    return api.get("v1/spectroclusters/virtual/values");
  },
});

export async function getVirtualYamlConfig() {
  let config = virtualYamlConfigValuesFetcher.selector(store.getState()).result;
  if (!config) {
    await store.dispatch(virtualYamlConfigValuesFetcher.fetch());
  }

  return virtualYamlConfigValuesFetcher.selector(store.getState()).result;
}
