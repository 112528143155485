import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import PageHeader from "components/common/layouts/App/PageHeader";
import Switch from "components/common/Switch";
import Button from "components/ui/Button";

import UsersList from "../users/index";
import TeamsList from "../teams/components/list";

import { openUserModal } from "state/users/actions";
import { openTeamFormModal } from "state/teams/actions";
import Icon from "components/ui/Icon";
import { pageWidthConstrain } from "components/styled/mixins";
import Link from "components/common/History/Link";
import DetailPageTabs from "components/common/Tabs/DetailPageTabs";
import { useParams } from "react-router";

const ListingWrap = styled.div`
  flex-grow: 1;

  ${pageWidthConstrain}
`;

const ManagementLayout = ({ match, openUserModal, openTeamFormModal }) => {
  const { t } = useTranslation();
  const { tab } = useParams();
  const tabs = [
    {
      href: "/management/users",
      label: t("Users"),
      key: "users",
    },
    {
      href: "/management/teams",
      label: t("Teams"),
      key: "teams",
    },
  ];

  return (
    <>
      <PageHeader
        tabTitleContent={{
          activeTab: tabs.find((t) => t.key === tab)?.label,
          pageTitle: t("Users and Teams Management"),
        }}
        breadcrumbs={[{ label: t("Users and Teams Management") }]}
        withBack={false}
      />
      <DetailPageTabs.After>
        <Switch value={tab}>
          <Switch.Case value={"users"}>
            <Link to="/management/users/create" onClick={openUserModal}>
              <Button data-qa="create_user">
                <Icon type="plus" /> {t("Create User")}
              </Button>
            </Link>
          </Switch.Case>
        </Switch>
        <Switch value={tab}>
          <Switch.Case value={"teams"}>
            <Link
              to="/management/teams/create"
              onClick={() => openTeamFormModal("create")}
            >
              <Button data-qa="create_team">
                <Icon type="plus" /> {t("Create team")}
              </Button>
            </Link>
          </Switch.Case>
        </Switch>
      </DetailPageTabs.After>

      <DetailPageTabs data-qa="clusters-tabs" tabs={tabs} activeKey={tab}>
        <Switch value={tab}>
          <Switch.Case value={"users"}>
            <ListingWrap>
              <UsersList />
            </ListingWrap>
          </Switch.Case>
          <Switch.Case value={"teams"}>
            <ListingWrap>
              <TeamsList />
            </ListingWrap>
          </Switch.Case>
        </Switch>
      </DetailPageTabs>
    </>
  );
};

export default connect((state) => ({}), { openUserModal, openTeamFormModal })(
  ManagementLayout
);
