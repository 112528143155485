import { useEffect } from "react";

const loadScript = () => {
  if (document.getElementById("atlassian-widget")) {
    return;
  }

  let script = document.createElement("script");

  script.setAttribute("data-jsd-embedded", "true");
  script.setAttribute("data-key", "887f4568-3957-418f-a965-3234f1f447fc");
  script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com");
  script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
  script.id = "atlassian-widget";
  script.onload = () => {
    let DOMContentLoaded_event = document.createEvent("Event");
    DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
    window.document.dispatchEvent(DOMContentLoaded_event);
  };

  document.body.appendChild(script);
};

export default function JSDWidget() {
  useEffect(() => {
    loadScript();
    let iframe = document.getElementById("jsd-widget");

    if (iframe) {
      iframe.classList.remove("hidden");
    }

    return function () {
      iframe = document.getElementById("jsd-widget");
      if (iframe) {
        iframe.classList.add("hidden");
      }
    };
  }, []);

  return null;
}
