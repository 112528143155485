import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { keyedDataFetcher } from "modules/dataFetcher";
import createFormActions from "modules/form/actions";
import fileListConnector from "modules/profileIDE/connectors/fileList";
import { applicationProfileEditor } from "modules/profileIDE/flows/application-edit";
import { operatorsFetcher } from "modules/profileIDE/state/operators";
import api from "services/api";
import history from "services/history";
import notifications from "services/notifications";
import store, { getStoreEntity } from "services/store";
import Validator from "services/validator";
import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import { AppProfileSchema, PackSchema } from "utils/schemas";
import {
  appProfileDetailFetcher,
  createNewVersionModal,
  deleteModalService,
  settingsModalService,
} from "../services/details";
import { v4 as uuid } from "uuid";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import LayerSVG from "assets/icons/app_layer.svg?component";
import { PermissionService } from "services/permissions";
import { Missing, SemanticVersion } from "services/validator/rules";
import { generatePath } from "react-router";
import { APP_PROFILES } from "utils/constants/routes";

import { containersFetcher } from "modules/profileIDE/state/containers";
import { messagingSystemsFetcher } from "modules/profileIDE/state/messagingSystems";
import { storageOptionsFetcher } from "modules/profileIDE/state/storage";
import { extractInstallOrder } from "utils/yaml";
import { securityOptionsFetcher } from "modules/profileIDE/state/security";
export const APP_PROFILE_NEW_VERSION_MODULE = "app-profile-version";

export const detailPageFetcher = keyedDataFetcher({
  selectors: ["appProfile", "pageFetcher"],
  async fetchData([_1, _2, uid]) {
    await store.dispatch(appProfileDetailFetcher.fetch());
    let profile = appProfileDetailFetcher.selector(store.getState()).result;
    await store.dispatch({
      type: "FETCH_APP_PROFILE_TIERS",
      promise: api.get(`v1/appProfiles/${uid}/tiers`).then((response) => {
        let appTiers = [...response.spec.appTiers].reverse();
        const hasInstallOrder = appTiers.every(
          (tier) => !!parseInt(extractInstallOrder({ ...tier.spec }))
        );

        if (hasInstallOrder) {
          appTiers = [...response.spec.appTiers].sort((a, b) => {
            const installOrderA = parseInt(extractInstallOrder({ ...a.spec }));
            const installOrderB = parseInt(extractInstallOrder({ ...b.spec }));
            return installOrderB - installOrderA;
          });
        }

        return {
          ...response,
          metadata: profile.metadata,
          spec: {
            ...response.spec,
            appTiers,
          },
        };
      }),
      schema: AppProfileSchema,
    });

    profile = appProfileDetailFetcher.selector(store.getState()).result;

    const packFetchers = profile.spec.appTiers.map((tier) => {
      const packUid = tier.spec.sourceAppTierUid;

      if (tier.type === "manifest") {
        return null;
      }

      if (!tier.spec?.sourceAppTierUid) {
        return null;
      }

      return store.dispatch({
        type: `FETCH_TIER_PACK`,
        packUid,
        promise: api
          .get(`v1/packs/${tier.spec.sourceAppTierUid}`)
          .then((response) => {
            const latestPackVersion = (response?.tags || []).find(
              (pack) => !pack.parentTags
            );

            const latestPackValues =
              response.packValues.find(
                (packValue) => packValue.packUid === latestPackVersion?.packUid
              ) || response.packValues[0];

            const packOutputParameters = [
              ...(latestPackValues?.template?.parameters?.outputParameters ||
                []),
            ];
            const packInputParameters = [
              ...(latestPackValues?.template?.parameters?.inputParameters ||
                []),
            ];

            return {
              ...response,
              metadata: { name: packUid },
              parameters: {
                inputParameters: [...(tier?.spec?.properties || [])].map(
                  (property) => {
                    const parameter = packInputParameters.find(
                      (parameter) => parameter.name === property.name
                    );

                    return {
                      ...parameter,
                      ...property,
                      displayName: parameter?.displayName || property.name,
                    };
                  }
                ),
                outputParameters: packOutputParameters,
              },
            };
          }),
        schema: PackSchema,
      });
    });

    await Promise.allSettled(packFetchers);
    profile = getStoreEntity(profile.guid, AppProfileSchema);
    const entities = profile.spec.appTiers.reduce((accumulator, tier) => {
      const isOperatorInstance = tier.type === "operator-instance";
      const packName =
        tier.spec.pack?.name ||
        (tier.spec?.properties || []).find(
          (property) => property.name === "helmChartName"
        )?.value;
      accumulator[tier.guid] = {
        ...tier,
        ...tier.metadata,
        ...tier.spec,
        parameters: tier.spec.pack?.parameters || [],
        packName: packName,
        packVersion: tier.version,
        subType: isOperatorInstance ? packName : "",
        manifests: !isOperatorInstance
          ? (tier.spec?.manifests || []).map((manifest) => {
              const guid = uuid();
              return {
                ...manifest,
                guid,
                type: "child-manifest",
                persisted: true,
              };
            })
          : [],
      };
      return accumulator;
    }, {});
    const files = profile.spec.appTiers.map((tier) => tier.guid);

    const childrenMap = profile.spec.appTiers.reduce((acc, tier) => {
      acc[tier.guid] = entities[tier.guid].manifests.map((manifest) => {
        entities[manifest.guid] = { ...manifest };
        return manifest.guid;
      });

      return acc;
    }, {});

    const permissions = new PermissionService(
      profile?.metadata?.annotations?.permissions?.split(",")
    );
    const isReadOnly = !permissions.has("appProfile.update");

    applicationProfileEditor.initialize({
      fileList: {
        files,
        entities,
        childrenMap,
        metadata: profile.metadata,
      },
      drafts: entities,
      toolbar: {
        activeSelection: "overview",
        tabs: [
          {
            id: "overview",
            icon: { component: LayerSVG },
            onClick: () => {},
            beforeActions: true,
            light: true,
          },
          {
            id: "edit",
            icon: { awesome: faPenToSquare },
            onClick: () => {},
            beforeActions: true,
            light: true,
          },
          !isReadOnly && {
            id: "add",
            icon: { awesome: faPlusCircle },
            onClick: () => {
              store.dispatch(containersFetcher.fetch());
              store.dispatch(operatorsFetcher.fetch());
              store.dispatch(messagingSystemsFetcher.fetch());
              store.dispatch(storageOptionsFetcher.fetch());
              store.dispatch(securityOptionsFetcher.fetch());
              store.dispatch(
                applicationProfileEditor.form.actions.validateForm()
              );
            },
          },
        ].filter(Boolean),
      },
      editor: {
        isEdit: true,
      },
    });

    return { entities, files };
  },
});

export function initializeAppDetailPage({ uid }) {
  return async function (dispatch, getState) {
    dispatch(detailPageFetcher.key(uid).flushCache());
    await dispatch(detailPageFetcher.key(uid).fetch());
    const { files } = detailPageFetcher.key(uid).selector(getState()).result;

    await store.dispatch(
      applicationProfileEditor.form.actions.init({ guid: files[0] })
    );

    applicationProfileEditor.dispatch(
      fileListConnector.actions.onSelect(files)
    );
  };
}

export function openSettings(tab = "basic-info") {
  return function thunk() {
    settingsModalService.open({ tab });
  };
}

const deleteValidator = new Validator();
deleteValidator.addRule(["name"], Missing());
export const deleteActions = createFormActions({
  validator: deleteValidator,
  async init() {
    return {
      name: "",
    };
  },
  async submit() {
    const profile = appProfileDetailFetcher.selector(store.getState()).result;
    const promise = api.delete(`v1/appProfiles/${profile.metadata.uid}`);

    try {
      await promise;
      deleteModalService.close();
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
    }
    return promise;
  },
});

export function onApplicationProfileDelete() {
  return function thunk(dispatch, getState) {
    deleteModalService.open().then(async () => {
      await dispatch(deleteActions.submit({ module: "delete-app-profile" }));
      settingsModalService.close();

      history.push("/app-profiles");
      const profile = appProfileDetailFetcher.selector(getState()).result;
      notifications.success({
        message: `App profile "${profile.metadata.name}" has been deleted successfully`,
      });
    });
  };
}

export const appProfileEditMetadataFormActions = createFormActions({
  async init() {
    let profile = appProfileDetailFetcher.selector(store.getState()).result;
    return {
      name: profile.metadata.name || "",
      uid: profile.metadata.uid,
      description: profile.metadata?.annotations?.description || "",
      tags: parseTagsForInput(profile.metadata?.labels || {}),
    };
  },
  async submit(data) {
    const promise = api.patch(`v1/appProfiles/${data.uid}/metadata`, {
      metadata: {
        name: data.name,
        annotations: {
          description: data.description,
        },
        labels: formatTags(data.tags),
      },
    });

    try {
      await promise;
      notifications.success({
        message: `App profile "${data.name}" has been updated successfully`,
      });
    } catch (error) {
      notifications.error({
        message: i18next.t("Failed to edit App profile metadata"),
        description: error.message,
      });
      return promise;
    }

    await store.dispatch(detailPageFetcher.key(data.uid).flushCache());
    await store.dispatch(detailPageFetcher.key(data.uid).fetch());
  },
});

export function onDeployAppProfile(uid) {
  return function thunk() {
    history.push(`/deployments/${uid}/new`);
  };
}

const createVersionValidator = new Validator();
createVersionValidator.addRule(["name", "version"], Missing());
createVersionValidator.addRule(["version"], SemanticVersion());

export const versionFormActions = createFormActions({
  validator: createVersionValidator,
  init() {
    let profile = appProfileDetailFetcher.selector(store.getState()).result;

    return Promise.resolve({
      name: profile?.metadata?.name,
      version: "",
    });
  },
  async submit(data) {
    let profile = appProfileDetailFetcher.selector(store.getState()).result;

    const promise = api.post(`v1/appProfiles/${profile?.metadata?.uid}/clone`, {
      metadata: {
        name: data.name,
        version: data.version,
      },
    });
    let response;

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to clone this profile"
        ),
        description: error.message,
      });

      return Promise.reject();
    }

    if (response?.uid) {
      notifications.success({
        message: i18next.t(
          "A new profile version has been created successfully"
        ),
      });

      history.push(
        generatePath(APP_PROFILES.DETAILS, {
          uid: response.uid,
        })
      );
    }
  },
});

export function onVersionChange({ version, uid }) {
  return (dispatch, getState) => {
    let profile = appProfileDetailFetcher.selector(getState()).result;
    if (version === profile.spec.version) {
      return;
    }

    dispatch({
      type: "UPDATE_SELECTED_VERSION",
      version,
    });

    history.push(
      generatePath(APP_PROFILES.DETAILS, {
        uid,
      })
    );
  };
}

export function openNewVersionModal() {
  return (dispatch) => {
    dispatch(
      versionFormActions.init({ module: APP_PROFILE_NEW_VERSION_MODULE })
    );

    createNewVersionModal.open().then(() =>
      dispatch(
        versionFormActions.submit({
          module: APP_PROFILE_NEW_VERSION_MODULE,
        })
      )
    );
  };
}
