import i18next from "i18next";

import api from "services/api/index";
import { generatePath } from "react-router";

import { ClusterProfileSchema } from "utils/schemas";
import { pollClusterProfileNotifications } from "utils/tasks";
import { FLAGS } from "utils/constants/flags";
import { CLUSTER_PROFILES, CLUSTERS } from "utils/constants/routes";

import {
  getRawClusterProfile,
  getUpdateNotification,
  getUpdateNotificationPacks,
} from "state/clusterprofile/selectors/details";

import { clusterProfileFormActions } from "./layerConfig";

import store from "services/store";
import createFormActions from "modules/form/actions";
import ModalService from "services/modal";
import notificationService from "services/notifications";
import flags from "services/flags";
import Validator from "services/validator";
import { Missing, SemanticVersion } from "services/validator/rules";
import history from "services/history";
import {
  updatesFormAction,
  createNewVersionModal,
  selectClusterModal,
  clustersFetcher,
} from "../services";

export const VERSION_FORM_MODULE = "clusterProfileVersion";
export const SELECT_CLUSTER_FORM_MODULE = "selectCluster";

export function fetchClusterProfile(
  uid,
  type = "FETCH_CLUSTER_PROFILE_DETAILS"
) {
  return function thunk(dispatch) {
    const promise = api.get(`v1/clusterprofiles/${uid}`);

    dispatch({
      type,
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export function getClusterProfileByUid(uid) {
  return async function thunk(dispatch) {
    const response = await dispatch(fetchClusterProfile(uid));
    dispatch({
      type: "UPDATE_SELECTED_VERSION",
      version: response?.spec?.version,
    });
    pollClusterProfileNotifications.start();
  };
}

export function fetchClusterProfileNotifications() {
  return async (dispatch, getState) => {
    const clusterprofile = getRawClusterProfile(getState());

    if (!clusterprofile?.metadata.uid) {
      return;
    }

    if (!flags.has([FLAGS.NOTIFICATIONS])) {
      return;
    }

    await dispatch({
      type: "FETCH_CLUSTER_PROFILE_NOTIFICATIONS",
      promise: api
        .get(
          `v1/notifications/clusterprofile/${clusterprofile?.metadata?.uid}?isDone=false`
        )
        .then((res) => {
          return {
            metadata: clusterprofile.metadata,
            notifications: res.items,
          };
        }),
      schema: ClusterProfileSchema,
    });
  };
}

export const clusterProfileUpdateConfirm = new ModalService(
  "clusterProfileUpdate"
);

export function notificationSelectLayer(layer) {
  return function thunk(dispatch) {
    dispatch({
      type: "NOTIFICATION_SELECT_LAYER",
      layer,
    });
  };
}

export function confirmClusterProfileUpdate() {
  return async function thunk(dispatch, getState) {
    const state = getState();
    const clusterprofile = getRawClusterProfile(state);
    const allNotifications = getUpdateNotification(state);

    if (allNotifications.length === 0) {
      return;
    }

    const actionableNotification = allNotifications.find(
      (value) => value.action.isInfo === false
    );
    const infoNotification = allNotifications.find(
      (value) => value.action.isInfo === true
    );
    clusterProfileUpdateConfirm.open().then(
      async () => {
        try {
          if (infoNotification) {
            await api.patch(`v1/${infoNotification.action.link}`);
          }
          if (actionableNotification) {
            const formData =
              getState().forms["profile-update-notification"].data;
            const packs = getUpdateNotificationPacks(getState());
            const payload = {
              packs: packs
                .filter((pack) => !pack.isDisabled)
                .map((pack) => ({
                  name: pack.metadata.name,
                  values: formData[pack.guid],
                })),
            };
            await api.patch(
              `v1/${actionableNotification.action.link}`,
              payload
            );
          }

          dispatch(fetchClusterProfileNotifications());
          await dispatch(
            fetchClusterProfile(
              clusterprofile.metadata.uid,
              "REFRESH_CLUSTER_PROFILE_DETAILS"
            )
          );
          dispatch(
            clusterProfileFormActions.init({ module: "clusterprofile" })
          );
          pollClusterProfileNotifications.start();
        } catch (err) {
          notificationService.error({
            message: i18next.t(
              "Something went wrong when trying to confirm this notification"
            ),
            description: err.message,
          });

          return Promise.reject();
        }
      },
      async () => {
        if (infoNotification) {
          await api.patch(
            `v1/notifications/${infoNotification.metadata.uid}/ack`
          );
        }
        if (actionableNotification) {
          await api.patch(
            `v1/notifications/${actionableNotification.metadata.uid}/ack`
          );
        }
      }
    );

    await dispatch(
      updatesFormAction.init({ module: "profile-update-notification" })
    );

    const selectablePack = getUpdateNotificationPacks(getState()).find(
      (pack) => !pack.isDisabled
    );
    if (selectablePack) {
      dispatch(notificationSelectLayer(selectablePack));
    }
  };
}

export function fetchResolvedValues(uid) {
  return function (dispatch) {
    const promise = api.get(`v1/clusterprofiles/${uid}/packs/resolvedValues`);

    dispatch({
      type: "FETCH_RESOLVED_VALUES",
      promise: promise.then(({ resolved = {} } = {}) => ({
        metadata: { uid },
        status: {
          resolved,
        },
      })),
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

const createVersionValidator = new Validator();
createVersionValidator.addRule(["name", "version"], Missing());
createVersionValidator.addRule(["version"], SemanticVersion());

export async function validateClusterProfileNameAndVersion({
  name,
  version,
} = {}) {
  const promise = api.get(
    `v1/clusterprofiles/validate/name?name=${name}&version=${version}`
  );

  try {
    await promise;
  } catch (error) {
    return error.message;
  }
}

export const clusterProfileVersionFormActions = createFormActions({
  validator: createVersionValidator,
  init() {
    const clusterProfile = getRawClusterProfile(store.getState());

    return Promise.resolve({
      name: clusterProfile?.metadata?.name,
      version: "",
    });
  },
  async submit(data) {
    const clusterProfile = getRawClusterProfile(store.getState());

    const error = await validateClusterProfileNameAndVersion(data);
    if (error) {
      notificationService.error({
        message: i18next.t("Something went wrong"),
        description: error,
      });
      return Promise.reject();
    }

    const promise = api.post(
      `v1/clusterprofiles/${clusterProfile?.metadata?.uid}/clone`,
      {
        metadata: {
          name: data.name,
          version: data.version,
        },
      }
    );
    let response;

    try {
      response = await promise;
    } catch (error) {
      notificationService.error({
        message: i18next.t(
          "Something went wrong when trying to clone this cluster profile"
        ),
        description: error.message,
      });

      return Promise.reject();
    }

    if (response?.uid) {
      const { tab } = store.getState().location.params || {};

      notificationService.success({
        message: i18next.t(
          "A new cluster profile version has been created successfully"
        ),
      });

      history.push(
        generatePath(CLUSTER_PROFILES.DETAILS, {
          tab,
          id: response.uid,
        })
      );
    }
  },
});

export function onVersionChange({ version, uid }) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedVersion = state.clusterprofile?.details?.selectedVersion;
    const { tab } = state.location?.params || {};

    if (version === selectedVersion) {
      return;
    }

    dispatch({
      type: "UPDATE_SELECTED_VERSION",
      version,
    });

    history.push(
      generatePath(CLUSTER_PROFILES.DETAILS, {
        tab,
        id: uid,
      })
    );
  };
}

export function openNewVersionModal() {
  return (dispatch) => {
    dispatch(
      clusterProfileVersionFormActions.init({ module: VERSION_FORM_MODULE })
    );

    createNewVersionModal.open().then(() =>
      dispatch(
        clusterProfileVersionFormActions.submit({
          module: VERSION_FORM_MODULE,
        })
      )
    );
  };
}

const selectClusterFormValidator = new Validator();
selectClusterFormValidator.addRule("clusterUid", Missing());

export const selectClusterFormActions = createFormActions({
  validator: selectClusterFormValidator,
  async init() {
    await store.dispatch(clustersFetcher.fetch());
    return Promise.resolve({
      clusterUid: "",
    });
  },
  async submit(data) {
    const profile = getRawClusterProfile(store.getState());
    const redirectPath = generatePath(CLUSTERS.TAB_DETAILS, {
      tab: "configure",
      id: data?.clusterUid,
      clusterCategory: "clusters",
    });
    history.push(`${redirectPath}?attachedProfile=${profile?.metadata?.uid}`);
  },
});

export function onAddOnProfileAttach(profileUid) {
  return (dispatch) => {
    dispatch(
      selectClusterFormActions.init({ module: SELECT_CLUSTER_FORM_MODULE })
    );

    selectClusterModal.open().then(() =>
      dispatch(
        selectClusterFormActions.submit({
          module: SELECT_CLUSTER_FORM_MODULE,
        })
      )
    );
  };
}
