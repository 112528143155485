import dataFetcher from "modules/dataFetcher";
import api from "services/api";

export default function editorReducer() {
  return {
    name: "editor",
    initialState: {
      mode: "form",
    },
    switchMode(nextState, { mode }) {
      nextState.mode = mode;
    },
  };
}
export const appProfileMacrosFetcher = dataFetcher({
  selectors: ["appProfiles", "macros"],
  fetchData: async () => {
    const data = await api.get(`v1/appProfiles/macros`);

    return (data?.macros || []).map((macro) => macro.name);
  },
});

export const packsUrlFetcher = dataFetcher({
  selectors: ["appProfiles", "packs"],
  fetchData: async () => {
    const data = await api.get(`v1/registries/pack/summary`);

    return data?.items?.reduce((accumulator, item) => {
      const packUid = item?.metadata?.uid;
      const packEndpoint = item?.spec?.endpoint;
      accumulator[packUid] = packEndpoint;
      return accumulator;
    }, {});
  },
});
