export const FLAGS = {
  ALL: "all",
  BETA: "beta",
  BUG_REPORTER: "bug-reporter",

  AZURE: "azure",
  GCP: "gcp",
  AKS: "aks",
  GKE: "gke",
  OPENSTACK: "openstack",
  MAAS: "maas",
  EDGE_BAREMETAL: "edge",
  EDGE_NATIVE: "edge-native",
  TKE: "tke",
  TENCENT: "tencent",
  COXEDGE: "coxedge",

  AUDIT: "audit",
  SAML: "saml",
  BILLING: "billing",
  BROWNFIELD: "brownfield",
  ALERTS: "alerts",
  NOTIFICATIONS: "notifications",
  SCANS: "scans",
  BACKUP: "backup",
  OIDC: "oidc",
  PARALLEL_WORKERS: "parallel-workers",
  GENERIC_IMPORT: "genericimport",
  BACKUP_NEW_LOCATION: "backup-new-locations",
  ENABLE_ONPREM_PUBLIC_CLOUDS: "enable-onprem-public-clouds",
  WORKSPACES: "workspaces",
  SYSTEM_PROFILES: "system-profiles",
  UBUNTU_ADVANTAGE: "ubuntu-advantage",
  MULTI_APPLIANCE_SUPPORT: "multi-appliance-support",
  FREEMIUM: "freemium",
  CONTAINER_IMAGES: "container-images",
  EDGE_LIBVIRT: "edge-libvirt", // enabled by demo flags
  ENABLE_REPORT_DOWNLOAD: "enable-report-download", // do not release this one
  // add new features under this line
  DEVX: "devx",
};

export const RELEASED_FLAGS = [
  FLAGS.BETA,
  FLAGS.AZURE,
  FLAGS.GCP,
  FLAGS.AUDIT,
  FLAGS.NOTIFICATIONS,
  FLAGS.SAML,
  FLAGS.BILLING,
  FLAGS.BROWNFIELD,
  FLAGS.BACKUP,
  FLAGS.SCANS,
  FLAGS.OPENSTACK,
  FLAGS.AKS,
  FLAGS.OIDC,
  FLAGS.MAAS,
  FLAGS.ALERTS,
  FLAGS.ENABLE_ONPREM_PUBLIC_CLOUDS,
  FLAGS.WORKSPACES,
  FLAGS.GENERIC_IMPORT,
  FLAGS.EDGE_BAREMETAL,
  FLAGS.SYSTEM_PROFILES,
  FLAGS.BACKUP_NEW_LOCATION,
  FLAGS.TKE,
  FLAGS.UBUNTU_ADVANTAGE,
  FLAGS.CONTAINER_IMAGES,
  FLAGS.MULTI_APPLIANCE_SUPPORT,
  FLAGS.DEVX,
  FLAGS.COXEDGE,
  FLAGS.GKE,
];
