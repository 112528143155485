import React from "react";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";

import AppLayout from "components/common/layouts/App";

import theme from "components/theme";
import Loader from "components/ui/Loader";
import history from "services/history";
import store from "services/store";

import Pages from "./pages";
import PageNotFound from "./pages/404";
import ErrorPage from "./pages/error";
import AuthPages from "./pages/auth/main";
import JSDWidget from "components/common/JSDWidget";

import {
  getCurrentContext,
  getUserProjectsSorted,
  getCurrentUser,
  getBackToProject,
  getUserMenu,
  isSecurityModeFips,
} from "state/auth/selectors";
import { selectProject, logout, toggleDevMode } from "state/auth/actions";
import { openProductTourModal } from "state/productTour/actions";
import { getFullName, getContractAcceptance } from "utils/presenters";
import { getEntity } from "utils/entities";
import { UserSchema } from "utils/schemas";
import NoProjectsPage from "pages/no-projects";
import ErrorBoundary from "components/ErrorBoundary";
import ConfirmationModal from "components/common/ConfirmationModal";
import { changePageConfirm } from "utils/changePageConfirm";
import { AUTH } from "utils/constants/routes";
import MenuProvider from "components/common/providers/Menu";
import ContractAcceptance from "./pages/auth/flows/ContractAcceptance";
import IntercomWidget from "components/common/IntercomWidget";
import { DemoFlags } from "components/ui/Flags";

const ConnectedAppLayout = connect(
  (state) => ({
    currentContext: getCurrentContext(state),
    backToProject: getBackToProject(state),
    projects: getUserProjectsSorted(state),
    user: getCurrentUser(state),
    username: getFullName(
      getEntity((state) => state.auth.me, UserSchema)(state)
    ),
    userMenuItems: getUserMenu(state),
    isDevMode: state.auth.devMode,
    isSecurityModeFips: isSecurityModeFips(state),
  }),
  {
    selectProject,
    onLogout: logout,
    openProductTourModal,
    toggleDevMode,
  }
)(AppLayout);

export function AppEnvironment({ children }) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <React.Suspense fallback={<Loader />}>
            <MenuProvider>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </MenuProvider>
          </React.Suspense>
        </ErrorBoundary>
      </ConnectedRouter>

      <DemoFlags has={"intercom"}>
        <IntercomWidget />
      </DemoFlags>
      <JSDWidget />
    </Provider>
  );
}

function AppContent(props) {
  if (props.isLoading) {
    return <Loader />;
  }

  if (props.isContractAccepted === false) {
    return <ContractAcceptance />;
  }

  return (
    <React.Suspense fallback={<Loader />}>
      <Pages />
      <ConfirmationModal type="warning" service={changePageConfirm}>
        <ConfirmationModal.Question>
          {props.changePageMessage}
        </ConfirmationModal.Question>
      </ConfirmationModal>
    </React.Suspense>
  );
}

const ConnectedAppContent = connect((state) => ({
  changePageMessage: changePageConfirm.data?.message,
  isLoading: state.auth.isLoading,
  isContractAccepted: getContractAcceptance(getCurrentUser(state)),
}))(AppContent);

const ConnectedAuthPages = connect((state) => ({
  isLoading: state.auth.isLoading,
}))(AuthPages);

function App() {
  return (
    <AppEnvironment>
      <Route path={AUTH.ERROR} exact component={ErrorPage} />
      <Route path={AUTH.NOT_FOUND} exact component={PageNotFound} />
      <Route path={AUTH.NO_PROJECTS} exact component={NoProjectsPage} />
      <ConnectedAuthPages />
      <ConnectedAppLayout>
        <ConnectedAppContent />
      </ConnectedAppLayout>
    </AppEnvironment>
  );
}

export default App;
