import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Tooltip, Radio, Menu } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { createAsyncActionComponent } from "modules/asyncAction";
import Tags from "components/common/Tags";
import ActionMenu from "components/common/ActionMenu";
import allPermissions, { PermissionService } from "services/permissions";

import { getEnvironments, LAYER_TYPES } from "utils/constants";
import { SCOPE_TAGS, PROFILE_TYPE_TAGS } from "utils/constants/clusterprofile";
import * as colors from "utils/constants/colors";
import Icon from "components/ui/Icon";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { SVGLayerIcon } from "components/common/ClusterProfilePreview/LayerIcon";
import { deleteProfileAsyncAction } from "state/clusterprofile/actions/listing";
import { useSelector } from "react-redux";

const Wrap = styled.div`
  height: 238px;
  width: 288px;
  overflow: hidden;
  background: #fafafa;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${(props) =>
    props.highlight &&
    css`
      box-shadow: 0px 0px 2px ${colors.blue}, 0px 2px 0px ${colors.blue};
    `}
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.nearBlack};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  flex-grow: 1;
`;

export const HeaderTag = styled.div`
  color: #0d1440;
  border-radius: 13px;
  max-height: 18px;
  padding: 4px 12px;
  display: ${(props) => (props.inline ? "inline" : "flex")};
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  margin-right: 4px;
  background: ${(props) => (props.color ? props.color : colors.winterMist)};

  ${(props) =>
    props.compressed &&
    css`
      padding: 3px 6px;
      font-size: 10px;
      line-height: 1;
      margin-right: 2px;
    `}
`;

const HeaderContent = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;

  .ant-icon {
    line-height: 1;
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;

    &:after {
      width: 24px;
      height: 24px;
      top: 7px;
      left: 7px;
    }
  }
`;

const ActionMenuWrapper = styled.div`
  margin-left: auto;
`;

const PackName = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 13px;
  line-height: 21px;
  color: ${colors.white};
  opacity: 0.7;
  text-transform: capitalize;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;
`;

const CreationDate = styled.div`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${colors.lightMidGray};
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  svg {
    margin-right: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Pillar = styled.div`
  background: ${(props) => props.color};
  height: 11px;
  width: 3px;
  margin-right: 8px;
  transform: rotate(30deg);
`;

const Pillars = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderTags = styled.div`
  display: flex;
`;

const ErrorIcon = styled(Icon)`
  color: ${colors.darkRed};
  font-size: 15px;
  margin-right: 5px;
`;

const TooltipMessage = styled.span`
  font-size: 13px;
  line-height: 21px;
  color: ${colors.white};
  opacity: 0.7;
`;

function getLayer(pack = {}) {
  const layerType = pack.layer === "addon" ? pack.addonType : pack.layer;
  return LAYER_TYPES.find((layer) => layer.type === layerType);
}

function renderPacks(pack, index) {
  const layer = getLayer(pack);
  return (
    <PackName key={index} data-qa="cluster-profile-pack" data-qa-index={index}>
      {layer && <SVGLayerIcon path={layer.icon} color={layer.color} />}{" "}
      {pack.displayName} {pack.tag}
    </PackName>
  );
}

function renderPackPillar(item = {}) {
  const layer = getLayer(item);
  return <Pillar key={item.guid} color={layer?.color || colors.lightMidGray} />;
}

const DeleteActionItem = createAsyncActionComponent({
  Component: Menu.Item,
  asyncAction: deleteProfileAsyncAction,
  triggerOnClick: false,
});

export function ProfileCardTags({ profileType, scope, compressed = false }) {
  const tags = useMemo(() => {
    return [PROFILE_TYPE_TAGS[profileType], SCOPE_TAGS[scope]].filter(Boolean);
  }, [profileType, scope]);

  const renderTag = (data) => {
    if (!data) {
      return null;
    }
    return (
      <HeaderTag key={data.label} color={data.color} compressed={compressed}>
        {data.label}
      </HeaderTag>
    );
  };

  return <HeaderTags>{tags.map(renderTag)}</HeaderTags>;
}

const ClusterProfileCard = ({
  clusterprofile,
  openCloneClusterProfileModal,
  onDeleteProfile,
  withMenu = false,
  withRadio = false,
  highlight = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { guid, name, tags = {}, metadata, packs = [], spec } = clusterprofile;
  const cloudType = spec?.published?.cloudType;
  const scope = metadata?.annotations?.scope;
  const profileType = spec?.published?.type;
  const ENVIRONMENTS = useSelector(getEnvironments);
  const envLogo = useMemo(() => {
    return ENVIRONMENTS.find((item) => item.apiKey === cloudType)
      ?.smallLogoPath;
  }, [ENVIRONMENTS, cloudType]);
  const permissions = new PermissionService(
    metadata?.annotations?.permissions?.split(",")
  );
  const isInvalid = !!metadata?.annotations?.inValidReason;

  const actionMenuOptions = [
    allPermissions.has("clusterProfile.create") && {
      key: "clone",
      label: t("Clone"),
      onClick: () => openCloneClusterProfileModal(guid),
    },
    permissions.has("clusterProfile.delete") && {
      key: "delete",
      label: t("Delete"),
      color: colors.error,
      onClick: () => onDeleteProfile(guid),
      identifier: guid,
      Component: DeleteActionItem,
    },
  ].filter(Boolean);

  const renderErrorIcon = () => {
    if (isInvalid) {
      return (
        <Tooltip
          title={
            <TooltipMessage>{t("Invalid cluster profile")}</TooltipMessage>
          }
          color={colors.darkBlue}
          placement="right"
        >
          <ErrorIcon awesome={faExclamationCircle} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Wrap {...rest} highlight={highlight}>
      <Header>
        <HeaderContent>
          {withRadio && <Radio size="large" checked={highlight} />}
          {renderErrorIcon()}
          <ProfileCardTags profileType={profileType} scope={scope} />
        </HeaderContent>
        {withMenu && (
          <ActionMenuWrapper onClick={(ev) => ev.stopPropagation()}>
            <ActionMenu options={actionMenuOptions} />
          </ActionMenuWrapper>
        )}
      </Header>

      <Title>{metadata?.name || name}</Title>

      <Content>
        <Tags tags={tags} />
        <CreationDate>
          <Icon awesome={faCalendar} style={{ fontSize: "18px" }} />
          <div>
            {moment(metadata.creationTimestamp).format("D MMM, YYYY / hh:mm A")}
          </div>
        </CreationDate>
      </Content>

      <Footer>
        <Tooltip
          color={colors.darkBlue}
          placement="right"
          title={packs.map(renderPacks)}
          overlayClassName="clusterprofile-tooltip"
        >
          <Pillars>{packs.map(renderPackPillar)}</Pillars>
        </Tooltip>
        {envLogo && <img src={envLogo} alt={cloudType} />}
      </Footer>
    </Wrap>
  );
};

export default ClusterProfileCard;
