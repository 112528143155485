import api from "services/api";
import dataFetcher from "modules/dataFetcher";
import { ClusterSchema } from "utils/schemas";
import { getCurrentContext } from "state/auth/selectors";
import store from "services/store";
import { getAllClustersList } from "../selectors/mapview";

export const clusterDetailsFetcher = dataFetcher({
  selectors: ["mapView", "clusterDetails"],
  schema: ClusterSchema,
  fetchData(_, clusterUid) {
    const context = getCurrentContext(store.getState());

    if (context?.isAdmin) {
      const allClusters = getAllClustersList(store.getState());
      const selectedCluster = (allClusters || []).find(
        (cluster) => cluster.metadata.uid === clusterUid
      );

      const projectUid = selectedCluster?.specSummary?.projectMeta?.uid;

      if (projectUid) {
        return api.get(`v1/dashboard/spectroclusters/${clusterUid}`, null, {
          headers: { ProjectUid: projectUid },
        });
      }
    }

    return api.get(`v1/dashboard/spectroclusters/${clusterUid}`);
  },
});
