import React, { useEffect, useState } from "react";
import * as THREE from "three";
import styled from "styled-components";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";

const SVG_CACHE = {};
const SVG_MESH_CACHE = {};

function LayerIcon3D({ path, color, position = [-2.85, 0.8, 5.1] }) {
  const [group, setGroup] = useState();
  useEffect(() => {
    if (!path) {
      return;
    }

    if (SVG_MESH_CACHE[path]) {
      setGroup(SVG_MESH_CACHE[path]);
      return;
    }

    const loader = new SVGLoader();
    loader.load(path, function (data) {
      const shapes = data.paths.reduce((accumulator, path) => {
        return accumulator.concat(SVGLoader.createShapes(path));
      }, []);

      SVG_MESH_CACHE[path] = shapes;

      setGroup(shapes);
    });
  }, [path]);

  if (!group) {
    return null;
  }

  return (
    <group
      position={position}
      scale={[1, 1 / 0.95, 1]}
      rotation={[0, Math.PI - Math.PI / 6, -Math.PI]}
    >
      {(group || []).map((shape, index) => (
        <mesh key={index} scale={[0.037, 0.037, 0.037]}>
          <shapeGeometry attach="geometry" args={[shape]} />
          <meshBasicMaterial
            attach="material"
            deepWrite={false}
            side={THREE.DoubleSide}
            color={color}
          />
        </mesh>
      ))}
    </group>
  );
}

export default React.memo(LayerIcon3D);

export const SVGWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  svg {
    height: 20px;
    width: 20px;
    rect {
      display: none;
    }
    path {
      fill: ${(props) => props.color};
    }
  }
`;

export function SVGLayerIcon({ path, color }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (!path) {
      return;
    }

    async function getIcon() {
      if (SVG_CACHE[path]) {
        return setData(SVG_CACHE[path]);
      }
      const data = await fetch(path);
      const text = await data.text();
      SVG_CACHE[path] = text;
      setData(text);
    }
    getIcon();
  }, [path]);

  if (!data) {
    return <SVGWrap />;
  }

  return <SVGWrap dangerouslySetInnerHTML={{ __html: data }} color={color} />;
}
