import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { getLatestVersionRegistry } from "./containers";

export const securityOptionsFetcher = dataFetcher({
  selectors: ["app-builder", "security"],
  async fetchData() {
    const response = await api.post("v1/packs/search?limit=100", {
      filter: {
        type: ["spectro"],
        layer: ["addon"],
        environment: ["all"],
        addOnType: ["operator"],
        addOnSubType: ["security"],
      },
      sort: [
        {
          field: "displayName",
          order: "asc",
        },
      ],
    });

    const output = [];

    const items = response.items.map(async (item, index) => {
      const registryUid = getLatestVersionRegistry(item.spec.registries)?.uid;

      if (!registryUid) {
        return item;
      }

      const pack = await api.get(
        `v1/packs/${item.spec.name}/registries/${registryUid}`
      );

      const latestPackVersion = (pack?.tags || []).find(
        (pack) => !pack.parentTags
      );

      const latestPackValues = pack.packValues.find(
        (packValue) => packValue.packUid === latestPackVersion.packUid
      );

      output.push({
        ...item.spec,
        ...latestPackValues,
        packUid: latestPackVersion.packUid,
        version: latestPackVersion.version,
      });

      return pack;
    });

    await Promise.allSettled(items);
    return output;
  },
});
