import isEqual from "fast-deep-equal";
import debounce from "redux-debounce-thunk";
import entities, { createEntity } from "utils/entities";

const DEFAULT_LIMIT_SIZE = 50;

export default class ListActions {
  constructor(actions) {
    if (actions instanceof ListActions) {
      return actions;
    }

    const {
      fetchData,
      schema,
      debounceDelay = 1000,
      initialQuery = () => ({}),
      defaultQuery,
      dataFetcher,
      hasPagination,
      identifier,
    } = actions;

    Object.assign(this, {
      fetchData,
      schema,
      initialQuery,
      dataFetcher,
      hasPagination,
      identifier,
      defaultQuery,
    });

    this.debouncedFetchItems = debounce(this.fetchItems, debounceDelay);
  }

  initialize(module, initialQuery = this.initialQuery()) {
    return (dispatch) => {
      dispatch({
        type: "LIST_INITIALIZE",
        query: { limit: DEFAULT_LIMIT_SIZE, ...initialQuery },
        defaultQuery: this.defaultQuery,
        module,
      });
      return dispatch(this.createFetchAction(module, "LIST_INITIALIZE"));
    };
  }

  addItems({ module, items }) {
    return (dispatch) => {
      const entity = createEntity(items, this.schema);
      const normalization = entities.normalize(entity, this.schema);
      dispatch({
        type: "ADD_ENTITIES",
        ...normalization,
      });
      dispatch({
        type: "LIST_ADD_ITEMS",
        guid: entity.guid,
        module,
        items: normalization.result,
      });
    };
  }

  removeItems({ module, items }) {
    return {
      type: "LIST_REMOVE_ITEM",
      module,
      items,
    };
  }

  nextPage(module) {
    return (dispatch, getState) => {
      const listState = getState().list[module];
      if (listState.isLoading || !listState.nextToken) {
        return;
      }
      dispatch({ type: "LIST_NEXT_PAGE", module });
      return dispatch(this.fetchItems(module));
    };
  }

  goToPage({ module, pageNumber }) {
    return (dispatch, getState) => {
      const { count, query } = getState().list[module] || {};
      const lastPageNumber = Math.ceil(count / query.limit);
      if (pageNumber > lastPageNumber) {
        return;
      }
      dispatch({
        type: "LIST_GO_TO_PAGE",
        module,
        currentPageNumber: pageNumber,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  fetchItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_FETCH_ITEMS"));
    };
  };

  refreshItems = (module) => {
    return (dispatch) => {
      return dispatch(this.createFetchAction(module, "LIST_REFRESH_ITEMS"));
    };
  };

  createFetchAction(module, type) {
    return (dispatch, getState) => {
      const { query, currentPageNumber, token } = getState().list[module] || {};
      let offset;
      if (this.hasPagination) {
        offset = (currentPageNumber - 1) * query.limit;
      }

      const promise = async () => {
        if (!this.fetchData && !this.dataFetcher) {
          return {
            items: [],
          };
        }

        let data = {
          items: [],
        };

        const fetchQuery = {
          ...query,
          continue: token !== "initial" ? token : undefined,
          offset,
        };

        if (this.dataFetcher) {
          const dataFetcher = this.identifier
            ? this.dataFetcher.key(this.identifier)
            : this.dataFetcher;

          await dispatch(dataFetcher.fetch(fetchQuery));
          data = dataFetcher.selector(getState()).result;
        }

        if (this.fetchData) {
          data = await this.fetchData(fetchQuery);
        }

        dispatch({
          type: "LIST_SET_ITEMS_META",
          nextToken: data?.listmeta?.continue,
          count: data?.listmeta?.count,
          module,
        });

        return data?.items || [];
      };

      const apiCall = promise();

      dispatch({
        type,
        module,
        promise: apiCall,
        token,
        schema: this.schema,
        currentPageNumber,
        hasPagination: this.hasPagination,
      });

      return apiCall;
    };
  }

  changeQuery({ name, value, module }) {
    return (dispatch) => {
      dispatch({
        module: module,
        type: "LIST_CHANGE_QUERY",
        name,
        value,
        hasPagination: this.hasPagination,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }

  batchChangeQuery({ module, query }) {
    return (dispatch, getState) => {
      if (isEqual(query, getState().list[module]?.query)) return;

      dispatch({
        module,
        type: "BATCH_CHANGE_QUERY",
        query,
        hasPagination: this.hasPagination,
      });
      dispatch(this.debouncedFetchItems(module));
    };
  }
}
