import i18n from "i18next";
import { createSelector } from "reselect";
import i18next from "i18next";

import api, { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store, { getStoreEntity } from "services/store";
import ModalService from "services/modal";

import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ProfileStackModule from "modules/profileStack";

import {
  CloudAccountSchema,
  ClusterSchema,
  DatacenterSchema,
} from "utils/schemas";
import {
  MAAS_ENVIRONMENT,
  MANAGED_TO_PURE_ENVIRONMENT,
  OPENSTACK_ENVIRONMENT,
  VMWARE_ENVIRONMENT,
} from "utils/constants";
import { getCluster, getRawCluster } from "../selectors/details";
import { edgeMachinesFetcher } from "../actions/list/edgemachines";
import { getClusterCloudConfig } from "../selectors/nodes";
import {
  maasOverlordsFetcher,
  openstackOverlordsFetcher,
  vmWareOverlordsFetcher,
} from "state/cloudaccounts/services";

export const onSpotWarningConfirm = new ModalService("onSpotWarning");

const CLOUD_TYPE_TO_OVERLORD_FETCHER = {
  [VMWARE_ENVIRONMENT.apiKey]: vmWareOverlordsFetcher,
  [MAAS_ENVIRONMENT.apiKey]: maasOverlordsFetcher,
  [OPENSTACK_ENVIRONMENT.apiKey]: openstackOverlordsFetcher,
};

export const cloudAccountsFetcher = dataFetcher({
  selectors: [
    "credentials",
    (state) => {
      const cloudType = state.forms?.cluster?.data?.cloudType;
      return MANAGED_TO_PURE_ENVIRONMENT[cloudType] || cloudType;
    },
  ],
  async fetchData([_, cloudType]) {
    if (!cloudType) {
      return [];
    }

    const res = await api.get("v1/dashboard/cloudaccounts/metadata", {
      environment: cloudType,
    });

    if (Object.keys(CLOUD_TYPE_TO_OVERLORD_FETCHER).includes(cloudType)) {
      const overlordFetcher = CLOUD_TYPE_TO_OVERLORD_FETCHER[cloudType];
      await store.dispatch(overlordFetcher.fetch());
      const overlords =
        overlordFetcher.selector(store.getState())?.result || [];

      return (res?.items || []).filter((cloudAccount) => {
        if (cloudAccount?.metadata?.annotations?.overlordType === "system") {
          return true;
        }

        const isOverlordAccount = overlords.find(
          (overlord) =>
            overlord?.spec?.cloudAccountUid === cloudAccount?.metadata?.uid
        );

        const hasAnnotation = overlords.find((overlord) => {
          return (
            cloudAccount?.metadata?.annotations?.overlordUid ===
            overlord?.metadata?.uid
          );
        });

        return isOverlordAccount || hasAnnotation;
      });
    }

    return res.items;
  },
  schema: [CloudAccountSchema],
});

export const regionsFetcher = dataFetcher({
  selectors: [
    "regions",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.credential,
    (state) => state.forms.cluster.data?.subscriptionId,
  ],
  async fetchData([_, selectedCloud, credential, subscriptionId]) {
    try {
      let apiEndpoint = `v1/clouds/${selectedCloud}/regions?cloudAccountUid=${credential}`;

      if (selectedCloud === "azure") {
        apiEndpoint = `${apiEndpoint}&subscriptionId=${subscriptionId}`;
      }

      const res = await api.get(apiEndpoint);
      return res.regions;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to retrieve the required networks."
        ),
        duration: 0,
        description: err.message,
      });
    }
  },
});

export const subscriptionsFetcher = dataFetcher({
  selectors: ["subscriptions", (state) => state.forms.cluster.data?.credential],
  async fetchData([_, credential]) {
    const res = await api.get(
      `v1/clouds/azure/subscriptions?cloudAccountUid=${credential}`
    );
    return res.subscriptionList;
  },
});

export const azureSubscriptionFetcher = dataFetcher({
  selectors: ["clusterSubscription"],
  async fetchData([_]) {
    const cluster = getCluster(store.getState());
    const credential = cluster.spec.cloudConfig.spec.cloudAccount.metadata.uid;

    const res = await api.get(
      `v1/clouds/azure/subscriptions?cloudAccountUid=${credential}`
    );
    return res.subscriptionList;
  },
});

export const virtualNetworksFetcher = dataFetcher({
  selectors: [
    "virtualNetworks",
    (state) => state.forms.cluster.data?.region,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
    (state) => state.forms.cluster.data?.vnetResourceGroup,
  ],
  async fetchData([_, region, subscriptionId, credential, vnetResourceGroup]) {
    try {
      const res = await api.get(
        `v1/clouds/azure/regions/${region}/subscriptions/${subscriptionId}/networks?cloudAccountUid=${credential}&resourceGroup=${vnetResourceGroup}`
      );

      return res.virtualNetworkList;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when retrieving the virtual network list"
        ),
        description: error.message,
      });
    }
  },
});

export const adminGOIDsFetcher = dataFetcher({
  selectors: ["adminGOIDs", (state) => state.forms.cluster.data?.credential],
  async fetchData([_, credential]) {
    try {
      const res = await api.get(
        `v1/clouds/azure/groups?cloudAccountUid=${credential}`
      );

      return res.groups;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when retrieving the admin group object IDs"
        ),
        description: error.message,
      });
    }
  },
});

export const resourceGroupsFetcher = dataFetcher({
  selectors: [
    "resourceGroups",
    (state) => state.forms.cluster.data?.region,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, region, subscriptionId, credential]) {
    const res = await api.get(
      `v1/clouds/azure/regions/${region}/subscriptions/${subscriptionId}/resourceGroups?cloudAccountUid=${credential}`
    );
    return res.resourceGroupList;
  },
});

export const privateDnsZonesFetcher = dataFetcher({
  selectors: [
    "azure/privatednszones",
    (state) => state.forms.cluster.data?.staticPlacement,
    (state) => state.forms.cluster.data?.resourceGroup,
    (state) => state.forms.cluster.data?.vnetResourceGroup,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([
    _,
    staticPlacement,
    resourceGroup,
    vnetResourceGroup,
    subscriptionId,
    credential,
  ]) {
    const group =
      staticPlacement === "true" ? vnetResourceGroup : resourceGroup;

    const res = await api.get(
      `v1/clouds/azure/resourceGroups/${group}/privateDnsZones`,
      {
        subscriptionId,
        cloudAccountUid: credential,
      }
    );
    return res?.privateDnsZones || [];
  },
});

export const storageAccountsFetcher = dataFetcher({
  selectors: [
    "azure/storageAccounts",
    (state) => state.forms.cluster.data?.resourceGroup,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, resourceGroup, subscriptionId, credential]) {
    const res = await api.get(
      `v1/clouds/azure/resourceGroups/${resourceGroup}/storageAccounts`,
      {
        subscriptionId,
        cloudAccountUid: credential,
      }
    );
    return res?.accounts || [];
  },
});

export const storageContainersFetcher = dataFetcher({
  selectors: [
    "azure/storageAccounts",
    (state) => state.forms.cluster.data?.resourceGroup,
    (state) => state.forms.cluster.data?.storageAccountName,
    (state) => state.forms.cluster.data?.subscriptionId,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([
    _,
    resourceGroup,
    storageAccountName,
    subscriptionId,
    credential,
  ]) {
    const res = await api.get(
      `v1/clouds/azure/resourceGroups/${resourceGroup}/storageAccounts/${storageAccountName}/containers`,
      {
        subscriptionId,
        cloudAccountUid: credential,
      }
    );
    return res?.containers || [];
  },
});

function getClusterDetails(state) {
  // TODO find a better way
  if (state.router.location.pathname.includes("/nodes")) {
    return getStoreEntity(
      state.cluster.details.currentClusterId,
      ClusterSchema
    );
  }

  return null;
}

export const getApplianceUid = createSelector(
  getClusterDetails,
  (state) => state.forms?.cluster?.data?.appliance,
  (cluster, createClusterFormAppliance) => {
    return (
      cluster?.spec?.cloudConfig?.spec?.edgeHostRef?.uid ||
      createClusterFormAppliance ||
      ""
    );
  }
);

const getClusterAccountUid = createSelector(
  getClusterDetails,
  (state) => state?.forms?.cluster?.data?.credential,
  (cluster, credential) => {
    if (cluster) {
      return cluster.spec.cloudConfig.spec.cloudAccountRef?.uid;
    }

    return credential;
  }
);

export const propertiesFetcher = keyedDataFetcher({
  selectors: [
    "vsphere",
    getClusterAccountUid,
    (state) => {
      const cluster = getClusterDetails(state);

      if (cluster) {
        return cluster.spec.cloudConfig.spec.clusterConfig.placement.datacenter;
      }

      return state.forms.cluster.data.datacenter;
    },
    getApplianceUid,
  ],
  async fetchData([_, accountUid, datacenter, applianceUid, computecluster]) {
    try {
      if (applianceUid) {
        const appliances = vsphereAppliancesFetcher.selector(
          store.getState()
        )?.result;
        const selectedAppliance = appliances?.find(
          (appliance) => appliance.metadata.uid === applianceUid
        );

        const selectedDatacenter =
          selectedAppliance?.spec.cloudProperties?.vsphere?.datacenters.find(
            (selectedDatacenter) => selectedDatacenter.name === datacenter
          );

        return (
          selectedDatacenter?.computeClusters.find(
            (item) => item.name === computecluster
          ) || {}
        );
      }
      const response = api.get(
        `v1/cloudaccounts/vsphere/${accountUid}/properties/computecluster/resources?datacenter=${datacenter}&computecluster=${computecluster}`
      );
      const data = await response;
      return data.computecluster;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the properties"
        ),
        description: err?.message,
      });
    }
  },
});

export const datacentersFetcher = dataFetcher({
  selectors: ["datacenters", getClusterAccountUid, getApplianceUid],
  schema: [DatacenterSchema],
  async fetchData([_, accountUid, applianceUid]) {
    if (applianceUid) {
      const appliances = vsphereAppliancesFetcher.selector(
        store.getState()
      )?.result;
      const selectedAppliance = appliances?.find(
        (appliance) => appliance.metadata.uid === applianceUid
      );

      return (
        selectedAppliance?.spec?.cloudProperties?.vsphere?.datacenters || []
      );
    }
    try {
      const response = await api.get(
        `v1/cloudaccounts/vsphere/${accountUid}/properties/datacenters`
      );
      return response.items;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the datacenters"
        ),
        description: err?.message,
      });
    }
  },
});

export const projectsFetcher = dataFetcher({
  selectors: [
    "projects",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, selectedCloud, credential]) {
    const res = await api.get(
      `v1/clouds/${selectedCloud}/projects?cloudAccountUid=${credential}`
    );
    return res.projects;
  },
});

export const regionsByProjectFetcher = dataFetcher({
  selectors: [
    "regions",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.project,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, selectedCloud, project, credential]) {
    try {
      const res = await api.get(
        `v1/clouds/${selectedCloud}/projects/${project}/regions?cloudAccountUid=${credential}`
      );
      return res.regions;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to retrieve the required networks."
        ),
        duration: 0,
        description: err.message,
      });
    }
  },
});

export const networksFetcher = dataFetcher({
  selectors: [
    "networks",
    (state) => state.cluster.create.selectedCloud,
    (state) => state.forms.cluster.data?.project,
    (state) => state.forms.cluster.data?.region,
    (state) => state.forms.cluster.data?.credential,
  ],
  async fetchData([_, selectedCloud, project, region, credential]) {
    const res = await api.get(
      `v1/clouds/${selectedCloud}/projects/${project}/regions/${region}/networks?cloudAccountUid=${credential}`
    );
    return res.networks;
  },
});

export const sshFetcher = dataFetcher({
  selectors: [
    "ssh",
    (state) => state?.cluster?.create?.selectedCloud,
    (state) => state?.forms?.cluster?.data?.region,
    (state) => state?.forms?.cluster?.data?.credential,
  ],
  async fetchData([_, selectedCloud, region, accountUid]) {
    try {
      const response = await api.get(
        `v1/clouds/${selectedCloud}/regions/${region}/keypairs?cloudAccountUid=${accountUid}`
      );
      return response;
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong when trying to get the SSH keys"),
        description: err?.message,
      });
    }
  },
});

export const getSelectedCloudAccount = createSelector(
  (state) => state.router.location,
  getRawCluster,
  (state) => state?.forms?.cluster?.data?.credential,
  cloudAccountsFetcher.selector,
  (location, cluster, accountUid, accounts) => {
    // TODO find a better way
    if (location.pathname.includes("/nodes")) {
      return cluster.spec.cloudConfig.spec.cloudAccount;
    }
    return accounts?.result?.find(
      (account) => account.metadata.uid === accountUid
    );
  }
);

const getSelectedCloudAccountType = createSelector(
  getSelectedCloudAccount,
  (selectedAccount) => {
    return selectedAccount?.kind;
  }
);

const getSelectedCloudAccountOverlordUid = createSelector(
  getSelectedCloudAccount,
  (selectedAccount) => {
    return selectedAccount?.metadata?.annotations?.overlordUid;
  }
);

export const ipamFetcher = dataFetcher({
  selectors: [
    "overlordPools",
    getSelectedCloudAccountType,
    getSelectedCloudAccountOverlordUid,
  ],
  async fetchData([_, type, uid]) {
    const response = await nonProjectApi.get(
      `v1/overlords/${type}/${uid}/pools`
    );

    // TODO: assuming that if there is a clusterUid then you are nodes page
    const clusterUid = store.getState().location?.params?.id;

    return response.items.filter((pool) => {
      if (pool.spec.restrictToSingleCluster === false) {
        return true;
      }

      if (!pool.status.inUse) {
        return true;
      }

      if (clusterUid) {
        return pool.status.associatedClusters.includes(clusterUid);
      }

      return false;
    });
  },
});

export const clusterCreateProfileModule = new ProfileStackModule({
  name: "cluster-create",
});

// Might need to delete pool index in the future and make this simple data fetcher
export const appliancesKeyedFetcher = keyedDataFetcher({
  selectors: ["appliances", (state) => state.forms.cluster.data.cloudType],
  fetchData: async ([_, cloudType, poolIndex]) => {
    const result = edgeMachinesFetcher.selector(store.getState()).result;
    const nodePools = store.getState()?.forms?.cluster?.data?.nodePools;
    const excludedEdgeHosts = nodePools
      .flatMap((pool) => pool.edgeHosts)
      .map((host) => host.hostUid);
    const architecture = nodePools?.[poolIndex]?.architecture;

    const edgeMachines = (result?.items || []).filter((machine) => {
      const machineStatus = machine?.status;
      const machineState = machineStatus?.state?.toLowerCase();
      const archType = machine?.spec?.device?.archType;

      let isReady = machineState === "ready";
      const isUnpaired = machineState === "unpaired";
      const isHealthy = machineStatus?.health?.state !== "unhealthy";
      const isSameCloudType = machine?.spec?.type?.toLowerCase() === cloudType;
      let isNotInUse = true;

      if (cloudType === "edge-native") {
        isNotInUse =
          machineStatus.inUseClusters.length === 0 &&
          !excludedEdgeHosts.includes(machine.metadata.uid);
      }

      return (
        (isUnpaired || (isReady && isHealthy && isSameCloudType)) &&
        isNotInUse &&
        archType === architecture
      );
    });

    return edgeMachines;
  },
});

export const vsphereAppliancesFetcher = dataFetcher({
  selectors: ["vsphere-appliances"],
  fetchData: async () => {
    const promise = api.get(`v1/edgehosts?type=vsphere`);
    const clusterCloudConfig = getClusterCloudConfig(store.getState());
    const inUseEdgeHostUid = clusterCloudConfig?.spec?.edgeHostRef?.uid;

    try {
      const result = await promise;

      return (result?.items || []).filter((machine) => {
        const machineStatus = machine?.status;

        if (inUseEdgeHostUid && inUseEdgeHostUid === machine.metadata.uid) {
          return true;
        }

        return (
          (machineStatus?.state?.toLowerCase() === "ready" &&
            machineStatus?.health?.state !== "unhealthy") ||
          machineStatus?.state?.toLowerCase() === "unpaired"
        );
      });
    } catch (e) {}
  },
});

function getEdgeMachineData(indexes) {
  const nodePools = store.getState().forms.cluster.data.nodePools;
  const result = edgeMachinesFetcher.selector(store.getState()).result;
  const [poolIndex, index] = indexes.split(".");
  const selectedHost =
    nodePools?.[poolIndex]?.edgeHosts?.[index]?.hostUid || "";

  const edgeMachine = (result?.items || []).find(
    (machine) => machine.metadata.uid === selectedHost
  );

  return edgeMachine;
}

export const applianceStoragePoolsKeyedFetcher = keyedDataFetcher({
  selectors: ["applianceStoragePools"],
  fetchData: async ([_, indexes]) => {
    const edgeMachine = getEdgeMachineData(indexes);
    const storagePools = edgeMachine?.spec?.properties?.storagePools || [];

    return storagePools;
  },
});

export const applianceNetworkTypesKeyedFetcher = keyedDataFetcher({
  selectors: ["applianceNetworkTypes"],
  fetchData: async ([_, indexes]) => {
    const edgeMachine = getEdgeMachineData(indexes);
    const networkTypes = [
      ...new Set(
        (edgeMachine?.spec?.properties?.networks || []).map(
          (network) => network.networkType
        )
      ),
    ];

    return Promise.resolve(networkTypes);
  },
});

export const applianceNetworksKeyedFetcher = keyedDataFetcher({
  selectors: ["applianceNetworks"],
  fetchData: async ([_, indexes]) => {
    const [poolIndex, index] = indexes.split(".");
    const nodePools = store.getState().forms.cluster.data.nodePools;

    const edgeMachine = getEdgeMachineData(indexes);
    const selectedNetworkType =
      nodePools?.[poolIndex]?.edgeHosts?.[index]?.networkType || "";

    const networks = (edgeMachine?.spec?.properties?.networks || []).filter(
      (network) =>
        selectedNetworkType ? network.networkType === selectedNetworkType : true
    );

    return Promise.resolve(networks);
  },
});

export const applianceResourceFetchers = [
  applianceStoragePoolsKeyedFetcher,
  applianceNetworkTypesKeyedFetcher,
  applianceNetworksKeyedFetcher,
];

export const eksKmskeysFetcher = keyedDataFetcher({
  selectors: ["kmskeys", (state) => state?.forms?.cluster?.data?.credential],
  fetchData: async ([_, cloudAccountUid, region]) => {
    const promise = api.get(
      `v1/clouds/aws/regions/${region}/kmskeys?cloudAccountUid=${cloudAccountUid}`
    );

    try {
      const keys = await promise;
      return keys?.kmsKeys || [];
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err?.message,
      });

      return [];
    }
  },
});

export const eksVolumeFetcher = keyedDataFetcher({
  selectors: ["eks-volumes"],
  fetchData: async ([_, region]) => {
    const promise = api.get(`v1/clouds/aws/volumeTypes?region=${region}`);

    try {
      const response = await promise;
      return response?.volumeTypes || [];
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err?.message,
      });

      return [];
    }
  },
});
