import { getEntity } from "utils/entities";
import { ClusterGroupSchema } from "utils/schemas";
import { createSelector } from "reselect";
import { PermissionService } from "services/permissions";

export const getClusterGroupEntity = getEntity(
  (state) => state.clusterGroup?.details?.currentClusterGroupGuid,
  ClusterGroupSchema
);

export const getClusterGroupName = createSelector(
  getClusterGroupEntity,
  (group) => {
    return group?.metadata?.name;
  }
);

export const getClusterGroupPermissions = createSelector(
  getClusterGroupEntity,
  (group) => {
    return new PermissionService(
      group?.metadata?.annotations?.permissions?.split(",")
    );
  }
);
