import { ClusterProfileSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { presentClusterProfileLayers } from "utils/presenters";
import { createSelector } from "reselect";
import { PermissionService } from "services/permissions";
import { profileBuilderEditModule } from "../services";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";
import { systemProfileBuilderEditModule } from "state/profiles/appprofiles/services/create";
import { useSelector } from "react-redux";
import { getCurrentContext } from "state/auth/selectors";

export const getRawClusterProfile = getEntity(
  (state) => state.clusterprofile.details.currentClusterProfileId,
  ClusterProfileSchema
);

export const getSelectedClusterProfileLayers = createSelector(
  getRawClusterProfile,
  (selectedClusterProfile) => {
    return presentClusterProfileLayers(selectedClusterProfile);
  }
);

export const getDetailPermissions = createSelector(
  getRawClusterProfile,
  (profile) => {
    return new PermissionService(
      profile?.metadata?.annotations?.permissions?.split(",")
    );
  }
);

export const isProfileBuilderValid = createSelector(
  () => profileBuilderEditModule?.isProfileValid(),
  (isValid) => {
    return isValid;
  }
);

const getPacksParams = createSelector(
  getRawClusterProfile,
  (clusterprofile) => {
    const clusterProfilePacks = clusterprofile?.spec?.published?.packs;
    const paramsConfig = clusterProfilePacks.reduce((accumulator, value) => {
      const { guid, params, pack } = value;
      if (!pack?.spec?.params) {
        accumulator[guid] = [];
        return accumulator;
      }

      const layerConfig = pack.spec.params
        .filter((item) => item.scope === "cluster")
        .map((param) => {
          return {
            ...param,
            value: params[param.name],
          };
        });

      accumulator[guid] = layerConfig;
      return accumulator;
    }, {});

    return paramsConfig;
  }
);

export const getParamsForLayer = createSelector(
  getPacksParams,
  (state) => state.clusterprofile.details.selectedLayer,
  (packs, selectedLayer) => {
    if (packs && selectedLayer && packs[selectedLayer]) {
      return packs[selectedLayer];
    }

    return [];
  }
);

function isUpdateNotificationActionable(notification) {
  const events = notification.action?.events;
  if (!events) {
    return;
  }

  if (notification?.action.isDone) {
    return;
  }

  return notification;
}

export const getUpdateNotification = createSelector(
  getRawClusterProfile,
  (clusterprofile) => {
    const notifications = clusterprofile?.notifications || [];
    return notifications.filter(isUpdateNotificationActionable);
  }
);

export const isNotificationAcknowledged = createSelector(
  getRawClusterProfile,
  (clusterprofile) => {
    return clusterprofile?.notifications?.some(
      (notification) => notification?.action?.isDone === false
    );
  }
);

export const canUpdateClusterProfile = createSelector(
  getUpdateNotification,
  isNotificationAcknowledged,
  (notification, isNotificationAcknowledged) => {
    return notification.length > 0 && isNotificationAcknowledged;
  }
);

export const areUpdatesInfoOnly = createSelector(
  getRawClusterProfile,
  (clusterprofile) => {
    return clusterprofile?.notifications?.every(
      (notification) => notification?.action?.isInfo === true
    );
  }
);

export const showInfoNotification = createSelector(
  getRawClusterProfile,
  (state) => state.clusterprofile.details.scopePackValues,
  (clusterprofile, packValues) => {
    if (packValues.length > 0) {
      return false;
    }

    return clusterprofile?.notifications?.some(
      (notification) => notification?.action?.isInfo
    );
  }
);

export const getNotificationAck = createSelector(
  (state) => state.clusterprofile.details.scopePackValues,
  areUpdatesInfoOnly,
  (packValues, areUpdatesInfoOnly) => {
    if (areUpdatesInfoOnly) {
      return true;
    }

    return packValues?.every((value) => value.isAck === true);
  }
);

export const getResolvedValues = createSelector(
  getRawClusterProfile,
  (profile) => profile?.status?.resolved || {}
);

function extractPackFromEvent(event) {
  let packSpec = event.pack.spec;
  let eventMeta = { message: event.message, type: event.type };

  const packMeta = {
    metadata: event.pack.metadata,
    guid: event.pack.guid,
    logo: packSpec?.logoUrl,
    name: packSpec?.displayName || packSpec?.name || event.pack.metadata.name,
    tag: packSpec?.tag || packSpec?.version,
    ...(event.manifest ? {} : { event: eventMeta }),
    type: event.packType,
    isDisabled:
      event?.type === "RegistryPackTagDelete" ||
      event.sourceType === "InfoNotificationRegistryPackUpdate",
  };

  return packMeta;
}

export const getUpdateNotificationPacks = createSelector(
  getUpdateNotification,
  (updateNotifications) => {
    const packs = [];
    const events = updateNotifications.reduce(
      (accumulator, notification) => [
        ...accumulator,
        ...notification.events.map((event) => ({
          ...event,
          sourceType: notification.type,
        })),
      ],
      []
    );
    if (!events.length) {
      return [];
    }

    events.forEach((event) => {
      const existingPack = packs.find((pack) => {
        return pack.metadata.uid === event.packUid;
      });

      const newPack = extractPackFromEvent(event);

      if (!existingPack) {
        packs.push(newPack);

        return;
      }
    });

    return packs;
  }
);

export const getProfileDivergencies = createSelector(
  getUpdateNotificationPacks,
  (state) => state.clusterprofile.details.notification.divergences,
  (packs, divergencies) => {
    return Object.keys(divergencies).reduce((accumulator, packName) => {
      const packVersion = packs.find((pack) => pack.metadata.name === packName);
      const [profileInfo, repositoryInfo] = divergencies[packName]?.items || [];

      if (!packVersion) {
        return accumulator;
      }

      if (profileInfo.spec.isValuesOverridden) {
        accumulator[packVersion.guid] = {
          values: getPackValuesWithoutPresetsComment(
            repositoryInfo?.spec?.values
          ),
          isInversed: profileInfo.spec.isValuesOverridden,
        };
      } else {
        accumulator[packVersion.guid] = {
          values: getPackValuesWithoutPresetsComment(profileInfo?.spec?.values),
          isInversed: profileInfo.spec.isValuesOverridden,
        };
      }

      return accumulator;
    }, {});
  }
);

export const isClusterProfileUsed = createSelector(
  getRawClusterProfile,
  (clusterProfile) => {
    return clusterProfile.status.inUseClusterUids?.length > 0;
  }
);

export const useIsSaveDisabled = ({ profileType }) => {
  const modules = {
    cluster: profileBuilderEditModule,
    infra: profileBuilderEditModule,
    "add-on": profileBuilderEditModule,
    system: systemProfileBuilderEditModule,
  };

  const { getPackErrors, hasIncompleteLayers, hasUnsavedChanges } =
    modules[profileType].selectors;

  const disabledConditions = [
    useSelector(getPackErrors).length !== 0,
    useSelector(hasIncompleteLayers),
    !useSelector(hasUnsavedChanges),
  ].filter(Boolean);

  return disabledConditions.length !== 0;
};

export const useHasDeletedPacks = ({ profileType }) => {
  const modules = {
    cluster: profileBuilderEditModule,
    infra: profileBuilderEditModule,
    "add-on": profileBuilderEditModule,
    system: systemProfileBuilderEditModule,
  };

  const { hasDeletedPacks } = modules[profileType].selectors;

  return useSelector(hasDeletedPacks);
};

export const getClusterProfileVersions = createSelector(
  getRawClusterProfile,
  (clusterProfile) => {
    return clusterProfile?.spec?.versions || [];
  }
);

export const shouldDisableProfileActions = createSelector(
  getCurrentContext,
  getRawClusterProfile,
  (context, clusterProfile) => {
    const scope = clusterProfile?.metadata?.annotations?.scope;
    return ["system", "tenant"].includes(scope) && !context?.isAdmin;
  }
);

export const canCloneClusterProfile = createSelector(
  getRawClusterProfile,
  (clusterProfile) => {
    const permissions = clusterProfile?.metadata?.annotations?.permissions;
    return permissions?.includes("clusterProfile.create");
  }
);

export const canEditClusterProfileTags = createSelector(
  getRawClusterProfile,
  (clusterProfile) => {
    const permissions = clusterProfile?.metadata?.annotations?.permissions;
    return permissions?.includes("tag.update");
  }
);
