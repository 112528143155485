import { combineReducers } from "redux";
import createReducer from "utils/createReducer";
import React, { createContext, useContext } from "react";
import { connect } from "react-redux";
import store from "services/store";
import { createSelector } from "reselect";
import _ from "lodash";

const BinderContext = createContext();

export function BinderProvider({ module, children }) {
  return (
    <BinderContext.Provider value={module}>{children}</BinderContext.Provider>
  );
}
export function useBinder() {
  return useContext(BinderContext);
}

export function createModule({ name: moduleName, submodules = [], effects }) {
  const actions = submodules.reduce(
    (accumulator, { name, initialState, ...reducer }) => {
      accumulator[name] = Object.keys(reducer).reduce((keyAccumulator, key) => {
        keyAccumulator[key] = key;
        return keyAccumulator;
      }, {});
      return accumulator;
    },
    {}
  );

  return {
    name: moduleName,
    actions,
    effects,
    get reducer() {
      return combineReducers(
        submodules.reduce((accumulator, { name, initialState, ...reducer }) => {
          const reducerFn = createReducer(reducer);
          accumulator[name] = (state = initialState, action) => {
            if (action.module !== moduleName) {
              return state;
            }

            if (action.type === "@@INIT_MODULE") {
              return _.merge({}, initialState, action[name]);
            }

            return reducerFn(state, action);
          };
          return accumulator;
        }, {})
      );
    },

    get state() {
      return store.getState()[moduleName];
    },

    dispatch(thunk) {
      function dispatcher(payload) {
        if (!payload.type) {
          return;
        }
        return store.dispatch({ ...payload, module: moduleName });
      }

      if (typeof thunk === "function") {
        return thunk(dispatcher, this);
      }

      return dispatcher(thunk);
    },

    initialize(stateOverwrite = {}) {
      this.dispatch({ type: "@@INIT_MODULE", ...stateOverwrite });
    },
  };
}

export function createConnector(contextInjector = {}) {
  return {
    actions: contextInjector.actions,
    connect(Component) {
      const ReduxConnector = connect(
        (state, { module }) => {
          const getModuleState = () => module.state;
          const selectors = contextInjector.selectors || {};

          return Object.keys(selectors).reduce((accumulator, key) => {
            accumulator[key] = createSelector(
              getModuleState,
              selectors[key]
            )(module.state);
            return accumulator;
          }, {});
        },
        (dispatch, { module }) => {
          const actions = contextInjector.actions || {};
          return Object.keys(actions).reduce((accumulator, key) => {
            accumulator[key] = (...args) => {
              module.dispatch(actions[key]?.(...args));
            };
            return accumulator;
          }, {});
        }
      )(Component);

      return function Binder(props) {
        const module = useBinder();
        return <ReduxConnector module={module} {...props} />;
      };
    },
  };
}
