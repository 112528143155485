import i18next from "i18next";

import api, { nonProjectApi } from "services/api";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import notifications from "services/notifications";
import ModalService from "services/modal";

import dataFetcher from "modules/dataFetcher";
import createFormActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import { getCurrentContext, getCurrentUser } from "state/auth/selectors";

export const MACROS_FORM_MODULE = "macros";
export const discardChangesConfirmation = new ModalService();

const fetchTenantMacros = async (tenantUid) => {
  let response;
  try {
    response = await nonProjectApi.get(`v1/tenants/${tenantUid}/macros`);
  } catch (err) {}

  return response?.macros || [];
};

const fetchProjectMacros = async (projectUid) => {
  let response;
  try {
    response = await api.get(`v1/projects/${projectUid}/macros`);
  } catch (err) {}

  return response?.macros || [];
};

export const macrosFetcher = dataFetcher({
  selectors: ["macros"],
  async fetchData(_) {
    const currentContext = getCurrentContext(store.getState());

    let response;
    if (currentContext?.isAdmin) {
      const currentUser = getCurrentUser(store.getState());
      const tenantUid = currentUser?.metadata?.annotations?.tenantUid;
      response = await fetchTenantMacros(tenantUid);
    } else {
      const projectUid = currentContext?.projectUid;
      response = await fetchProjectMacros(projectUid);
    }

    return { items: response };
  },
});

export const macrosSuggestionsFetcher = dataFetcher({
  selectors: ["macros", "suggestions"],
  async fetchData(_) {
    let response;
    try {
      response = await api.get(`v1/clusterprofiles/macros`);
    } catch (err) {}

    return { items: response?.macros || [] };
  },
});

const macrosValidator = new Validator();
macrosValidator.addRule(["name", "value"], Missing());

const validator = new Validator();
validator.addRule(["macros"], macrosValidator);
validator.addRule(["macros"], function* (value, key, data) {
  const names = value.map(({ name }) => name.trim());

  const seen = {};

  for (let i = 0; i < names.length; i++) {
    const trimmedName = names[i];
    const name = `macros.${i}.name`;

    if (trimmedName === "") {
      continue;
    }

    if (seen[trimmedName]) {
      yield {
        result: "Macros names must be unique",
        field: name,
      };
    } else {
      seen[trimmedName] = true;
      yield {
        result: false,
        field: name,
      };
    }
  }
});

export const macrosFormActions = createFormActions({
  validator,
  init: async () => {
    const response = await store.dispatch(macrosFetcher.fetch());
    return Promise.resolve({
      macros: response?.items || [],
    });
  },
  submit: async (data) => {
    const currentContext = getCurrentContext(store.getState());

    let promise;
    if (currentContext?.isAdmin) {
      const currentUser = getCurrentUser(store.getState());
      const tenantUid = currentUser?.metadata?.annotations?.tenantUid;
      promise = nonProjectApi.put(`v1/tenants/${tenantUid}/macros`, {
        macros: data.macros,
      });
    } else {
      const projectUid = currentContext?.projectUid;
      promise = api.put(`v1/projects/${projectUid}/macros`, {
        macros: data.macros,
      });
    }

    try {
      await promise;
      notifications.success({
        message: i18next.t("Macros have been updated successfully"),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Macros couldn't be updated"),
        description: err?.message,
      });
    }
  },
});

export function onAddMacro(name) {
  return (dispatch, getState) => {
    const macros = [...getState().forms[MACROS_FORM_MODULE].data.macros];
    macros.push({
      name: "",
      value: "",
    });

    dispatch(
      macrosFormActions.batchChange({
        module: MACROS_FORM_MODULE,
        updates: {
          macros,
        },
      })
    );
  };
}

export function onDeleteMacro(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const macroIndex = pathParts[1];
    const macros = [...getState().forms.macros.data.macros];
    macros.splice(macroIndex, 1);

    dispatch(
      macrosFormActions.batchChange({
        module: MACROS_FORM_MODULE,
        updates: {
          macros,
        },
      })
    );
    dispatch(
      macrosFormActions.clearFieldErrors({
        module: MACROS_FORM_MODULE,
        field: `${MACROS_FORM_MODULE}.${macroIndex}.name`,
      })
    );
    dispatch(
      macrosFormActions.clearFieldErrors({
        module: MACROS_FORM_MODULE,
        field: `${MACROS_FORM_MODULE}.${macroIndex}.value`,
      })
    );
  };
}

export const saveMacrosAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      macrosFormActions.submit({ module: MACROS_FORM_MODULE })
    );
  },
});

export function onDiscard() {
  return (dispatch, getState) => {
    const initialData = getState().forms[MACROS_FORM_MODULE]?.initialData || {};
    discardChangesConfirmation.open().then(() => {
      dispatch(
        macrosFormActions.batchChange({
          module: MACROS_FORM_MODULE,
          updates: {
            ...initialData,
          },
        })
      );
      dispatch(macrosFormActions.clearErrors({ module: MACROS_FORM_MODULE }));
    });
  };
}
