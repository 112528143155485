import React from "react";
import AntIcon from "@ant-design/compatible/es/icon/index";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledIcon = styled(AntIcon)`
  &.anticon {
    margin: 0;
    .svg-inline--fa {
      vertical-align: 0;
    }
  }
`;

export default function Icon({
  awesome = null,
  component = null,
  onClick,
  ...rest
}) {
  let customSvg = component;
  if (awesome) {
    customSvg = function AwesomeIcon() {
      return <FontAwesomeIcon icon={awesome} {...rest} />;
    };
  }

  return <StyledIcon {...rest} component={customSvg} onClick={onClick} />;
}
