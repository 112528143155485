import moment from "moment";

import ListActions from "modules/list/actions";
import querystring from "query-string";
import dataFetcher from "modules/dataFetcher";
import ModalService from "services/modal";
import api from "services/api";

import { BackupSchema, RestoreStatusSchema } from "utils/schemas";
import createFormActions from "modules/form/actions";
import history from "services/history";
import store from "services/store";
import {
  isVirtualCluster,
  getRawCluster,
} from "state/cluster/selectors/details";

export const BACKUP_LIST_MODULE = "backupList";
export const RESTORE_LIST_MODULE = "restoreList";
export const RESTORE_FORM_MODULE = "restore";
export const BACKUP_FILTERS_MODULE = "backupFilters";
export const CREATE_BACKUP_FORM_MODULE = "backup";
export const SCHEDULE_BACKUPS_MODULE = "scheduleBackups";

export const backupDetailsModal = new ModalService("backupDetails");
export const deleteBackupConfirmation = new ModalService("deleteBackup");
export const restoreFromBackupModal = new ModalService("restoreFromBackup");
export const createBackupModal = new ModalService("createBackup");

function getQueryFromFilters(data) {
  const { searchTerm } = data;
  return {
    searchTerm: searchTerm || "",
  };
}

export function parseBackupStatuses(clusterBackupStatuses) {
  return (clusterBackupStatuses || []).reduce((acc, backup) => {
    if (
      backup?.state?.toLowerCase() === "scheduled" &&
      !backup.backupStatusMeta?.length
    ) {
      // No scheduled backups yet
      return acc;
    }
    if (!backup?.state && !backup.backupStatusMeta?.length) {
      return acc;
    }
    if (
      backup?.actor?.actorType === "clusterPolicy" &&
      !backup.backupStatusMeta?.length &&
      backup?.state !== "failed"
    ) {
      return acc;
    }

    if (backup?.backupStatusMeta?.length) {
      backup.backupStatusMeta.forEach((status) => {
        acc.push({
          ...backup,
          backupStatusMeta: [status],
          restoreStatusMeta: (backup.restoreStatusMeta || []).filter(
            (restore) => restore.backupName === status.backupName
          ),
          uid: `${backup.backupRequestUid}_${status.backupName}`,
        });
      });
      return acc;
    }

    acc.push(backup);
    return acc;
  }, []);
}

export const backupsListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  schema: [BackupSchema],
  async fetchData() {
    const response = await store.dispatch(fetchBackupStatuses());
    const items = parseBackupStatuses(
      response?.status?.clusterBackupStatuses
    ).sort((bk1, bk2) =>
      moment(bk1?.backupStatusMeta?.[0]?.backupState?.backupTime).diff(
        moment(bk2?.backupStatusMeta?.[0]?.backupState?.backupTime)
      ) > 0
        ? -1
        : 1
    );

    return {
      items,
    };
  },
});

export const restoresListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  schema: [RestoreStatusSchema],
  async fetchData() {
    const cluster = getRawCluster(store.getState());
    let response;
    try {
      response = await api.get(
        `v1/spectroclusters/${cluster.metadata.uid}/features/restore`
      );
    } catch (err) {}

    const items = response?.status?.clusterRestoreStatuses?.reduce(
      (acc, restore) => {
        if (restore?.restoreStatusMeta?.length) {
          restore.restoreStatusMeta.forEach((status) => {
            acc.push({
              ...restore,
              restoreStatusMeta: [status],
            });
          });
          return acc;
        }

        acc.push(restore);
        return acc;
      },
      []
    );

    return {
      items,
    };
  },
});

export function fetchBackupStatuses() {
  return async (dispatch) => {
    const promise = dispatch(backupStatusesFetcher.fetch());
    let response;

    try {
      response = await promise;
    } catch (error) {}

    return {
      ...response,
      status: {
        ...response?.status,
        clusterBackupStatuses: response?.status?.clusterBackupStatuses?.map(
          (backupStatus) => ({
            ...backupStatus,
            backupStatusMeta: backupStatus?.backupStatusMeta?.map(
              (backupStatusMeta) => ({
                ...backupStatusMeta,
                backupLocationName: response?.spec?.config?.backupLocationName,
              })
            ),
          })
        ),
      },
    };
  };
}

export const backupStatusesFetcher = dataFetcher({
  selectors: ["backuplist", (state) => getRawCluster(state)?.metadata?.uid],
  fetchData([_, clusterUid]) {
    return api.get(`v1/spectroclusters/${clusterUid}/features/backup`);
  },
});

export const backupFetcher = dataFetcher({
  selectors: ["backup", (state) => getRawCluster(state)?.metadata?.uid],
  schema: BackupSchema,
  async fetchData([_, clusterUid], { backupUid, backupName }) {
    const response = await api.get(
      `v1/spectroclusters/${clusterUid}/features/backup?backupRequestUid=${backupUid}`
    );
    const backup = response?.status?.clusterBackupStatuses?.[0];
    const backupStatus = backup?.backupStatusMeta?.find(
      (backup) => backup.backupName === backupName
    );

    return {
      ...backup,
      uid: `${backup.backupRequestUid}_${backupStatus.backupName}`,
      backupStatusMeta: [
        {
          ...backupStatus,
          backupLocationName: response?.spec?.config?.backupLocationName,
        },
      ],
      restoreStatusMeta: (backup.restoreStatusMeta || []).filter(
        (restore) => restore.backupName === backupStatus.backupName
      ),
    };
  },
});

export const filterFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    return Promise.resolve({
      searchTerm: query.searchTerm || "",
    });
  },
  submit: (data) => {
    const query = getQueryFromFilters(data);
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);
    store.dispatch(
      backupsListActions.batchChangeQuery({
        module: BACKUP_LIST_MODULE,
        query,
      })
    );
  },
});

export const clusterListFetcher = dataFetcher({
  selectors: ["clusters", (state) => getRawCluster(state)?.metadata?.uid],
  async fetchData([_, currentClusterUid], query) {
    const { search } = query || {};
    const filters = {
      sort: "name",
      filter: {
        name: {
          contains: search || "",
        },
        state: "Running",
      },
    };
    const state = store.getState();
    if (isVirtualCluster(state)) {
      filters.filter.environment = "nested";
      filters.filter.includeVirtual = true;
      filters.filter.name = {
        eq: getRawCluster(state)?.metadata?.name,
      };
    }
    const response = await api.post(
      "v1/dashboard/spectroclusters/metadata",
      filters
    );

    return {
      items: response?.items,
      currentClusterUid,
    };
  },
});
