import React from "react";

import { animated, useSpring } from "@react-spring/three";
import * as THREE from "three";
import LayerIcon from "./LayerIcon";

const DEFAULT_MARGIN = 2.6;
const COMPACT_MARGIN = 1.75;

export function Layer(props) {
  const {
    isEnlarged,
    data,
    offset,
    compact,
    onSelect,
    setHovered,
    index,
    heightMultiplier = 1,
  } = props;

  const { scale } = useSpring({
    scale: isEnlarged ? 1.6 : 1.4,
  });

  const isComplete = !!data.description;

  let onClick = null;
  let onMouseEnter = null;
  let onMouseLeave = null;
  if (onSelect) {
    onMouseEnter = () => {
      setHovered((state) => [...state, data.guid]);
    };

    onMouseLeave = () => {
      setHovered((state) => {
        const index = state.findIndex((uuid) => uuid === data.guid);
        const nextState = [...state];
        nextState.splice(index, 1);

        return nextState;
      });
    };
    onClick = onSelect.bind(this, data.guid);
  }
  const spaceBetween = compact ? COMPACT_MARGIN : DEFAULT_MARGIN;

  let posY =
    index * spaceBetween - 30 * heightMultiplier + 2 / heightMultiplier;

  return (
    <animated.group position={[0, posY, 0]} scale={scale}>
      <mesh
        castShadow
        receiveShadow
        position={[0, -0.05, 0]}
        rotation={[0.03, Math.PI / 6, 0]}
      >
        <cylinderGeometry attach="geometry" args={[4.75, 4.75, -0.7, 6]} />
        <shadowMaterial
          attach="material"
          transparent
          opacity={index === 0 ? 0 : 0.15}
          shadowSide={THREE.DoubleSide}
        />
      </mesh>
      <mesh
        rotation={[0, Math.PI / 6, 0]}
        castShadow
        onPointerOver={(ev) => {
          ev.stopPropagation();
          onMouseEnter && onMouseEnter();
        }}
        onPointerOut={onMouseLeave}
        onClick={(ev) => {
          ev.stopPropagation();
          onClick && onClick();
        }}
      >
        <cylinderGeometry attach="geometry" args={[4.75, 4.75, 0.7, 6]} />
        <meshPhongMaterial
          transparent
          opacity={isComplete ? 1 : 0.8}
          attachArray="material"
          color={data.shade}
        />
        <meshPhongMaterial
          transparent
          opacity={isComplete ? 1 : 0.8}
          attachArray="material"
          color={"#fff"}
        />
        <meshPhongMaterial
          transparent
          opacity={isComplete ? 1 : 0.8}
          attachArray="material"
          color={data.shade}
        />
        <LayerIcon
          path={data.icon}
          color="#fff"
          position={[
            -3.22,
            1.05 - (offset / (compact ? 75 : 40)) * heightMultiplier,
            5.01,
          ]}
        />
      </mesh>
      <mesh castShadow rotation={[0, Math.PI / 6, 0]}>
        <cylinderGeometry attach="geometry" args={[4, 4.15, 0.85, 6]} />
        <meshPhongMaterial
          attach="material"
          color={data.shade}
          transparent
          opacity={isComplete ? 1 : 0.8}
        />
      </mesh>
    </animated.group>
  );
}

export default React.memo(Layer);
