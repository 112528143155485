import React from "react";
import styled, { css } from "styled-components";

import { midGray, STATUS_COLOR } from "utils/constants/colors";
import Loader from "components/ui/Loader";

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  color: ${(props) => STATUS_COLOR[props.status] || midGray};

  ${(props) =>
    props.hasBackground &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        ${(props) => STATUS_COLOR[props.status] || midGray};
      border-radius: 11px;
      padding: 5px 8px;
    `}
`;

const LoaderWrap = styled.div`
  margin-right: 8px;
  max-height: 12px;
  display: flex;
  align-items: center;
`;

export default function Status({
  status,
  hasBackground = true,
  hasLoader = true,
  children,
  loadingStatuses = ["inprogress"],
}) {
  return (
    <StatusWrapper
      status={status?.toLowerCase()}
      hasBackground={hasBackground}
      data-qa-type="status"
      data-qa="status"
    >
      {hasLoader && loadingStatuses.includes(status?.toLowerCase()) ? (
        <LoaderWrap>
          <Loader small />
        </LoaderWrap>
      ) : null}
      <div>{children || status}</div>
    </StatusWrapper>
  );
}
