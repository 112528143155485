import createApi from "./api/create";

const api = createApi();
class ApplicationEnvironment {
  _env = {};

  async initialize() {
    let data = {};
    let versionData = {};

    try {
      data = await api.get(`app.json?${Date.now()}`);
    } catch (err) {}

    if (!data.PALETTE_VERSION) {
      try {
        versionData = await api.get(`v1/mgmt/app/version`);
        data.PALETTE_VERSION = versionData.version.latestVersion;
      } catch (err) {}
    }

    this._env = data;
  }

  get env() {
    return this._env;
  }
}

const appEnv = new ApplicationEnvironment();

export default appEnv;
