import moment from "moment";
import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { getCluster } from "state/cluster/selectors/details";
import { GENERAL_DATE_FORMAT, WORKLOADS_TIME_PERIODS } from "utils/constants";
import { presentWorkloadChartData } from "utils/presenters";
import { getStoreEntity } from "services/store";
import ModalService from "services/modal";
import {
  PodSchema,
  DeploymentSchema,
  DaemonSetSchema,
  StatefulSetSchema,
  WorkloadRoleBindingSchema,
} from "utils/schemas";

export const WORKLOAD_DETAILS_MODULE = "workloadDetails";
export const selectedWorkloadModal = new ModalService(WORKLOAD_DETAILS_MODULE);

export const WORKLOAD_SCHEMAS = {
  pods: PodSchema,
  deployments: DeploymentSchema,
  daemonsets: DaemonSetSchema,
  statefulsets: StatefulSetSchema,
  rolebindings: WorkloadRoleBindingSchema,
  clusterrolebindings: WorkloadRoleBindingSchema,
};

export const clusterWorkloadUsageFetcher = dataFetcher({
  selectors: [
    "cluster",
    (state) => getCluster(state)?.metadata.uid,
    (state) => state.location.params.flag,
    "barChartData",
  ],
  fetchData: async (
    [_1, clusterUid, currentWorkload, _3],
    timeRange,
    filterBy = []
  ) => {
    const [unit, unitType] = timeRange.split(" ");
    const period = WORKLOADS_TIME_PERIODS[timeRange].period;

    const data = await api.post(
      `v1/dashboard/spectroclusters/resources/usage`,
      {
        filter: {
          startTime: moment()
            .subtract(unit, unitType)
            .utc()
            .format(GENERAL_DATE_FORMAT),
          endTime: moment().utc().format(GENERAL_DATE_FORMAT),
          clusters: [clusterUid],
          workload: {
            type:
              currentWorkload === "pods"
                ? undefined
                : currentWorkload.substring(0, currentWorkload.length - 1),
          },
          namespaces: filterBy,
          includeMasterMachines: true,
        },
        options: {
          groupBy: "cluster",
          period,
        },
      }
    );

    return presentWorkloadChartData({
      data,
      timeRange,
    });
  },
});

export const selectedWorkloadUsageFetcher = dataFetcher({
  selectors: [
    "cluster",
    (state) => getCluster(state)?.metadata.uid,
    (state) => state.location.params.flag,
    () => selectedWorkloadModal?.data?.guid,
    "barChartData",
  ],
  fetchData: async ([_1, clusterUid, workloadTab, workloadGuid], filters) => {
    const { timeRange, namespaces } = filters || {};
    const [unit, unitType] = timeRange.split(" ");
    const period = WORKLOADS_TIME_PERIODS[timeRange].period;
    const workloadEntity = getStoreEntity(
      workloadGuid,
      WORKLOAD_SCHEMAS[workloadTab]
    );

    const workloadType = workloadTab.substring(0, workloadTab.length - 1);
    const isPodWorkload = workloadType === "pod";

    const data = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      {
        filter: {
          startTime: moment()
            .subtract(unit, unitType)
            .utc()
            .format(GENERAL_DATE_FORMAT),
          endTime: moment().utc().format(GENERAL_DATE_FORMAT),
          workload: isPodWorkload
            ? {}
            : {
                type: workloadType,
                names: [workloadEntity.metadata.entity.name],
              },
          clusters: [clusterUid],
          namespaces,
          includeMasterMachines: true,
          pods: isPodWorkload
            ? [workloadEntity.metadata.entity.name]
            : undefined,
        },
        options: {
          groupBy: workloadType,
          period,
        },
      }
    );

    return presentWorkloadChartData({ data, timeRange });
  },
});
