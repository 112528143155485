import React from "react";
import Fields from "components/ui/Fields";
import Loader from "components/ui/Loader";
import { createForm } from "modules/form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { gray, lightMidGray } from "utils/constants/colors";
import { auditSysMsgFetcher, updateAuditLog } from "state/audit/actions";
import { connect } from "react-redux";
import Button, { TextButton } from "components/ui/Button";
import Validator from "services/validator";
import i18next from "i18next";
import { Missing } from "services/validator/rules";
import createFormActions from "modules/form/actions";

const SysMsg = styled.div`
  font-size: 16px;
  line-height: 24px;

  color: ${gray};
`;

const NoteDescription = styled.div`
  border-top: 1px solid ${lightMidGray};
  margin: 0 -24px;
  padding: 0 24px;
  padding-top: 24px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  button {
    max-width: 134px;
  }
`;

export function AddNoteButton({ onClick }) {
  const { t } = useTranslation();
  return (
    <Button data-qa="addnote" onClick={onClick}>
      {t("Add a note")}
    </Button>
  );
}

const AuditMessageForm = () => {
  const { t } = useTranslation();

  return <Fields.TextArea data-qa="note" name="note" label={t("Note")} />;
};

const validator = new Validator();

validator.addRule("note", Missing());
validator.addRule("note", (value) => {
  if (value?.length < 3) {
    return i18next.t(
      "Please make sure your note is at least 3 characters long."
    );
  }
});

const Form = createForm({
  Component: AuditMessageForm,
  actions: createFormActions({
    validator,
    init: () => Promise.resolve({ note: "" }),
  }),
});

function UserNote({
  isLoading,
  sysMsg,
  updateAuditLog,
  closeNotification,
  hasErrors,
}) {
  const { t } = useTranslation();
  if (isLoading) {
    return <Loader small />;
  }

  return (
    <NoteDescription>
      <SysMsg>{sysMsg}</SysMsg>
      <Form module="audit" />
      <div>
        <Button
          key="confirm-ok"
          data-qa="confirm-ok"
          onClick={() => {
            updateAuditLog();
            closeNotification();
          }}
          disabled={hasErrors}
        >
          {t("Save Message")}
        </Button>
        <TextButton
          secondary
          key="confirm-cancel"
          data-qa="confirm-cancel"
          onClick={closeNotification}
        >
          {t("Cancel")}
        </TextButton>
      </div>
    </NoteDescription>
  );
}

export default connect(
  (state) => ({
    sysMsg: auditSysMsgFetcher.selector(state).result,
    isLoading: auditSysMsgFetcher.selector(state).isLoading,
    hasErrors: !!state.forms.audit?.errors.length,
  }),
  { updateAuditLog }
)(UserNote);
