import React, { useState, useMemo } from "react";

import LayersScene from "components/common/ClusterProfilePreview/LayersScene";
import Layer from "components/common/ClusterProfilePreview/Layer";
import { getLayerPrecedence } from "utils/presenters";

const DEFAULT_MAX_HEIGHT = 1200;

export default function ClusterProfilePreview({
  layers,
  onSelect,
  selectedLayer,
  compact,
  maxHeight = DEFAULT_MAX_HEIGHT,
}) {
  const [hovered, setHovered] = useState([]);
  const heightMultiplier = maxHeight / 1200;

  const sortedLayers = useMemo(() => {
    return [...layers].sort((layerA, layerB) => {
      return getLayerPrecedence(layerA) - getLayerPrecedence(layerB);
    });
  }, [layers]);

  function renderLayer(layer, index) {
    return (
      <Layer
        key={layer.guid || index}
        index={index}
        data={layer}
        onSelect={onSelect}
        setHovered={setHovered}
        offset={index}
        heightMultiplier={heightMultiplier}
        isEnlarged={
          hovered.includes(layer.guid) || selectedLayer === layer.guid
        }
        compact={compact}
      />
    );
  }

  return (
    <LayersScene
      height={layers.length * (compact ? 34 : 50) + (compact ? 48 + 15 : 50)}
      layers={sortedLayers}
      maxHeight={maxHeight}
    >
      {sortedLayers.map(renderLayer)}
    </LayersScene>
  );
}
