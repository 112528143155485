import i18n from "i18next";
import { createSelector } from "reselect";
import axios from "axios";

import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import { ClusterSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import history from "services/history";
import notifications from "services/notifications";
import store from "services/store";
import ModalService from "services/modal";
import { edgeMachinesFetcher } from "../actions/list/edgemachines";
import { getCluster } from "../selectors/details";

export const nodeDetailsModalService = new ModalService();
export const poolConfigModal = new ModalService();
export const connectNodeModal = new ModalService();
export const confirmNodePoolSizeModal = new ModalService();
export const addNodePoolModal = new ModalService("addNodePool");
export const maintenanceModeModal = new ModalService();

export const DEFAULT_RESOURCE_POOL_VALUE = "Default";
export const NODE_DETAILS_FILTERS_MODULE = "nodeDetailsFilters";

export const getRawCluster = getEntity(
  (state) => state.cluster.details.currentClusterId,
  ClusterSchema
);

export const createSelectorByPath = (formSelector, detailPageSelector) =>
  createSelector(
    formSelector,
    detailPageSelector,
    () => history.location.pathname,
    (formSelector, detailPageSelector, path) => {
      if (path.includes("create")) {
        return formSelector;
      }
      return detailPageSelector;
    }
  );

const getClusterRegion = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig.spec.clusterConfig?.location : null
);

const getGCPClusterRegion = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig.spec.clusterConfig?.region : null
);

const getClusterProject = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig.spec.clusterConfig.project : null
);

const getClusterAccountUID = createSelector(getRawCluster, (cluster) =>
  cluster ? cluster.spec.cloudConfig?.spec.cloudAccountRef?.uid : null
);

const getRegion = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.region,
  getClusterRegion
);

const getGCPRegion = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.region,
  getGCPClusterRegion
);

const getProject = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.project,
  getClusterProject
);

const getCredential = createSelectorByPath(
  (state) => state.forms?.cluster?.data?.credential,
  getClusterAccountUID
);

export const azureInstanceTypesFetcher = dataFetcher({
  selectors: ["azure", getRegion, "instanceTypes"],
  fetchData([_, region]) {
    return api.get(`v1/clouds/azure/regions/${region}/instancetypes`);
  },
});

export const azureAZFetcher = dataFetcher({
  selectors: ["azure", getRegion, "azs"],
  fetchData([_, region]) {
    return api.get(`v1/clouds/azure/regions/${region}/zones`);
  },
});

export const azureStorageAccountsFetcher = dataFetcher({
  selectors: ["azure", getRegion, "storageAccounts"],
  fetchData([_, region]) {
    return api.get(`v1/clouds/azure/storageaccounttypes?region=${region}`);
  },
});

export const gcpAZFetcher = dataFetcher({
  selectors: ["gcp", getProject, getGCPRegion, getCredential],
  async fetchData([_, project, region, credential]) {
    const res = await api.get(
      `v1/clouds/gcp/projects/${project}/regions/${region}/zones?cloudAccountUid=${credential}`
    );
    return res.zones;
  },
});

export const gcpInstanceTypesFetcher = dataFetcher({
  selectors: ["gcp", getGCPRegion],
  async fetchData([_, region]) {
    let res;
    try {
      res = await api.get(`v1/clouds/gcp/regions/${region}/instancetypes`);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      notifications.error({
        message: i18n.t("Something went wrong."),
        description: error?.message,
      });
    }
    return res;
  },
});

const resourceKey = {
  libvirt: "placements",
  edge: "hosts",
  "edge-native": "hosts",
};

export const nodePoolAppliancesFetcher = dataFetcher({
  selectors: ["nodesAppliances"],
  fetchData: async () => {
    const state = store.getState();
    const result = edgeMachinesFetcher.selector(state).result;
    const cluster = getCluster(state);
    const clusterEdgeHosts = (cluster?.spec?.machinePoolConfig || [])
      .map((nodePool) => {
        return (nodePool?.[resourceKey[cluster?.spec?.cloudType]] || []).map(
          (host) => host?.hostUid
        );
      })
      .flat();

    const isEdgeNative = cluster?.spec?.cloudType === "edge-native";
    const data = state.forms.nodePool?.data;
    const excludedEdgeHosts = data?.edgeHosts.map((host) => host.hostUid);
    const initialEdgeHosts = state.forms.nodePool?.initialData?.edgeHosts.map(
      (host) => host.hostUid
    );

    const edgeMachines = (result?.items || []).filter((machine) => {
      const machineStatus = machine?.status;
      const machineState = machineStatus?.state?.toLowerCase();
      const archType = machine?.spec?.device?.archType;

      let isNotInUse = clusterEdgeHosts.includes(machine.metadata.name);
      let isReady = machineState === "ready";

      const isUnpaired = machineState === "unpaired";
      const isHealthy = machineStatus?.health?.state !== "unhealthy";
      const isSameCloudType =
        machine?.spec?.type?.toLowerCase() === cluster?.spec?.cloudType;

      if (isEdgeNative) {
        isNotInUse = !excludedEdgeHosts.includes(machine.metadata.uid);
        isReady = isReady || initialEdgeHosts.includes(machine.metadata.uid);

        if (addNodePoolModal?.data?.type === "create") {
          isNotInUse =
            machineStatus.inUseClusters.length === 0 &&
            !excludedEdgeHosts.includes(machine.metadata.uid);
        }
      }

      // TODO: this needs a rewrite
      // the logic might be that you can use a host in the following cases:
      // - is unpaired
      // - is running & healthy and matches the cloudType
      // - is already used by this cluster but not duplicates inside the pool
      // For now I'll make this change only for libvirt
      // if bugs appear in this function ping @codrin-iftimie
      if (cluster?.spec?.cloudType === "libvirt") {
        return (
          isUnpaired ||
          (isReady && isHealthy && isSameCloudType) ||
          (isNotInUse && !excludedEdgeHosts.includes(machine.metadata.uid))
        );
      }

      return (
        (isUnpaired || (isReady && isHealthy && isSameCloudType)) &&
        isNotInUse &&
        archType === data?.architecture
      );
    });

    return Promise.resolve(edgeMachines);
  },
});

function getEdgeMachineData(deviceIndex) {
  const edgeHosts = store.getState().forms.nodePool.data.edgeHosts;
  const result = edgeMachinesFetcher.selector(store.getState()).result;
  const selectedHost = edgeHosts?.[deviceIndex]?.hostUid || "";

  const edgeMachine = (result?.items || []).find(
    (machine) => machine.metadata.uid === selectedHost
  );

  return edgeMachine;
}

export const nodePoolApplianceStoragePoolsKeyedFetcher = keyedDataFetcher({
  selectors: ["nodePoolApplianceStoragePools"],
  fetchData: async ([_, deviceIndex]) => {
    const edgeMachine = getEdgeMachineData(deviceIndex);
    const storagePools = edgeMachine?.spec?.properties?.storagePools || [];

    return storagePools;
  },
});

export const nodePoolApplianceNetworkTypesKeyedFetcher = keyedDataFetcher({
  selectors: ["nodePoolApplianceNetworkTypes"],
  fetchData: async ([_, deviceIndex]) => {
    const edgeMachine = getEdgeMachineData(deviceIndex);
    const networkTypes = [
      ...new Set(
        (edgeMachine?.spec?.properties?.networks || []).map(
          (network) => network.networkType
        )
      ),
    ];

    return Promise.resolve(networkTypes);
  },
});

export const nodePoolApplianceNetworksKeyedFetcher = keyedDataFetcher({
  selectors: ["nodePoolApplianceNetworks"],
  fetchData: async ([_, deviceIndex]) => {
    const edgeHosts = store.getState().forms.nodePool.data.edgeHosts;

    const edgeMachine = getEdgeMachineData(deviceIndex);
    const selectedNetworkType = edgeHosts?.[deviceIndex]?.networkType || "";

    const networks = (edgeMachine?.spec?.properties?.networks || []).filter(
      (network) =>
        selectedNetworkType ? network.networkType === selectedNetworkType : true
    );

    return Promise.resolve(networks);
  },
});

export const nodePoolApplianceResourceFetchers = [
  nodePoolApplianceStoragePoolsKeyedFetcher,
  nodePoolApplianceNetworkTypesKeyedFetcher,
  nodePoolApplianceNetworksKeyedFetcher,
];
