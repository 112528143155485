import api from "services/api";

import dataFetcher from "modules/dataFetcher";

export const sandboxClusterQuotaUsageFetcher = dataFetcher({
  selectors: ["sandboxCluster", "quota", "usage"],
  async fetchData(_, scope) {
    const response = await api.get(
      `v1/clustergroups/developerCredit/usage/${scope}`
    );
    return response;
  },
});

const createDevQuotaFetcher = (selectors = []) =>
  dataFetcher({
    selectors: ["developer", "quota", "usage", ...selectors],
    async fetchData(_, scope) {
      const response = await api.get(
        `v1/clustergroups/developerCredit/usage/${scope}`
      );
      return response;
    },
  });

export const developerQuotaUsageFetcher = createDevQuotaFetcher();

export const developerQuotaUsageFetcherTenantScope = createDevQuotaFetcher([
  "tenant",
]);

export const developerQuotaUsageFetcherSystemScope = createDevQuotaFetcher([
  "system",
]);
